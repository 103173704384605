import Text from "../../components/design-system/Text";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import FillButton from "../../components/design-system/button/FillButton";
import { useAppSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";

export default function DirectorEndPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const themeData = useAppSelector((state) => state.theme.data);
  const { me } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col justify-between items-center h-full bg-[#121212]">
        <div
          className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
        >
          <div className="flex items-center mb-[30px]">
            <Text className="title !text-[#ececec] ">디렉터 신청</Text>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <motion.div
            className={`w-full ${isMobile ? "px-10" : "flex flex-col"} mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <img
              src="/img/standbuy/background/director-banner.png"
              alt="director-banner"
              width="100%"
            />
            <div className="flex flex-col items-center mt-[50px]">
              <Text
                fontWeight={700}
                fontSize={20}
                className={"!text-[#ececec] "}
              >
                성실히 답변해주셔서 감사합니다.
              </Text>

              <Text
                fontWeight={500}
                fontSize={16}
                className="mt-2 !text-[#ececec] "
              >
                1~2주 내로 연락드리겠습니다.
              </Text>
            </div>

            <div className="flex justify-center mt-10">
              <FillButton
                onClick={() => navigate(`/feed`)}
                text={"홈으로 가기"}
                className="w-[360px] py-4"
                color={"white"}
              />
            </div>
          </motion.div>
        </div>
      </div>
      {/* {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />} */}
    </>
  );
}
