import React, { useState } from "react";
import Switcher from "../../components/common/Switcher";
import NiceFormCard from "./card/NIceFormCard";
import NiceSocialCard from "./card/NiceSocialCard";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../store/store";
import { useLocation } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";

export default function NiceAuthPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { versionData } = useAppSelector((state) => state.mobile);
  const location = useLocation();

  return (
    <>
      <MetaDatas siteName={""} title={"본인인증"} siteUrl={location.pathname} />

      <div
        className={`bg-gray-200 dark:bg-black flex justify-center items-center h-screen`}
      >
        <Switcher className="hidden" />
        <div className="w-full flex flex-col items-center justify-center mx-5 my-10 gap-10 px-3">
          {/*<img*/}
          {/*  src={*/}
          {/*    themeData === "light" || themeData === ""*/}
          {/*      ? "/img/standbuy/logo/logo.svg"*/}
          {/*      : "/img/standbuy/logo/logo-white.svg"*/}
          {/*  }*/}
          {/*  alt="standbuy"*/}
          {/*  onClick={() => navigate(-1)}*/}
          {/*  className="cursor-pointer"*/}
          {/*  width={200}*/}
          {/*/>*/}

          {!versionData ? <NiceFormCard /> : null}
          {versionData ? <NiceSocialCard /> : null}
        </div>
      </div>
    </>
  );
}
