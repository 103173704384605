import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import SideDrawer from "../../components/design-system/SideDrawer";
import MyPageEdit from "./card/MyPageEdit";
import MyBankEdit from "./card/MyBankEdit";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import GoogleAd from "../../components/ads/GoogleAd";
import { UploadTextColor } from "../../styles/color";
import { useLocation } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";

export default function MyPage() {
  const location = useLocation();
  return (
    <>
      <MetaDatas
        title={"마이페이지"}
        siteName={""}
        siteUrl={location.pathname}
      />
      <div className="flex flex-col justify-between h-full">
        <motion.div
          className={`w-full flex justify-center mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className="mt-[40px] mx-[200px] w-full max-w-[1280px] relative">
            <div className=" animate-bounce gap-1">
              <Text fontSize={14} fontWeight={600} color="black" className="">
                메뉴
              </Text>
              <img src="/img/standbuy/icon/scroll-down.svg" alt="scroll-down" />
            </div>
            <div className="flex items-center mb-[30px]">
              <SideDrawer />
              <Text className="title ">내 정보 수정</Text>
            </div>

            <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

            <MyPageEdit />
            {/*<MyBankEdit />*/}
          </div>
        </motion.div>

        <Text
          color={UploadTextColor}
          className="px-4 my-1 flex justify-center "
          fontSize={15}
        >
          *하단 광고는 STANDBUY와 무관한 광고 입니다.
        </Text>

        <hr className="w-full my-1 border-gray-300 border-[1px]" />

        <div className="w-full sm:max-w-[728px] mx-auto my-5">
          <GoogleAd />
        </div>

        <CustomFooter styles={"fixed bottom-0"} />
      </div>
    </>
  );
}
