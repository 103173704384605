import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Text from "../../design-system/Text";
import { useState } from "react";
import { useAxios } from "../../../lib/api/useAxios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import UserReportModal from "./UserReportModal";
import { API_SOCIAL } from "../../../api/api";
import { useContext } from "../../../util/useContext";
import {
  modalFetched,
  niceModalFetched,
} from "../../../store/reducer/globalModalSlice";
import { CustomImage } from "../../design-system/CustomImg";
import { shallowEqual } from "react-redux";

export default function CommentBtn({
  options,
  item,
  detail,
  setModifyFlag,
  setEditingId,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const { me, isLogin } = useContext();
  const { del } = useAxios();
  const [feedReport, setFeedReport] = useState<any>({
    show: false,
  });
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isLogin === false) {
      dispatch(modalFetched({ show: true }));
    } else {
      setAnchorEl(event.currentTarget);
    }
    // if (isLogin && me?.mobileVerification) {
    //   setAnchorEl(event.currentTarget);
    // } else if (isLogin && !me?.mobileVerification) {
    //   dispatch(niceModalFetched({ show: true }));
    // } else if (isLogin === false) {
    //   dispatch(modalFetched({ show: true }));
    // }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function removeComment() {
    const res = await del(`${API_SOCIAL}/user/social/comment/remove`, {
      commentId: item?.commentId,
    });

    if (res?.success) {
      dispatch(
        toastFetched({
          show: true,
          text: "댓글을 삭제했습니다.",
          type: "success",
        })
      );
    }
  }

  const { mutate } = useMutation({
    mutationFn: removeComment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["social-detail"] });
      handleClose();
    },
  });

  const declarationList = [
    {
      label: "신고하기",
      onClick: () => {
        setFeedReport({ show: true });
      },
    },
  ];

  const myOptionList = [
    // {
    //   label: "수정하기",
    //   onClick: () => {
    //     setModifyFlag(true);
    //     setEditingId(item?.commentId);
    //   },
    // },
    {
      label: "삭제하기",
      onClick: mutate,
    },
  ];

  return (
    <>
      <div>
        <IconButton onClick={handleClick}>
          <CustomImage
            src="/img/standbuy/icon/comment-dot.svg"
            color={
              themeData === "light" || themeData === "" ? "#000" : "#ececec"
            }
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {options === "myList"
            ? myOptionList.map((option: any, index: any) => (
                <MenuItem
                  key={option.label}
                  onClick={(e: any) => {
                    // 옵션의 onClick 핸들러를 호출합니다.
                    option.onClick();
                    handleClose();
                  }}
                >
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color="#2f2f2f"
                    className="dark:text-darkText"
                  >
                    {option.label}
                  </Text>
                </MenuItem>
              ))
            : declarationList.map((option: any, index: any) => (
                <MenuItem
                  key={option.label}
                  onClick={(e: any) => {
                    // 옵션의 onClick 핸들러를 호출합니다.
                    option.onClick();
                    handleClose();
                  }}
                >
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color="#2f2f2f"
                    className="dark:text-darkText"
                  >
                    {option.label}
                  </Text>
                </MenuItem>
              ))}
        </Menu>
      </div>

      {feedReport.show && (
        <UserReportModal
          open={feedReport.show}
          onClose={() => setFeedReport({ show: false })}
          detail={detail}
          item={item}
        />
      )}
    </>
  );
}
