import React, { useEffect, useState } from "react";
import Switcher from "../../components/common/Switcher";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../components/design-system/Text";
import Card from "../../components/design-system/Card";
import FillButton from "../../components/design-system/button/FillButton";
import { UploadTextColor } from "../../styles/color";
import { MetaDatas } from "../../meta/MetaDatas";

export default function NiceAuthFailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [reason, setReason] = useState<any>("");
  const [type, setType] = useState<any>("");

  useEffect(() => {
    const temp = new URL(window.location.href).search;
    const params = new URLSearchParams(temp).get("reason");
    const paramsTwo = new URLSearchParams(temp).get("authSuccess");
    setReason(params);
    setType(paramsTwo);
  }, []);

  return (
    <>
      <MetaDatas siteName={""} title={"본인인증"} siteUrl={location.pathname} />

      <div className="bg-gray-200 dark:bg-black h-screen flex flex-col justify-center items-center gap-12">
        <Switcher className="hidden" />
        <img
          src={"/img/standbuy/logo/logo.svg"}
          alt="standbuy"
          onClick={() => navigate("/feed")}
          className="cursor-pointer"
          width={200}
        />
        <Card>
          <div className="flex flex-col items-center justify-center">
            <Text className="" fontSize={20} fontWeight={700}>
              본인 인증 실패했어요
            </Text>

            <Text
              fontSize={16}
              fontWeight={500}
              color="#535353"
              className=" mt-4"
            >
              - 이유 : {reason || "-"}
            </Text>

            <FillButton
              text={"스탠바이로 이동"}
              onClick={() => {
                navigate("/feed", { replace: true });
              }}
              className="flex justify-center w-full mt-10"
              size="lg"
            />
          </div>
        </Card>
      </div>
    </>
  );
}
