import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { UploadTextColor } from "../../../styles/color";

export default function ProfileSetModal({ open, onClose }: any) {
  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 ">프로필 편집 완료</Text>

        <Text className="upload-text  mt-4" color={UploadTextColor}>
          프로필 편집을 완료했어요!
        </Text>

        <FillButton
          text="확인"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
}
