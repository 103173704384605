import React, { useEffect, useRef, useState } from "react";
import SideDrawer from "../../components/design-system/SideDrawer";
import Text from "../../components/design-system/Text";
import CustomTabs from "../../components/design-system/CustomTabs";
import ConvertList from "./card/ConvertList";
import { motion } from "framer-motion";
import NoExchangeData from "./card/NoExchangeData";
import { addComma } from "../../util/number";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import ExchangeList from "./card/ExchangeList";
import { useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { settleGet } from "../../api/exchange/exchange";
import { useLocation } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";

const tabData = [
  {
    label: "정산내역",
    value: "EXCHANGE",
  },
  // {
  //   label: "전환 내역",
  //   value: "CONVERT",
  // },
];

export default function ExchangeDetailPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [value, setValue] = useState(tabData[0].value);
  const location = useLocation();

  const { status, data } = useQuery({
    queryKey: ["settle-stats"],
    queryFn: settleGet,
  });

  return (
    <>
      <MetaDatas title={"정산내역"} siteName={""} siteUrl={location.pathname} />

      <div
        className={`flex flex-col justify-between h-full ${isMobile ? "pt-[40px]" : "pt-[80px]"}`}
      >
        <motion.div
          className={`w-full ${isMobile ? "px-10" : "flex justify-center"} mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div
            className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
          >
            {isMobile ? null : (
              <div className="animate-bounce gap-1">
                <Text fontSize={14} fontWeight={600} color="black" className="">
                  메뉴
                </Text>
                <img
                  src="/img/standbuy/icon/scroll-down.svg"
                  alt="scroll-down"
                />
              </div>
            )}
            <div className="flex items-center mb-[30px]">
              {isMobile ? null : <SideDrawer />}
              <Text className="title ">정산 요청내역</Text>
            </div>

            <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

            <div className="mb-10">
              <div className="py-5 flex justify-evenly items-center bg-[#f9f9f9] dark:bg-darkBanner rounded-xl">
                <div>
                  <div className="flex flex-col items-center gap-1">
                    <Text
                      className=" mb-[6px]"
                      fontSize={15}
                      color="#636363"
                      fontWeight={500}
                    >
                      정산 가능 한 수익금
                    </Text>
                    <Text className="subtitle-1 ">
                      {addComma(data?.data.possible) || 0} ₩
                    </Text>
                  </div>
                </div>
              </div>
            </div>

            <CustomTabs
              tabsData={tabData}
              value={value}
              setValue={setValue}
              size={isMobile ? "20px" : "28px"}
            />
            {value === "EXCHANGE" && <ExchangeList />}
            {value === "CONVERT" && <ConvertList value={value} />}
          </div>
        </motion.div>

        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>
    </>
  );
}
