import React, { useEffect } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { useAxios } from "../../../lib/api/useAxios";
import { useMutation } from "@tanstack/react-query";
import { API_DEFAULT } from "../../../api/api";

export default function ResellWarningModal({
  open,
  onClose,
  data,
  setFileUrl,
}: any) {
  const { post } = useAxios();

  async function fileDownload({ buyType, contentId, manifestName }: any) {
    const res = await post(`${API_DEFAULT}/contents/download`, {
      buyType: buyType,
      contentId: contentId,
      manifestName: manifestName,
    });

    if (res?.success) {
      setFileUrl(res?.data.downloadUrl);
      onClose();
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: fileDownload,
    onSuccess: (fileUrl) => {
      // setLoading(true);
      // handleDownload(fileUrl);
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 ">
          콘텐츠를 다운로드하면 결제 취소가 불가능합니다.
        </Text>

        <FillButton
          text="다운로드"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => {
            mutate({
              buyType: data?.buyType,
              contentId: data?.contentId,
              manifestName: data?.manifestName,
            });
          }}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
