import React, { useState } from "react";
import { Select, Option } from "@material-tailwind/react";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import Text from "./Text";

export function BankSelect({ selectedBank, setSelectedBank }: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  const banks = [
    { code: "002", name: "산업은행" },
    { code: "003", name: "기업은행" },
    { code: "004", name: "국민은행" },
    { code: "007", name: "수협은행/수협중앙회" },
    { code: "011", name: "농협은행" },
    { code: "012", name: "농협중앙회" },
    { code: "020", name: "우리은행" },
    { code: "023", name: "SC제일은행" },
    { code: "027", name: "한국씨티은행" },
    { code: "031", name: "대구은행" },
    { code: "032", name: "부산은행" },
    { code: "034", name: "광주은행" },
    { code: "035", name: "제주은행" },
    { code: "037", name: "전북은행" },
    { code: "039", name: "경남은행" },
    { code: "045", name: "새마을금고중앙회" },
    { code: "048", name: "신협중앙회" },
    { code: "050", name: "상호저축은행" },
    { code: "054", name: "HSBC은행" },
    { code: "055", name: "도이치은행" },
    { code: "057", name: "제이피모간체이스은행" },
    { code: "060", name: "BOA은행" },
    { code: "062", name: "중국공상은행" },
    { code: "064", name: "산림조합중앙회" },
    { code: "071", name: "우체국" },
    { code: "081", name: "KEB하나은행" },
    { code: "088", name: "신한은행" },
    { code: "089", name: "K뱅크" },
    { code: "090", name: "카카오뱅크" },
    { code: "092", name: "토스뱅크" },
    { code: "209", name: "유안타증권" },
    { code: "218", name: "KB증권" },
    { code: "238", name: "미래에셋대우" },
    { code: "240", name: "삼성증권" },
    { code: "243", name: "한국투자증권" },
    { code: "247", name: "NH투자증권" },
    { code: "261", name: "교보증권" },
    { code: "262", name: "하이투자증권" },
    { code: "263", name: "현대차투자증권" },
    { code: "264", name: "키움증권" },
    { code: "265", name: "이베스트투자증권" },
    { code: "266", name: "SK증권" },
    { code: "267", name: "대신증권" },
    { code: "269", name: "한화투자증권" },
    { code: "270", name: "하나금융투자" },
    { code: "278", name: "신한금융투자" },
    { code: "279", name: "동부증권" },
    { code: "280", name: "유진투자증권" },
    { code: "287", name: "메리츠종합금융증권" },
    { code: "290", name: "부국증권" },
    { code: "291", name: "신영증권" },
    { code: "292", name: "케이프투자증권" },
    { code: "103", name: "SBI 저축은행" },
  ];

  return (
    <div className="w-full">
      <Select
        //@ts-ignore
        label={
          <Text className="" fontSize={14} color="blue-gary-700">
            은행 선택하기
          </Text>
        }
        variant="static"
        value={selectedBank}
        onChange={(val: any) => setSelectedBank(val)}
        children={undefined}
        placeholder={undefined}
        size="lg"
        className=""
      >
        {banks.map((bank) => (
          <Option key={bank.code} value={bank.code}>
            {bank.name}
          </Option>
        ))}
      </Select>
    </div>
  );
}
