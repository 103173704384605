//@ts-nocheck
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import React, { useState, useRef, useEffect } from "react"; // useEffect 추가
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { instance } from "../../../api";
import { API_DEFAULT } from "../../../api/api";
import { useMediaQuery } from "react-responsive";
import Text from "../../../components/design-system/Text";
import StockListSide from "../../feed/stock-list/StockListSide";
import { setLocationList } from "../../../store/reducer/locationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";

// props 타입 정의 예시
interface Props {
  fetchLocations: () => void;
  // 혹시 외부에서 loading을 받아온다면 여기에 선언해도 됩니다.
  // loading?: boolean;
}

export default function LocationMobileList(props: Props) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => setOpen(newOpen);

  const themeData = useAppSelector((state) => state.theme.data);
  const bounds = useAppSelector((state) => state.bounds);
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const dispatch = useAppDispatch();

  const groupKeyRef = useRef(0);
  const [items, setItems] = useState<any[]>([]);
  const isRefreshed = useRef(false);
  const [gridKey, setGridKey] = useState(0);
  const scrollContainerRef = useRef(null);
  const [spinnersFlag, setSpinnersFlag] = useState<any>(true);

  // ============================================
  // 1) '처음 마운트'와 'bounds 변경'을 추적할 state/ref
  // ============================================
  const initialBounds = useRef(bounds); // 초기 bounds 저장
  const [isBoundsChanged, setIsBoundsChanged] = useState(false);
  const isFirstRender = useRef(true); // 첫 마운트 여부

  // ============================================
  // 2) bounds 변경 감지 - useEffect
  // ============================================
  useEffect(() => {
    // 처음 마운트 시에는 비교 로직을 건너뛴 후, 두 번째 렌더부터 비교
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // 단순 비교: sw/lat, sw/lng, ne/lat, ne/lng 각각 비교
    const changed =
      bounds?.sw?.lat !== initialBounds.current?.sw?.lat ||
      bounds?.sw?.lng !== initialBounds.current?.sw?.lng ||
      bounds?.ne?.lat !== initialBounds.current?.ne?.lat ||
      bounds?.ne?.lng !== initialBounds.current?.ne?.lng;

    setIsBoundsChanged(changed);
  }, [bounds]);

  // ============================================
  // 3) "현 지도에서 검색" 버튼 클릭 시
  // ============================================
  const fetchItemsNow = async () => {
    groupKeyRef.current = 0;
    setItems([]);
    isRefreshed.current = true;

    setGridKey((prevKey) => prevKey + 1); // JustifiedInfiniteGrid 리렌더 트리거
    props.fetchLocations();

    // 검색 완료 후, 현재 bounds를 새 '초기 bounds'로 간주
    initialBounds.current = bounds;
    setIsBoundsChanged(false);
  };

  // ============================================
  // 4) 새로고침 이후 아이템 가져오는 함수
  // ============================================
  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    setSpinnersFlag(true);

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: bounds.sw.lat,
      latMax: bounds.ne.lat,
      lonMin: bounds.sw.lng,
      lonMax: bounds.ne.lng,
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.forEach((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
      setSpinnersFlag(false);
    }

    return nextItems;
  }

  // ============================================
  // 5) 초기 렌더링 시 아이템 가져오는 함수
  // (lat/lon 범위를 고정값으로 사용)
  // ============================================
  async function getItemMount(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    setSpinnersFlag(true);

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: 36.507468472827576,
      latMax: 38.60617845599798,
      lonMin: 125.24388600000003,
      lonMax: 128.75951100000003,
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.forEach((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
      setSpinnersFlag(false);
    }

    return nextItems;
  }

  return (
    <>
      {/* 
        버튼 노출 조건:
        1) bounds가 바뀌었을 때 (isBoundsChanged === true)
        2) 첫 마운트가 끝난 이후 (isFirstRender.current === false)
        -- 필요하다면 추가로 loading 상태까지 고려할 수 있음 --
      */}
      {isBoundsChanged && !isFirstRender.current && (
        <button
          onClick={fetchItemsNow}
          style={{
            background: "rgba(0, 74, 111, 0.8)",
            transform: "translateX(-50%)", // 버튼을 가로로 정중앙에 위치
          }}
          className={`fixed ${
            isMobile ? "bottom-6" : "bottom-10"
          } left-1/2 w-[170px] p-4 text-white text-center z-50 rounded-3xl font-bold`}
        >
          <FontAwesomeIcon icon={faRotateRight} className="mr-2" />현 지도에서
          검색
        </button>
      )}

      <JustifiedInfiniteGrid
        scrollContainer={scrollContainerRef.current}
        className={"container"}
        key={gridKey}
        placeholder={
          <div className="placeholder">추가 데이터를 불러오는 중...</div>
        }
        columnRange={[0, 1]}
        threshold={3000}
        gap={8}
        loading={
          spinnersFlag ? (
            <img
              src={
                themeData === "light" || themeData === ""
                  ? "/img/standbuy/animation/standbuy-walking-dark.gif"
                  : "/img/standbuy/animation/standbuy-walking-white.gif"
              }
              alt="loading"
              width={220}
            />
          ) : undefined
        }
        onRequestAppend={(e) => {
          const nextGroupKey = groupKeyRef.current + 1;
          groupKeyRef.current = nextGroupKey;
          e.wait();

          setTimeout(async () => {
            let add;
            if (isRefreshed.current) {
              // 새로고침 이후
              add = await getItems(nextGroupKey, 20);
            } else {
              // 초기 렌더링 시
              add = await getItemMount(nextGroupKey, 20);
            }

            if (add.length === 0) {
              return true;
            }

            dispatch(setLocationList({ data: add, reset: false }));
            setItems((prevItems) => [...prevItems, ...add]);
            e.ready();
          }, 1000);
        }}
      >
        {items?.map((item: any, index: any) => (
          <StockListSide
            data-grid-groupkey={item.groupKey}
            key={index}
            index={index}
            item={item}
          />
        ))}
      </JustifiedInfiniteGrid>
    </>
  );
}
