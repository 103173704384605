import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../../store/store";
import { motion } from "framer-motion";
import FillButton from "../../../components/design-system/button/FillButton";
import MobileFooter from "../../../components/layout/footer/MobileFooter";
import CustomFooter from "../../../components/layout/footer/CustomFooter";
import Text from "../../../components/design-system/Text";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "../../../util/useContext";
import { Input } from "@material-tailwind/react";
import { channel } from "diagnostics_channel";

export default function DirectorEightPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { me, state } = useContext();
  const themeData = useAppSelector((state) => state.theme.data);
  const { number } = useParams();
  const navigate = useNavigate();
  const [folioData, setfolioData] = useState("");

  return (
    <>
      <div className="flex flex-col justify-between items-center h-full bg-[#121212]">
        <div
          className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
        >
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title !text-[#ececec] ">디렉터 신청</Text>

            <div className="flex flex-col items-end">
              <Text fontWeight={700} className={"!text-[#ececec] "}>
                0{number}/09
              </Text>
              <Text className={"!text-[#ececec] "}>아직 조금 남았어요..!</Text>
            </div>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <motion.div
            className={`w-full ${isMobile ? "px-10" : "flex flex-col"} mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="flex flex-col items-center gap-10 mt-[100px]">
              <div className="flex flex-col items-center">
                <Text
                  fontWeight={700}
                  fontSize={20}
                  className={"!text-[#ececec] "}
                >
                  웹사이트 / 포트폴리오 링크를 입력해주세요.
                </Text>

                <Text
                  fontWeight={500}
                  fontSize={16}
                  className="mt-2 !text-[#ececec] "
                >
                  소셜미디어 혹은 개인 웹사이트 등 참고할 수 있는 링크를
                  입력해주세요.
                </Text>
              </div>

              <div className="flex justify-center">
                <Input
                  type="text"
                  variant="static"
                  label="웹사이트 / 포트폴리오 링크를 입력해주세요."
                  placeholder="웹사이트 / 포트폴리오 링크를 입력해주세요."
                  className="max-w-[600px] !py-5 !px-5 rounded-lg !border !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10 dark:!bg-darkBg dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText "
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "min-w-[600px]" }}
                  crossOrigin={undefined}
                  size="lg"
                  onChange={(e: any) => {
                    setfolioData(e.target.value);
                  }}
                  value={folioData}
                />
              </div>
            </div>

            <div className="flex justify-center mt-10">
              <FillButton
                onClick={() =>
                  navigate(`/director/9`, {
                    state: { channel: state, portfolio: folioData },
                  })
                }
                text={"STANDBUY"}
                className="w-[360px] py-4"
                disabled={!folioData}
                color={"white"}
              />
            </div>

            <div className="flex gap-4 items-center justify-center">
              <Text
                className="underline underline-offset-4 cursor-pointer my-6  !text-[#ececec] "
                onClick={() => navigate(-1)}
                fontSize={14}
                fontWeight={500}
                color="#363636"
              >
                이전으로
              </Text>
            </div>
          </motion.div>
        </div>
      </div>
      {/* {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />} */}
    </>
  );
}
