import React, { useEffect, useState } from "react";
import Text from "../../../components/design-system/Text";
import { ErrorText } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import Card from "../../../components/design-system/Card";
import { useAppDispatch } from "../../../store/store";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { LabelInputEyes } from "../../../components/design-system/input/LabelInputEyes";
import ResetEndModal from "../modal/ResetEndModal";

export default function ResetPasswordForm() {
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const [token, setToken] = useState("");
  const [password, setPassWord] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });

  const regexPwd =
    /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\[\]{};:'"<>,.?/\+\-=~₩`|_]).+$/;
  let isValidPassword = regexPwd.test(password);

  useEffect(() => {
    const temp = new URL(window.location.href).search;
    const params = new URLSearchParams(temp).get("t");
    // @ts-ignore
    setToken(params);
  }, []);

  async function handleResetPasswordEnd() {
    const res = await post(`${API_DEFAULT}/user/auth/reset-password/${token}`, {
      newPassword: password,
      newPasswordConfirm: passwordConfirm,
    });

    if (res?.success) {
      setInfoModal({ show: true });
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: "비밀번호 재설정 실패",
          type: "error",
        })
      );
    }
  }

  return (
    <>
      <Card>
        <div className="flex flex-col">
          <Text className="subtitle-2 ">신규 비밀번호 설정</Text>

          <div className="w-full mt-12">
            <LabelInputEyes
              type="password"
              width="100%"
              value={password}
              setValue={setPassWord}
              placeholder={"신규 비밀번호를 입력해주세요."}
              label={"신규 비밀번호"}
              minLength={8}
              error={
                (!isValidPassword && password !== "") ||
                (isValidPassword && password !== "" && password.length < 8)
              }
              success={
                isValidPassword && password !== "" && password.length >= 8
              }
            />
            {(!isValidPassword && password !== "") ||
            (isValidPassword && password !== "" && password.length < 8) ? (
              <Text color={ErrorText} className="label-2 mt-1">
                8자 이상 숫자/영어 소문자/특수문자 조합으로 입력해주세요.
              </Text>
            ) : null}
          </div>

          <div className="w-full mt-12">
            <LabelInputEyes
              width="100%"
              type="password"
              value={passwordConfirm}
              setValue={setPasswordConfirm}
              placeholder={"비밀번호를 다시 한 번 입력해주세요."}
              label="비밀번호 확인"
              error={passwordConfirm !== "" && passwordConfirm !== password}
              success={passwordConfirm !== "" && passwordConfirm === password}
            />
            {passwordConfirm !== "" && passwordConfirm !== password && (
              <Text color={ErrorText} className="label-2 mt-1">
                입력한 비밀번호가 다릅니다.
              </Text>
            )}
          </div>

          <FillButton
            onClick={handleResetPasswordEnd}
            text={"재설정 완료"}
            size={"lg"}
            className="mt-10"
            disabled={!isValidPassword || passwordConfirm !== password}
          />
        </div>
      </Card>
      {infoModal.show && (
        <ResetEndModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
        />
      )}
    </>
  );
}
