import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";

export default function JoinAddFailModal({ open, onClose, data }: any) {
  const [part1, part2] = useMemo(() => {
    return data.split("메일이에요.").map((str: any) => str.trim());
  }, [data]);

  return (
    <Modal title="" onClose={onClose} open={open} headerNone>
      <Text fontSize={18} fontWeight={600} className="mb-8 ">
        회원가입 실패
      </Text>
      <div className="flex justify-center mt-2">
        <div className="flex flex-col items-center gap-2 mb-4">
          <Text fontSize={14} className="">
            {part1 + " 메일이에요."}
          </Text>
          <Text fontSize={14} className="">
            {part2}
          </Text>
        </div>
      </div>
    </Modal>
  );
}
