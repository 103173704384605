import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { UploadTextColor } from "../../../styles/color";

export default function ProfileNickModal({ open, onClose, onClick }: any) {
  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 ">프로필 닉네임을 변경하시겠습니까?</Text>

        <Text className="upload-text  mt-4" color={UploadTextColor}>
          지금 닉네임을 변경하면 1주일간 변경할 수 없습니다.
        </Text>

        <FillButton
          text="수정하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={onClick}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
