import React, { useState } from "react";
import Text from "../../../components/design-system/Text";
import { motion } from "framer-motion";
import { Avatar } from "@material-tailwind/react";
import { Pagination } from "../../../components/design-system/Pagination";
import { checkRole } from "../../../util";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { UploadTextColor } from "../../../styles/color";
import GoogleAd from "../../../components/ads/GoogleAd";

export default function MySubsList({ data }: any) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(16);

  const firstPostIndex = (currentPage - 1) * postsPerPage;
  const lastPostIndex = firstPostIndex + postsPerPage;
  const currentPosts = data?.slice(firstPostIndex, lastPostIndex);

  return (
    <div>
      <Text className="mx-5 ">총 {data?.length}명</Text>

      <div className={`w-full min-h-[440px] h-[440px]`}>
        <div
          className={`grid ${isMobile ? "grid-cols-3 mb-10" : "grid-cols-8"} gap-6`}
        >
          {currentPosts?.map((item: any, index: any) => {
            return (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <div className="w-full flex flex-col items-center mt-10 gap-5">
                  <Avatar
                    variant="circular"
                    alt="profile img"
                    src={
                      item?.profileImage
                        ? item?.profileImage
                        : "/img/standbuy/icon/no-profile.svg"
                    }
                    placeholder={undefined}
                    className="border border-[#134B70] ring-2 ring-[#134B70] p-0.5  cursor-pointer"
                    size={isMobile ? "lg" : "lg"}
                    onClick={() =>
                      navigate(`/studio/${window.btoa(item?.memberId)}`, {
                        state: item?.memberId,
                      })
                    }
                  />

                  <div className="flex flex-col gap-[6px] max-w-[120px]">
                    <p className="break-words  truncate text-[14px] font-semibold text-[#353535] dark:text-darkText">
                      {item?.nickname}
                    </p>
                  </div>

                  <Text fontSize={14} fontWeight={600} color="#828282">
                    {checkRole(item?.grade)}
                  </Text>
                </div>
              </motion.div>
            );
          })}
        </div>
        <div className={`${isMobile ? "pb-10" : "pb-20"}`}>
          <Pagination
            postsNum={data?.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
        <Text
          color={UploadTextColor}
          className="px-4 my-1 flex justify-center "
          fontSize={15}
        >
          *하단 광고는 STANDBUY와 무관한 광고 입니다.
        </Text>
        <hr className="w-full my-1 border-gray-300 border-[1px]" />

        <div className="w-full sm:max-w-[728px] mx-auto my-5">
          <GoogleAd />
        </div>
      </div>
    </div>
  );
}
