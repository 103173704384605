import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../../store/store";
import { motion } from "framer-motion";
import FillButton from "../../../components/design-system/button/FillButton";
import MobileFooter from "../../../components/layout/footer/MobileFooter";
import CustomFooter from "../../../components/layout/footer/CustomFooter";
import Text from "../../../components/design-system/Text";
import { useNavigate, useParams } from "react-router-dom";

export default function DirectorOnePage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const themeData = useAppSelector((state) => state.theme.data);
  const { me } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col justify-between items-center h-full !bg-[#121212]">
        <div
          className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
        >
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title !text-[#ececec] ">디렉터 신청</Text>

            <div className="flex flex-col items-end">
              <Text fontWeight={700} className={"!text-[#ececec] "}>
                01/09
              </Text>
              <Text className={"!text-[#ececec] "}>아직 조금 남았어요..!</Text>
            </div>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <motion.div
            className={`w-full ${isMobile ? "px-10" : "flex flex-col"} mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="flex flex-col items-center gap-10 mt-[100px] ">
              <div className="flex flex-col items-center">
                <Text
                  fontWeight={700}
                  fontSize={20}
                  className={"!text-[#ececec] "}
                >
                  디렉터가 되기 위해선
                </Text>
                <Text
                  fontWeight={700}
                  fontSize={20}
                  className={"!text-[#ececec] "}
                >
                  몇 가지 조건이 필요합니다.
                </Text>
              </div>

              <div className="flex flex-col items-center">
                <Text
                  fontWeight={700}
                  fontSize={20}
                  className={"!text-[#ececec] "}
                >
                  조건에 해당된다면
                </Text>
                <Text
                  fontWeight={700}
                  fontSize={20}
                  className={"!text-[#ececec] "}
                >
                  'STANDBUY' 버튼을 눌러주세요.
                </Text>
              </div>
            </div>

            <div className="flex justify-center mt-10">
              <FillButton
                onClick={() => navigate(`/director/2`)}
                text={"STANDBUY"}
                className="w-[360px] py-4"
                color={"white"}
              />
            </div>

            <div className="flex gap-4 items-center justify-center">
              <Text
                className="underline underline-offset-4 cursor-pointer my-6 !text-[#ececec] "
                onClick={() => navigate(-1)}
                fontSize={14}
                fontWeight={500}
              >
                홈으로 가기
              </Text>
            </div>
          </motion.div>
        </div>
      </div>
      {/* {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />} */}
    </>
  );
}
