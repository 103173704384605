import React from "react";
import Modal from "../../design-system/modal/Modal";
import Text from "../../design-system/Text";
import FillButton from "../../design-system/button/FillButton";
import RemoveBackgroundModal from "./RemoveBackgroundModal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/store";
import { noticeFetched } from "../../../store/reducer/priceInfoSlice";
import { useMediaQuery } from "react-responsive";

export default function TermsNoticeModal({ open, onClose }: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <RemoveBackgroundModal
      open={open}
      onClose={onClose}
      width={isMobile ? 340 : 520}
    >
      <div className="flex flex-col items-center">
        <Text
          fontSize={isMobile ? 20 : 32}
          fontWeight={700}
          className="text-center "
        >
          이용약관 변경 안내
        </Text>

        <img
          src="/img/standbuy/icon/notification.png"
          alt="alarm"
          width={isMobile ? 140 : 240}
        />
      </div>
      <div className="flex flex-col items-center justify-center ">
        <Text className="" fontSize={isMobile ? 18 : 24} fontWeight={600}>
          원활한 서비스 운영을 위해
        </Text>
        <Text className="" fontSize={isMobile ? 18 : 24} fontWeight={600}>
          이용약관이 변경됩니다.
        </Text>

        <div className="flex flex-col items-center mt-5 gap-4">
          <Text className="" fontSize={isMobile ? 18 : 24} fontWeight={600}>
            주요 내용
          </Text>
          <div className="flex flex-col items-center">
            <Text className="" fontSize={isMobile ? 16 : 20} fontWeight={400}>
              변경 전 : 1년 이용권 (기간 제한)
            </Text>
            <Text className="" fontSize={isMobile ? 16 : 20} fontWeight={400}>
              변경 후 : 이용권 (기간 제한 없음)
            </Text>
          </div>
        </div>

        <div className="mt-4">
          <Text
            className="mt-6 "
            fontSize={isMobile ? 16 : 20}
            fontWeight={700}
          >
            공지일 : 2025년 1월 17일
          </Text>

          <Text className="" fontSize={isMobile ? 16 : 20} fontWeight={700}>
            변경일 : 2025년 2월 3일
          </Text>
        </div>
      </div>

      <div className="flex items-center gap-10 justify-center mt-4 pb-[12px]">
        <Text
          className="cursor-pointer opacity-50 "
          onClick={() => navigate("/notice/7")}
          fontSize={isMobile ? 16 : 18}
          fontWeight={700}
          color="#363636"
        >
          자세히 보기
        </Text>
        <Text
          className="cursor-pointer my-4 "
          onClick={() => {
            onClose();
            dispatch(noticeFetched(true));
          }}
          fontSize={isMobile ? 16 : 18}
          fontWeight={700}
          color="#363636"
        >
          다시 보지 않기
        </Text>
      </div>
    </RemoveBackgroundModal>
  );
}
