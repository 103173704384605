import React from "react";
import Text from "../../../components/design-system/Text";
import { addComma } from "../../../util/number";

export default function SalesManage({ data }: any) {
  return (
    <>
      <Text fontSize={16} fontWeight={600} color="#303030" className="">
        콘텐츠 판매관리
      </Text>
      <div className="mt-5 flex gap-[70px] justify-evenly">
        <div className="flex flex-col items-center gap-[10px]">
          <div className="min-w-[90px] max-w-[90px] max-h-[90px] bg-[#f5f5f5] flex justify-center items-center p-[30px] rounded-lg">
            <Text fontSize={20} fontWeight={700} color="#363636">
              {addComma(data?.salesSummary.totalContents) || "0"}
            </Text>
          </div>
          <Text fontSize={14} fontWeight={500} color="#555" className="">
            콘텐츠 수
          </Text>
        </div>

        <div className="flex flex-col items-center gap-[10px]">
          <div className="min-w-[90px] max-w-[90px] max-h-[90px] bg-[#f5f5f5] flex justify-center items-center p-[30px] rounded-lg">
            <Text fontSize={20} fontWeight={700} color="#363636">
              {addComma(data?.salesSummary.ticketSales) || "0"}
            </Text>
          </div>
          <Text fontSize={14} fontWeight={500} color="#555" className="">
            이용권 누적 판매량
          </Text>
        </div>

        <div className="flex flex-col items-center gap-[10px]">
          <div className="min-w-[90px] max-w-[90px] max-h-[90px] bg-[#f5f5f5] flex justify-center items-center p-[30px] rounded-lg">
            <Text fontSize={20} fontWeight={700} color="#363636">
              {addComma(data?.salesSummary.ownershipSales) || "0"}
            </Text>
          </div>
          <Text fontSize={14} fontWeight={500} color="#555" className="">
            소유권 누적 판매량
          </Text>
        </div>

        <div className="flex flex-col items-center gap-[10px]">
          <div className="min-w-[90px] max-w-[90px] max-h-[90px] bg-[#f5f5f5] flex justify-center items-center p-[30px] rounded-lg">
            <Text fontSize={20} fontWeight={700} color="#363636">
              {addComma(data?.salesSummary.cumulativeRevenue) || "0"}
            </Text>
          </div>
          <Text fontSize={14} fontWeight={500} color="#555" className="">
            누적 수익금
          </Text>
        </div>

        {/* <div className="flex flex-col items-center gap-[10px]">
          <div className="min-w-[90px] max-w-[90px] max-h-[90px] bg-[#f5f5f5] flex justify-center items-center p-[30px] rounded-lg">
            <Text fontSize={20} fontWeight={700} color="#363636">
              {addComma(data?.salesSummary.cumulativeRevenue) || "0"}
            </Text>
          </div>
          <Text fontSize={14} fontWeight={500} color="#555">
            구독자 수
          </Text>
        </div> */}
      </div>
    </>
  );
}
