import React, { useEffect, useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { Textarea } from "@material-tailwind/react";
import FillButton from "../../../components/design-system/button/FillButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMediaQuery } from "react-responsive";
import { onProfileEdit } from "../../../api/studio/studio";

export default function ProfileStudioModal({ open, onClose, data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<any>();

  const { mutate } = useMutation({
    mutationFn: onProfileEdit,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
      if (data?.success) {
        onClose();
        dispatch(
          toastFetched({
            show: true,
            text: "프로필 메시지가 저장되었습니다.",
            type: "success",
          })
        );
      }
    },
  });

  useEffect(() => {
    if (data) {
      setMessage(data?.description);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerNone
      noOutSideClosed={true}
      width={isMobile ? "100%" : undefined}
    >
      <Text fontSize={18} color="#242424" fontWeight={700} className="">
        소개 편집
      </Text>

      <div className={`w-full mt-5`}>
        <Textarea
          className="!border-[#F1F1F1] focus:border-[1px] min-h-[266px] focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10  shadow-gray-900/5 ring-4 ring-transparent dark:!bg-darkBg dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] "
          placeholder="메시지를 입력해주세요."
          labelProps={{ className: "before:content-none after:content-none" }}
          value={message}
          onChange={(e: any) => {
            setMessage(e.target.value);
          }}
          maxLength={500}
        />

        <div className="flex justify-end">
          <Text fontSize={14} fontWeight={600} color="#535353" className="">
            {message?.length || 0} / 500
          </Text>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <FillButton
          text="편집 완료"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => mutate(message)}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
