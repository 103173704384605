import Switcher from "../../components/common/Switcher";
import { useLocation, useNavigate } from "react-router-dom";
import SocialCheckForm from "./card/SocialCheckForm";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { MetaDatas } from "../../meta/MetaDatas";

export default function SocialTermsPage() {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <MetaDatas
        siteName={""}
        title={"이용약관 동의"}
        siteUrl={location.pathname}
      />
      <div className="bg-white dark:bg-black h-screen flex justify-center items-center">
        <Switcher className="hidden" />
        <div className="w-full flex flex-col items-center gap-12">
          <img
            src={
              themeData === "light" || themeData === ""
                ? "/img/standbuy/logo/logo.svg"
                : "/img/standbuy/logo/logo-white.svg"
            }
            alt="standbuy"
            onClick={() => navigate("/")}
            className="cursor-pointer"
            width={200}
          />
          <SocialCheckForm />
        </div>
      </div>
    </>
  );
}
