//@ts-noCheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-tailwind/react";
import Text from "../../design-system/Text";
import KeywordCheckBox from "./KeywordCheckBox";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import CustomCheckBox from "../../design-system/CustomCheckBox";
import FilterSwitch from "./FilterSwitch";
import { useWindowSize } from "@uidotdev/usehooks";
import AssetsCheckBox from "./AssetsCheckBox";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function FilterIconButton({ items }: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const { height } = useWindowSize();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        variant="outlined"
        className="rounded-full focus:ring-transparent"
        size="sm"
      >
        <FontAwesomeIcon
          icon={faSliders}
          className={`w-[14px] h-[14px] ${
            themeData === "light" || themeData === "" ? "" : "text-darkText"
          }`}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          disableRipple
          sx={{
            background:
              themeData === "light" || themeData === "" ? "" : "#121212",
          }}
        >
          <div className="flex flex-col gap-3 !z-99">
            <KeywordCheckBox />
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}
