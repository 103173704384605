import React, { useEffect, useRef, useState } from "react";
import Dayjs from "dayjs";
import { addComma } from "../../../util/number";
import Text from "../../../components/design-system/Text";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT, URL_HOST } from "../../../api/api";
import useIntersectObserver from "../../../components/hooks/useIntersectObserver";
import FeedInfoModal from "../../feed/modal/FeedInfoModal";
import NoAlarmList from "./NoAlarmList";
import { useContext } from "../../../util/useContext";
import detailDate from "../../../util/dateHistoryTransfer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AlarmDeleteModal from "../modal/AlarmDeleteModal";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../../api";

export default function AlarmList({ value }: any) {
  const { get, post, del } = useAxios();
  const { state, me } = useContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [deleteModal, setDeleteModal] = useState<any>({
    show: false,
    data: "",
    flag: "",
  });

  const getFofListData = async (page: any) => {
    try {
      const res = await instance.get(
        `${API_DEFAULT}/user/alarm?memberId=${me?.memberId || state}&page=${page}&size=10`
      );
      return res.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [page, setPage] = useState(0);
  const [commentList, setCommentList] = useState<any>([]);
  const intersectRef = useRef<any>(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });

  const { isIntersect } = useIntersectObserver(intersectRef, {
    rootMargin: "200px",
    threshold: 0.01,
  });

  const loadMoreCommentData = async () => {
    if (isIntersect) {
      try {
        const data = await getFofListData(page);

        if (data?.length === 0) {
          setIsLastPage(true);
        } else {
          // 여기서 data가 반드시 배열인지 확인
          if (Array.isArray(data)) {
            setCommentList((prevComments: any) => [...prevComments, ...data]);
            setPage((prev) => prev + 1);
          } else {
            // data가 배열이 아닐 때의 처리 로직 추가
            console.error("data is not an array", data);
          }
        }
      } catch (error) {
        console.error("Error loading more comment data:", error);
        // 필요한 경우 에러 상태 설정 또는 사용자에게 피드백 제공
      }
    }
  };

  useEffect(() => {
    loadMoreCommentData();
  }, [isIntersect, isLastPage, page, value]);

  async function onAlarmRead(alarmId: any) {
    const res = await post(
      `${API_DEFAULT}/user/alarm/${alarmId}/read?memberId=${me?.memberId}`
    );

    if (res?.success) {
      console.log("읽기 성공");
      // window.location.reload();
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: onAlarmRead,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["alarm-list"] });
    },
  });

  async function onRemoveAlarmList(alarmId: any) {
    const res = await del(
      `${API_DEFAULT}/user/alarm/${alarmId}/delete?memberId=${me?.memberId}`
    );
    window.location.reload();
  }

  const { mutate: onRemoveAlarm } = useMutation({
    mutationFn: onRemoveAlarmList,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["alarm-list"] });
    },
  });

  const popupWidth = 1400;
  const popupHeight = 1000;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  // 팝업 창을 화면 가운데에 위치시키기 위한 좌표 계산
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  useEffect(() => {
    window.navigateToPage = (url: string, options?: any) => {
      navigate(url, options);
    };
  }, [navigate]);

  const [clickedIndexes, setClickedIndexes] = useState<number[]>([]); // 여러 인덱스를 저장하는 상태

  const handleBoxClick = (index: number) => {
    // 이미 클릭된 박스인지 확인 (추가만 하고, 제거는 하지 않음)
    if (!clickedIndexes.includes(index)) {
      setClickedIndexes([...clickedIndexes, index]);
    }
  };
  const renderMessage = (type: any, item: any) => {
    switch (type) {
      case "DIRECTOR_APPROVAL":
        return (
          <>
            <Text fontWeight={700}>DIRECTOR 등록이 완료됐어요.</Text>
          </>
        );
      case "NOTICE":
        return (
          <>
            <Text fontWeight={700}>
              중요 공지가 발생했어요. 꼭 확인해주세요 :)
            </Text>
          </>
        );
      case "LIKE":
        return (
          <>
            <Text removeDarkMode>
              회원님의 게시물에{" "}
              <Text fontWeight={700} span removeDarkMode>
                {item?.sender}님
              </Text>
              이{" "}
              <Text fontWeight={700} span removeDarkMode>
                좋아요를{" "}
              </Text>
              눌렀습니다.
            </Text>
          </>
        );
      case "REPLY":
        return (
          <>
            <Text removeDarkMode>
              회원님의 게시물에{" "}
              <Text fontWeight={700} span>
                {item?.sender}님
              </Text>
              이{" "}
              <Text fontWeight={700} span>
                답글을 작성{" "}
              </Text>
              했습니다.
            </Text>
          </>
        );
      case "COMMENT":
        return (
          <>
            <Text removeDarkMode>
              회원님의 게시물에{" "}
              <Text fontWeight={700} span>
                {item?.sender}님
              </Text>
              이{" "}
              <Text fontWeight={700} span>
                댓글을 작성{" "}
              </Text>
              했습니다.
            </Text>
          </>
        );

      case "SUB":
        return (
          <>
            <Text removeDarkMode>
              <Text fontWeight={700} span>
                {item?.sender}님
              </Text>
              이{" "}
              <Text fontWeight={700} span>
                구독을 시작{" "}
              </Text>
              했습니다.
            </Text>
          </>
        );
      case "TICKET":
        return (
          <>
            <Text removeDarkMode>
              <Text fontWeight={400} span>
                회원님
              </Text>
              의{" "}
              <Text fontWeight={700} span>
                이용권이 판매되었습니다.
              </Text>
            </Text>
          </>
        );
      case "OWNERSHIP":
        return (
          <>
            <Text removeDarkMode>
              <Text fontWeight={400} span>
                회원님
              </Text>
              의{" "}
              <Text fontWeight={700} span className="">
                소유권이 판매되었습니다.
              </Text>
            </Text>
          </>
        );
      default:
        return null;
    }
  };

  const messageType = (item: any) => {
    if (item?.type === "COMMENT") {
      return (
        <>
          <Text
            fontSize={12}
            fontWeight={600}
            color={"#b2b2b2"}
            className={"mt-3"}
            removeDarkMode
          >
            박스를 눌러서 댓글을 확인해보세요.
          </Text>
        </>
      );
    } else if (item?.type === "NOTICE") {
      return (
        <>
          <Text
            fontSize={12}
            fontWeight={600}
            color={"#b2b2b2"}
            className={"mt-3 "}
            removeDarkMode
          >
            박스를 눌러서 공지사항을 확인해보세요.
          </Text>
        </>
      );
    } else if (item?.type === "REPLY") {
      return (
        <>
          <Text
            fontSize={12}
            fontWeight={600}
            color={"#b2b2b2"}
            className={"mt-3 "}
            removeDarkMode
          >
            박스를 눌러서 답글을 확인해보세요.
          </Text>
        </>
      );
    } else if (item?.type === "LIKE") {
      return (
        <>
          <Text
            fontSize={12}
            fontWeight={600}
            color={"#b2b2b2"}
            className={"mt-3 "}
            removeDarkMode
          >
            박스를 눌러서 게시물을 확인해보세요.
          </Text>
        </>
      );
    }
    if (item?.type === "TICKET" || item?.type === "OWNERSHIP") {
      return (
        <>
          <Text
            fontSize={12}
            fontWeight={600}
            color={"#b2b2b2"}
            className={"mt-3 "}
            removeDarkMode
          >
            박스를 눌러서 판매내역을 확인해보세요.
          </Text>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {commentList?.length < 1 ? (
        <NoAlarmList value={value} />
      ) : (
        commentList?.map((item: any, index: any) => (
          <div
            className={`mt-4 py-[30px] px-[20px] border-[#EBEBEB] ${item?.read || clickedIndexes.includes(index) ? "bg-[#E7E7E7]" : "bg-[#fcfcfc]"} rounded-3xl relative cursor-pointer`}
            onClick={() => {
              handleBoxClick(index);
              mutate(item?.alarmId);
              if (
                item?.type === "COMMENT" ||
                item?.type === "REPLY" ||
                item?.type === "LIKE"
              ) {
                window.name = JSON.stringify({ contentId: item?.target });
                window.open(
                  `${URL_HOST}feed-info`,
                  "feed",
                  `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
                );
              } else if (
                item?.type === "TICKET" ||
                item?.type === "OWNERSHIP"
              ) {
                navigate(`/fof-credit`);
              } else if (item?.type === "NOTICE") {
                navigate(`/notice/6`, {
                  state: 6,
                });
              }
            }}
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-6">
                <div className={""}>
                  <>
                    <div className="flex justify-between">
                      <div className="flex gap-3 grow">
                        <div
                          className={`py-2 px-4 ${item?.read || clickedIndexes.includes(index) ? "bg-[#9A9A9A]" : "bg-[#F57373]"}  max-w-[82px] rounded-3xl`}
                        >
                          <Text color={"white"} fontWeight={700} fontSize={14}>
                            {item?.type === "DIRECTOR_APPROVAL"
                              ? "승인"
                              : item?.type === "LIKE"
                                ? "좋아요"
                                : item?.type === "SUB"
                                  ? "구독알림"
                                  : item?.type === "COMMENT"
                                    ? "댓글알림"
                                    : item?.type === "REPLY"
                                      ? "답글알림"
                                      : item?.type === "NOTICE"
                                        ? "공지사항"
                                        : item?.type === "TICKET" ||
                                            item?.type === "OWNERSHIP"
                                          ? "판매알림"
                                          : null}
                          </Text>
                        </div>

                        <Text
                          fontSize={16}
                          fontWeight={600}
                          color={"#b2b2b2"}
                          className={"mt-2 "}
                          removeDarkMode
                        >
                          {detailDate(new Date(item.createTime))}{" "}
                        </Text>
                      </div>

                      {/* X 버튼 */}
                      <FontAwesomeIcon
                        icon={faXmark}
                        onClick={(e: any) => {
                          e.stopPropagation();

                          onRemoveAlarm(item?.alarmId);
                        }}
                        fontSize={24}
                        className="cursor-pointer mt-10 absolute right-6 top-0"
                        color={"#9A9A9A"}
                      />
                    </div>
                    <div className="flex items-center gap-4 mt-5">
                      <Text fontSize={18}>
                        {renderMessage(item?.type, item)}
                      </Text>
                    </div>
                    {messageType(item)}
                  </>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {infoModal.show && (
        <FeedInfoModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
      {deleteModal.show && (
        <AlarmDeleteModal
          open={deleteModal.show}
          onClose={() => setDeleteModal({ show: false })}
          data={deleteModal.data}
        />
      )}

      {!isLastPage && <div id="intersectElement" ref={intersectRef}></div>}
    </>
  );
}
