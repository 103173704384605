//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useContext } from "../../../util/useContext";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import CustomCheckBox from "../../design-system/CustomCheckBox";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Text from "../../design-system/Text";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { shallowEqual } from "react-redux";
import { LabelInputLength } from "../../design-system/input/LabelInputLength";
import Input from "@material-tailwind/react/components/Input";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import FilterCheckBox from "../../design-system/FilterCheckBox";

export default function KeywordCheckBox() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state } = useContext();
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const navigate = useNavigate();
  const { get } = useAxios();
  const [info, setInfo] = useState<any[] | null>(null);

  // 가격
  const [priceLow, setPriceLow] = useState<any>(null);
  const [priceHigh, setPriceHigh] = useState<any>(null);

  // 해상도
  const [viewWidth, setViewWidth] = useState<any>(null);
  const [viewHeight, setViewHeight] = useState<any>(null);

  // 키워드 직접입력
  const [keywordInput, setKeywordInput] = useState<string>("");

  // 각 입력 필드에 대한 ref 생성
  const priceLowRef = useRef<HTMLInputElement>(null);
  const priceHighRef = useRef<HTMLInputElement>(null);

  const viewWidthRef = useRef<HTMLInputElement>(null);
  const viewHeightRef = useRef<HTMLInputElement>(null);

  const keywordInputtRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (state?.keyword) {
      const valuesArray = state.keyword;
      setCheckedItems(valuesArray);
    }
  }, [state]);

  async function onInit() {
    const res = await get(`${API_DEFAULT}/contents/search/get/keywords`);
    if (res?.success) {
      setInfo(res?.data);
    }
  }

  useEffect(() => {
    onInit();
  }, []);

  const keywordString = sessionStorage.getItem("keywords");
  const keywordArray: any[] = JSON.parse(keywordString || "[]");

  const searchString = sessionStorage.getItem("search");

  const priceString: any = sessionStorage.getItem("price");
  const priceArray: any[] = JSON.parse(priceString || "[]");

  const viewString: any = sessionStorage.getItem("view");
  const viewArray: any[] = JSON.parse(viewString || "[]");

  // 수정된 handleCheckboxChange 함수
  const handleCheckboxChange = (name: string, checked: boolean) => {
    const value = name;
    let newCheckedItems;

    if (checked) {
      // 이미 존재하는지 확인하고 추가
      if (!checkedItems.includes(value)) {
        newCheckedItems = [...checkedItems, value];
      } else {
        newCheckedItems = [...checkedItems]; // 변경 없음
      }
    } else {
      // 체크 해제 시 제거
      newCheckedItems = checkedItems.filter((item: any) => item !== value);
    }

    setCheckedItems(newCheckedItems);

    sessionStorage.setItem("keywords", JSON.stringify(newCheckedItems));

    navigate(`/feed/filter`, {
      state: {
        keyword: newCheckedItems,
        search: searchString,
        price: priceArray,
        view: viewArray,
      },
      replace: true,
    });
  };

  return (
    <div className="flex flex-col gap-4 dark:bg-darkBg">
      {isMobile && <Text className="subtitle-3 ">필터</Text>}

      <div className="flex items-center gap-2">
        <Text className="subtitle-3 ">구독 · 등급</Text>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-center gap-2">
          <FilterCheckBox
            label="구독한 아티스트"
            labelStyle="label-1"
            checked={checkedItems.includes("구독")}
            onChange={(e) => handleCheckboxChange("구독", e.target.checked)}
            name={"구독"}
          />
        </div>
        <div className="flex items-center justify-center gap-2">
          <FilterCheckBox
            label="디렉터 콘텐츠만"
            labelStyle="label-1"
            checked={checkedItems.includes("DIRECTOR")}
            onChange={(e) => handleCheckboxChange("DIRECTOR", e.target.checked)}
            name={"DIRECTOR"}
          />
        </div>
      </div>

      <hr className="my-5" />

      <Text className="subtitle-3 ">콘텐츠 유형</Text>
      <CustomCheckBox
        label="이미지 · 그림"
        labelStyle="label-1"
        checked={checkedItems.includes("IMAGE")}
        onChange={(e) => handleCheckboxChange("IMAGE", e.target.checked)}
        name={"IMAGE"}
      />

      <CustomCheckBox
        label="영상"
        labelStyle="label-1"
        checked={checkedItems.includes("VIDEO")}
        onChange={(e) => handleCheckboxChange("VIDEO", e.target.checked)}
        name={"VIDEO"}
      />

      <hr className="my-5" />

      <Text className="subtitle-3 ">가격</Text>

      <CustomCheckBox
        label="무료 오픈 콘텐츠만"
        labelStyle="label-1"
        checked={priceArray[0] === 0 && priceArray[1] === 0}
        onChange={() => {
          const pricePayload: any = [0, 0];

          sessionStorage.setItem("price", JSON.stringify(pricePayload));

          navigate(`/feed/filter`, {
            state: {
              keyword: keywordArray,
              search: searchString,
              price: pricePayload,
              view: viewArray,
            },
            replace: true,
          });
        }}
        name={"open-price"}
      />

      <div className="flex items-center gap-3">
        <div>
          <input
            value={priceLow || ""}
            type="number"
            onChange={(e: any) => setPriceLow(e.target.value)}
            className="w-[80px] border border-gray px-2 py-1 dark:bg-darkBanner dark:border-0 dark:text-darkText"
            ref={priceLowRef}
            onClick={(e) => e.stopPropagation()} // 여기서 이벤트 전파 막기
            onFocus={(e) => e.stopPropagation()}
          />

          <span className="px-2">~</span>

          <input
            value={priceHigh || ""}
            type="number"
            onChange={(e: any) => setPriceHigh(e.target.value)}
            className="w-[80px] border border-gray px-2 py-1 dark:bg-darkBanner dark:border-0 dark:text-darkText"
            ref={priceHighRef}
            onClick={(e) => e.stopPropagation()} // 여기서 이벤트 전파 막기
            onFocus={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();

                if (!priceLow) {
                  priceLowRef.current?.focus();
                  return;
                }

                if (!priceHigh) {
                  priceHighRef.current?.focus();
                  return;
                }

                const pricePayload: any = [priceLow, priceHigh];

                sessionStorage.setItem("price", JSON.stringify(pricePayload));

                navigate(`/feed/filter`, {
                  state: {
                    keyword: keywordArray,
                    search: searchString,
                    price: pricePayload,
                    view: viewArray,
                  },
                  replace: true,
                });
              }
            }}
          />
        </div>

        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="dark:text-[#d1d1d1] cursor-pointer"
          onClick={(e: any) => {
            e.preventDefault();

            // 입력 값 검증
            if (!priceLow) {
              priceLowRef.current?.focus();
              return;
            }

            if (!priceHigh) {
              priceHighRef.current?.focus();
              return;
            }

            const pricePayload: any = [priceLow, priceHigh];

            sessionStorage.setItem("price", JSON.stringify(pricePayload));

            navigate(`/feed/filter`, {
              state: {
                keyword: keywordArray,
                search: searchString,
                price: pricePayload,
                view: viewArray,
              },
              replace: true,
            });
          }}
        />
      </div>

      <hr className="my-5" />

      <div className="flex items-center gap-2">
        <Text className="subtitle-3 ">해상도</Text>
        {/* <Text fontSize={12}>| 세로픽셀 기준</Text> */}
      </div>

      <div className="flex items-center gap-3">
        <div>
          <input
            value={viewWidth || ""}
            type="number"
            onChange={(e: any) => setViewWidth(e.target.value)}
            className="w-[80px] border border-gray px-2 py-1 dark:bg-darkBanner dark:border-0 dark:text-darkText"
            ref={viewWidthRef}
            onClick={(e) => e.stopPropagation()} // 여기서 이벤트 전파 막기
            onFocus={(e) => e.stopPropagation()}
          />

          <span className="px-2">~</span>

          <input
            value={viewHeight || ""}
            type="number"
            onChange={(e: any) => setViewHeight(e.target.value)}
            className="w-[80px] border border-gray px-2 py-1 dark:bg-darkBanner dark:border-0 dark:text-darkText"
            ref={viewHeightRef}
            onClick={(e) => e.stopPropagation()} // 여기서 이벤트 전파 막기
            onFocus={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();

                if (!viewWidth) {
                  viewWidthRef.current?.focus();
                  return;
                }

                if (!viewHeight) {
                  viewHeightRef.current?.focus();
                  return;
                }

                const viewPayload: any = [viewWidth, viewHeight];

                sessionStorage.setItem("view", JSON.stringify(viewPayload));

                navigate(`/feed/filter`, {
                  state: {
                    keyword: keywordArray,
                    search: searchString,
                    price: priceArray,
                    view: viewPayload,
                  },
                  replace: true,
                });
              }
            }}
          />
        </div>

        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="dark:text-[#d1d1d1] cursor-pointer"
          onClick={(e: any) => {
            e.preventDefault();

            if (!viewWidth) {
              viewWidthRef.current?.focus();
              return;
            }

            if (!viewHeight) {
              viewHeightRef.current?.focus();
              return;
            }

            const viewPayload: any = [viewWidth, viewHeight];

            sessionStorage.setItem("view", JSON.stringify(viewPayload));

            navigate(`/feed/filter`, {
              state: {
                keyword: keywordArray,
                search: searchString,
                price: priceArray,
                view: viewPayload,
              },
              replace: true,
            });
          }}
        />
      </div>

      <hr className="my-5" />

      <div className="flex items-center gap-2">
        <Text className="subtitle-3 ">지도로 찾기</Text>
      </div>

      <div
        className="flex justify-center cursor-pointer"
        onClick={() => navigate("/location-feed")}
      >
        <div className="border border-[#004A6F] rounded-2xl py-2 w-full flex justify-center items-center gap-2">
          <Text fontSize={14} fontWeight={500} className="">
            지도로 찾기 이동
          </Text>
          <img src="/img/google-icons/exit.svg" alt="exit" width={10} />
        </div>
      </div>

      <hr className="my-5" />

      <div className="flex items-center gap-2">
        <Text className="subtitle-3 ">추천 키워드</Text>
        <FontAwesomeIcon
          icon={faRotateRight}
          className={`w-[14px] h-[14px] cursor-pointer ${
            themeData === "light" || themeData === "" ? "" : "text-darkText"
          }`}
          onClick={onInit}
        />
      </div>

      {/* 키워드 직접 입력 필드 */}
      <div className="flex items-center gap-3">
        <input
          ref={keywordInputtRef}
          value={keywordInput || ""}
          type="text"
          placeholder="직접 입력"
          onChange={(e: any) => setKeywordInput(e.target.value)}
          className="border border-gray px-2 py-1 !max-w-[187px] dark:bg-darkBanner dark:border-0 dark:text-darkText"
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();

              if (!keywordInput) {
                keywordInputtRef.current?.focus();
                return;
              }

              if (keywordInput.trim() !== "") {
                handleCheckboxChange(keywordInput.trim(), true);
                setKeywordInput(""); // 입력 필드 초기화
              }
            }
          }}
        />

        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="dark:text-[#d1d1d1] cursor-pointer"
          onClick={() => {
            if (!keywordInput) {
              keywordInputtRef.current?.focus();
              return;
            }

            if (keywordInput.trim() !== "") {
              handleCheckboxChange(keywordInput.trim(), true);
              setKeywordInput(""); // 입력 필드 초기화
            }
          }}
        />
      </div>
      {info?.map((keyword, index) => (
        <CustomCheckBox
          key={index}
          name={keyword}
          checked={checkedItems.includes(keyword)}
          onChange={(e) => handleCheckboxChange(keyword, e.target.checked)}
          label={keyword}
          labelStyle="label-1"
        />
      ))}
    </div>
  );
}
