export default function MobileEndPage() {
  return (
    <div className="">
      <img
        src={"/img/standbuy/background/mobile-login-success.jpg"}
        alt="mobile-login-success"
      />
    </div>
  );
}
