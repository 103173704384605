//@ts-nocheck
import { Carousel } from "@material-tailwind/react";
import Text from "../design-system/Text";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  modalFetched,
  niceModalFetched,
} from "../../store/reducer/globalModalSlice";
import { useAppDispatch } from "../../store/store";
import { useContext } from "../../util/useContext";

export default function SlideBanner() {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const { me, isLogin } = useContext();

  return (
    <Carousel className="rounded-xl" autoplay loop>
      {/* <div className="relative">
        {!isLoaded && (
          <div className="absolute top-0 left-0 h-full flex items-center justify-center">
            <Skeleton
              height={450}
              width="100vw"
              baseColor="#e0e0e0"
              highlightColor="#f5f5f5"
            />
          </div>
        )}
        <img
          src="/img/standbuy/icon/main/background/notice-banner.svg"
          alt="notice"
          className="h-[450px] w-full object-cover cursor-pointer"
          onClick={() => navigate(`/notice/6`, { state: 6 })}
        />
        <Text
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
          fontSize={80}
          color="white"
          fontWeight={900}
        >
          1년간 자유롭게
        </Text>
        <div className={"absolute bottom-10 left-10 flex items-center gap-2"}>
          <button
            className="rounded-full bg-white py-2 px-5 cursor-pointer"
            onClick={() => navigate(`/notice/6`, { state: 6 })}
          >
            <Text fontWeight={600} removeDarkMode>
              공지사항
            </Text>
          </button>

          <div>
            <Text fontSize={20} color={"white"} fontWeight={500}>
              소유권 거래가 이용권 거래로 통합됐어요.
            </Text>
          </div>
        </div>
        <div className={"absolute bottom-10 right-10"}>
          <img src="/img/standbuy/logo/logo-white.svg" alt="logo-white" />
        </div>
      </div> */}

      <div className="relative">
        {!isLoaded && (
          <div className="absolute top-0 left-0 h-full flex items-center justify-center">
            <Skeleton
              height={450}
              width="100vw"
              baseColor="#e0e0e0"
              highlightColor="#f5f5f5"
            />
          </div>
        )}

        <img
          src="/img/standbuy/icon/main/background/instaStandbuy.svg"
          alt="instaStandbuy"
          className="h-[450px] w-full object-cover cursor-pointer"
          onClick={() =>
            window.open("https://www.instagram.com/open_yourgallery/")
          }
          onLoad={() => setIsLoaded(true)}
        />
        <Text
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
          fontSize={80}
          color="#FFBD42"
          fontWeight={900}
        >
          당신의 사진을 삽니다
        </Text>

        <div className={"absolute bottom-10 left-10 flex items-center gap-2"}>
          <button
            className="rounded-full bg-white py-2 px-5 cursor-pointer"
            onClick={() =>
              window.open("https://www.instagram.com/open_yourgallery/")
            }
          >
            <Text fontWeight={600} removeDarkMode>
              인스타 바로가기
            </Text>
          </button>

          <div>
            <Text fontSize={20} color={"white"} fontWeight={500}>
              당신의 사진에 가치를 부여 합니다.
            </Text>
          </div>
        </div>
        <div className={"absolute bottom-10 right-10"}>
          <img src="/img/standbuy/logo/logo-white.svg" alt="logo-white" />
        </div>
      </div>

      <div className="relative">
        <img
          src="/img/standbuy/icon/main/background/map-bg.svg"
          alt="image 1"
          className="h-[450px] w-full object-cover cursor-pointer"
          onClick={() => navigate("/location-feed")}
        />
        <Text
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
          fontSize={80}
          color="#FFEA00"
          fontWeight={900}
        >
          우리는 지도로 찾아요
        </Text>
        <div className={"absolute bottom-10 left-10 flex items-center gap-2"}>
          <button
            className="rounded-full bg-white py-2 px-5 cursor-pointer"
            onClick={() => navigate("/location-feed")}
          >
            <Text fontWeight={600} removeDarkMode>
              지도로 찾기
            </Text>
          </button>

          <div>
            <Text fontSize={20} color={"white"} fontWeight={500}>
              세계최초 지도기반 콘텐츠 거래 플랫폼
            </Text>
          </div>
        </div>
        <div className={"absolute bottom-10 right-10"}>
          <img src="/img/standbuy/logo/logo-white.svg" alt="logo-white" />
        </div>
      </div>

      <div className="relative">
        <img
          src="/img/standbuy/icon/main/background/upload-bg.svg"
          alt="upload"
          className="h-[450px] w-full object-cover cursor-pointer"
          onClick={() => {
            if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            } else if (isLogin && !me?.mobileVerification) {
              dispatch(niceModalFetched({ show: true }));
            }
          }}
        />
        <Text
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
          fontSize={80}
          color="white"
          fontWeight={900}
        >
          팔릴만 한 콘텐츠 팔기
        </Text>
        <div className={"absolute bottom-10 left-10 flex items-center gap-2"}>
          <button
            className="rounded-full bg-white py-2 px-5 cursor-pointer"
            onClick={() => navigate("/upload")}
          >
            <Text fontWeight={600} removeDarkMode>
              업로드
            </Text>
          </button>

          <div>
            <Text fontSize={20} color={"white"} fontWeight={500}>
              #감성사진 #보다는 #살만한
            </Text>
          </div>
        </div>
        <div className={"absolute bottom-10 right-10"}>
          <img src="/img/standbuy/logo/logo-white.svg" alt="logo-white" />
        </div>
      </div>
    </Carousel>
  );
}
