import React, { useEffect, useRef, useState } from "react";
import Text from "../../../components/design-system/Text";
import Dayjs from "dayjs";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import useIntersectObserver from "../../../components/hooks/useIntersectObserver";
import NoConvertData from "./NoConvertData";

export default function ConvertList({ value }: any) {
  const { get } = useAxios();

  const getFofListData = async (page: any) => {
    try {
      const res = await get(
        `${API_DEFAULT}/user/info/fof?type=${value}&page=${page}&size=10`
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [page, setPage] = useState(0);
  const [commentList, setCommentList] = useState<any>([]);

  const intersectRef = useRef<any>(null);
  const [isLastPage, setIsLastPage] = useState(false);

  const { isIntersect } = useIntersectObserver(intersectRef, {
    rootMargin: "200px",
    threshold: 0.01,
  });

  const loadMoreCommentData = async () => {
    if (isIntersect) {
      try {
        const data = await getFofListData(page);

        if (data?.length === 0) {
          setIsLastPage(true);
        } else {
          // 여기서 data가 반드시 배열인지 확인
          if (Array.isArray(data)) {
            setCommentList((prevComments: any) => [...prevComments, ...data]);
            setPage((prev) => prev + 1);
          } else {
            // data가 배열이 아닐 때의 처리 로직 추가
            console.error("data is not an array", data);
          }
        }
      } catch (error) {
        console.error("Error loading more comment data:", error);
        // 필요한 경우 에러 상태 설정 또는 사용자에게 피드백 제공
      }
    }
  };

  useEffect(() => {
    loadMoreCommentData();
  }, [isIntersect, isLastPage, page, value]);

  return (
    <>
      {commentList?.length < 1 ? (
        <NoConvertData />
      ) : (
        commentList?.map((item: any, index: any) => (
          <div className="py-[30px] border-b border-[#EBEBEB]">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-center gap-6">
                <Text fontSize={20} fontWeight={600} color={"#42BC7F"}>
                  {item?.type === "CONVERT" && "전환"}
                </Text>
                <div className="flex items-center gap-4">
                  <Text fontSize={16} fontWeight={600} color="#b2b2b2">
                    {Dayjs(item?.createTime).format("YYYY.MM.DD")}
                  </Text>
                </div>

                <div className="ml-[240px]">
                  <Text
                    fontSize={16}
                    fontWeight={600}
                    color="#4b4b4b"
                    className=""
                  >
                    {item?.description}
                  </Text>
                </div>
              </div>

              <div className="flex flex-col items-end justify-center">
                <Text fontSize={22} fontWeight={700} color={"#42BC7F"}>
                  {item?.amount} FOF
                </Text>
              </div>
            </div>
          </div>
        ))
      )}
      {!isLastPage && <div id="intersectElement" ref={intersectRef}></div>}
    </>
  );
}
