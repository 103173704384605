import React from "react";
import { useContext } from "../../../util/useContext";
import { Avatar, Tooltip } from "@material-tailwind/react";
import Text from "../../../components/design-system/Text";
import { CustomImage } from "../../../components/design-system/CustomImg";
import { addComma } from "../../../util/number";
import { checkRole } from "../../../util";

export default function AvatarCard({ data }: any) {
  return (
    <div className="px-6 py-[28px] border border-[#F1F1F1] rounded-lg min-w-[284px] max-h-[462px] h-[462px]">
      <div className="flex flex-col items-center justify-center gap-5">
        <Avatar
          variant="circular"
          alt="profile img"
          src={
            data?.profileImage
              ? data?.profileImage
              : "/img/standbuy/icon/no-profile.svg"
          }
          placeholder={undefined}
          className="border border-[#134B70] ring-1 hover:ring-1 ring-[#134B70]  hover:ring-[#396984] p-0.5 w-[147px] h-[147px]"
        />

        <Text
          fontSize={26}
          fontWeight={700}
          color="#1a1a1a"
          className="relative "
        >
          {data?.nickname}

          {data?.grade === "ROLE_DIRECTOR" && (
            <div className={"w-[20px] absolute -top-1 -right-6 z-[998]"}>
              <Tooltip
                content={
                  <div>Director 마크 : 스탠바이가 인증한 전문가에요😀</div>
                }
              >
                <img src={"/img/standbuy/icon/dr-badge.svg"} alt="director" />
              </Tooltip>
            </div>
          )}
        </Text>
      </div>

      <div className="w-full h-[1px] bg-[#F1F1F1] my-5"></div>

      <div className="flex flex-col gap-[30px]">
        <div className="flex items-center mx-[20px]">
          <div className="flex items-center gap-[10px] max-w-[120px] min-w-[100px]">
            <CustomImage
              src="/img/standbuy/icon/follower.svg"
              color="#A1A1A1"
            />
            <Text fontSize={16} fontWeight={600} color="#717171" className="">
              구독자
            </Text>
          </div>
          <Text
            fontSize={20}
            fontWeight={700}
            color="#343434"
            className="ml-6 "
          >
            {data?.subscriberCount}
          </Text>
        </div>

        <div className="flex items-center mx-[18px]">
          <div className="flex items-center gap-[10px] max-w-[120px] min-w-[100px]">
            <CustomImage
              src="/img/standbuy/icon/rank-user.svg"
              color="#A1A1A1"
            />
            <Text fontSize={16} fontWeight={600} color="#717171" className="">
              등급
            </Text>
          </div>
          <Text
            fontSize={20}
            fontWeight={700}
            color="#343434"
            className="ml-6 "
          >
            {checkRole(data?.grade)}
          </Text>
        </div>

        <div className="flex items-center mx-[20px]">
          <div className="flex items-center gap-[10px] max-w-[120px] min-w-[100px]">
            <CustomImage
              src="/img/standbuy/icon/download.svg"
              color="#A1A1A1"
            />
            <Text fontSize={16} fontWeight={600} color="#717171" className="">
              다운로드
            </Text>
          </div>
          <Text
            fontSize={20}
            fontWeight={700}
            color="#343434"
            className="ml-6 "
          >
            {addComma(data?.salesCount)}
          </Text>
        </div>
      </div>
    </div>
  );
}
