import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";
import toastSlice from "./reducer/toastSlice";
import themeSlice from "./reducer/themeSlice";
import authSlice from "./reducer/authSlice";
import uploadSlice from "./reducer/uploadSlice";
import locationSlice from "./reducer/locationSlice";
import geoLocationSlice from "./reducer/geoLocationSlice";
import mainStockSlice from "./reducer/mainStock";
import feedSlice from "./reducer/feedSlice";
import loginEmailSlice from "./reducer/loginEmailSlice";
import globalModalSlice from "./reducer/globalModalSlice";
import filterSlice from "./reducer/filterSlice";
import mapBoundSlice from "./reducer/mapBoundSlice";
import priceInfoSlice from "./reducer/priceInfoSlice";
import uploadEndSlice from "./reducer/uploadEndSlice";
import mobileSlice from "./reducer/mobileSlice";
import browserSlice from "./reducer/browerSlice";
import orderSlice from "./reducer/OrderSlice";

const reducers = combineReducers({
  toast: toastSlice,
  theme: themeSlice,
  auth: authSlice,
  upload: uploadSlice,
  location: locationSlice,
  geoLocation: geoLocationSlice,
  mainStock: mainStockSlice,
  feed: feedSlice,
  email: loginEmailSlice,
  modal: globalModalSlice,
  assets: filterSlice,
  bounds: mapBoundSlice,
  priceInfo: priceInfoSlice,
  uploadEnd: uploadEndSlice,
  mobile: mobileSlice,
  browser: browserSlice,
  order: orderSlice,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "theme",
    "email",
    "priceInfo",
    "uploadEnd",
    "browser",
    "order",
    "auth",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const makeStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
    // middleware: getDefaultMiddleware => [...getDefaultMiddleware(), logger],
    devTools: process.env.NODE_ENV === "development", // 개발자도구 설정
  });
  return store;
};
export default makeStore();
export type RootState = any;
export type AppDispatch = any;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
