export default function ResponsivePage() {
  return (
    <div className="">
      <img
        src={"/img/standbuy/background/mobile-warning-bg.png"}
        alt="mobile-warning-bg"
      />
    </div>
  );
}
