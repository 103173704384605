//@ts-nocheck
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../../store/store";
import { motion } from "framer-motion";
import FillButton from "../../../components/design-system/button/FillButton";
import MobileFooter from "../../../components/layout/footer/MobileFooter";
import CustomFooter from "../../../components/layout/footer/CustomFooter";
import Text from "../../../components/design-system/Text";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "../../../util/useContext";
import { Select, Option } from "@material-tailwind/react";

export default function DirectorSevenPage({ data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { me } = useContext();
  const themeData = useAppSelector((state) => state.theme.data);
  const { number } = useParams();
  const navigate = useNavigate();
  const [channel, setChannel] = useState();
  const [isFocused, setIsFocused] = useState(false);

  const channelCode = [
    { code: "instagram", name: "인스타그램 광고" },
    { code: "facebook", name: "페이스북 광고" },
    { code: "youtube", name: "유튜브" },
    { code: "blog", name: "네이버 블로그" },
    { code: "naver-search", name: "네이버 검색" },
    { code: "google-search", name: "구글 검색" },
    { code: "recommand", name: "지인 추천" },
    { code: "ad", name: "TV광고/지면광고" },
    { code: "etc", name: "기타" },
  ];

  return (
    <>
      <div className="flex flex-col justify-between items-center h-full bg-[#121212]">
        <div
          className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
        >
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title !text-[#ececec] ">디렉터 신청</Text>

            <div className="flex flex-col items-end">
              <Text fontWeight={700} className={"!text-[#ececec] "}>
                0{number}/09
              </Text>
              <Text className={"!text-[#ececec] "}>아직 조금 남았어요..!</Text>
            </div>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <motion.div
            className={`w-full ${isMobile ? "px-10" : "flex flex-col"} mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="flex flex-col items-center gap-10 mt-[100px]">
              <div className="flex flex-col items-center gap-2">
                <Text
                  fontWeight={700}
                  fontSize={20}
                  className={"!text-[#ececec] "}
                >
                  예비 디렉터님이 스탠바이를 알게된 경로
                </Text>

                <Text
                  fontWeight={500}
                  fontSize={16}
                  className={"!text-[#ececec] "}
                >
                  스탠바이를 처음 알게 된 경로를 선택해주세요.
                </Text>
              </div>

              <div className="w-[220px]">
                <Select
                  label="알게된 경로"
                  onClick={() => {
                    setIsFocused(true);
                  }}
                  value={channel}
                  onChange={(val: any) => setChannel(val)}
                  children={undefined}
                  placeholder={undefined}
                  size="lg"
                  className="!bg-[#ffffff] border-[#121212]"
                  animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                  }}
                  labelProps={{
                    className: isFocused ? "hidden" : "",
                  }}
                >
                  {channelCode.map((bank) => (
                    //@
                    <Option key={bank.code} value={bank.code}>
                      {bank.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="flex justify-center mt-10">
              <FillButton
                onClick={() => navigate(`/director/8`, { state: channel })}
                text={"STANDBUY"}
                disabled={!channel}
                className="w-[360px] py-4"
                color={"white"}
              />
            </div>

            <div className="flex gap-4 items-center justify-center">
              <Text
                className="underline underline-offset-4 cursor-pointer my-6  !text-[#ececec] "
                onClick={() => navigate(-1)}
                fontSize={14}
                fontWeight={500}
                color="#363636"
              >
                이전으로
              </Text>
            </div>
          </motion.div>
        </div>
      </div>
      {/* {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />} */}
    </>
  );
}
