import FillButton from "../../components/design-system/button/FillButton";
import Card from "../../components/design-system/Card";
import Text from "../../components/design-system/Text";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import Switcher from "../../components/common/Switcher";
import styled from "styled-components";
import FadeMotionDiv from "../../components/design-system/motion/FadeMotionDiv";
import { GOOGLE_LOGIN, KAKAO_LOGIN, NAVER_LOGIN } from "../../api/api";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import LoginInfoModal from "./modal/LoginInfoModal";
import LoginInputForm from "../loginEmail/LoginInputForm";
import { MetaDatas } from "../../meta/MetaDatas";
import Tooltip from "@mui/material/Tooltip";
import { useContext } from "../../util/useContext";
// const Wrap = styled.div`
//   background-image: url("/img/standbuy/background/login-bg.png");
// `;

export default function LoginPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const location = useLocation();
  const [emailClick, setEmailClick] = useState(false);
  const lastLogin = localStorage.getItem("LOGIN_TYPE");
  const { isLogin } = useContext();
  // const [infoModal, setInfoModal] = useState<any>({ show: true, data: "" });

  return (
    <>
      <MetaDatas siteName={""} title={"로그인"} siteUrl={location.pathname} />
      {isMobile ? (
        <div
          className={`bg-white dark:bg-darkBg flex justify-center items-center`}
        >
          <Switcher className="hidden" />
          <FadeMotionDiv>
            <div className="mt-10">
              <div className="flex flex-col gap-4">
                <div className="flex justify-between w-full">
                  <img
                    src={
                      themeData === "light" || themeData === ""
                        ? "/img/standbuy/logo/logo.svg"
                        : "/img/standbuy/logo/logo-white.svg"
                    }
                    alt="standbuy"
                    onClick={() => navigate("/")}
                    className="cursor-pointer my-4"
                    width={200}
                  />

                  <Text
                    className="underline underline-offset-4 cursor-pointer my-4 "
                    onClick={() => navigate("/join")}
                    fontSize={14}
                    fontWeight={500}
                    color="#363636"
                  >
                    회원가입
                  </Text>
                </div>

                <div>
                  <Text fontSize={32} fontWeight={800} className="">
                    갤러리에서 잠든
                  </Text>

                  <Text fontSize={32} fontWeight={800} className="">
                    내 사진&영상 사고 팔기
                  </Text>
                </div>

                {emailClick && <LoginInputForm />}

                <div className="flex flex-col gap-4">
                  {!emailClick &&
                    (lastLogin === "normal" ? (
                      <Tooltip
                        title="최근 로그인 시도"
                        arrow // 화살표가 있는 툴팁
                        placement="bottom-end" // 툴팁 위치 (top, bottom, left, right 등)
                        open
                      >
                        <div>
                          {/* Tooltip 안에는 반드시 하나의 자식 요소만 들어갈 수 있으므로 div로 감싸줌 */}
                          <FillButton
                            text="이메일로 로그인"
                            onClick={() => setEmailClick(!emailClick)}
                            className="w-full"
                            size="lg"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <FillButton
                        text="이메일로 로그인"
                        onClick={() => {
                          setEmailClick(!emailClick);
                          localStorage.setItem("LOGIN_TYPE", "normal");
                        }}
                        className="w-full"
                        size="lg"
                      />
                    ))}

                  <div>
                    {lastLogin === "google" ? (
                      <Tooltip
                        title="최근 로그인 시도"
                        arrow // 화살표가 있는 툴팁
                        placement="bottom-end" // 툴팁 위치 (top, bottom, left, right 등)
                        open
                      >
                        <div
                          className="border-black  dark:border-gray-50  border-[0.2px] rounded-md py-2.5 px-7 flex items-center gap-4 cursor-pointer"
                          onClick={() => {
                            const url = GOOGLE_LOGIN;
                            window.location = url as any;
                            localStorage.setItem("LOGIN_TYPE", "google");
                          }}
                        >
                          <img
                            src="/img/standbuy/sns/sns_google.png"
                            width={30}
                            alt="sns_google"
                            className="cursor-pointer"
                          />
                          <Text fontSize={18} fontWeight={700} className="">
                            구글 계정으로 로그인
                          </Text>
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className="border-black  dark:border-gray-50  border-[0.2px] rounded-md py-2.5 px-7 flex items-center gap-4 cursor-pointer"
                        onClick={() => {
                          const url = GOOGLE_LOGIN;
                          window.location = url as any;
                          localStorage.setItem("LOGIN_TYPE", "google");
                        }}
                      >
                        <img
                          src="/img/standbuy/sns/sns_google.png"
                          width={30}
                          alt="sns_google"
                          className="cursor-pointer"
                        />
                        <Text fontSize={18} fontWeight={700} className="">
                          구글 계정으로 로그인
                        </Text>
                      </div>
                    )}
                  </div>

                  <div>
                    {lastLogin === "kakao" ? (
                      <Tooltip
                        title="최근 로그인 시도"
                        arrow // 화살표가 있는 툴팁
                        placement="bottom-end" // 툴팁 위치 (top, bottom, left, right 등)
                        open
                      >
                        <div
                          className="border-black  dark:border-gray-50  border-[0.2px] rounded-md py-2.5 px-7 flex items-center gap-4 cursor-pointer"
                          onClick={() => {
                            const url = KAKAO_LOGIN;
                            window.location = url as any;
                            localStorage.setItem("LOGIN_TYPE", "kakao");
                          }}
                        >
                          <img
                            src="/img/standbuy/sns/sns_kakao.png"
                            width={30}
                            alt="sns_kakao"
                            className="cursor-pointer"
                          />
                          <Text fontSize={18} fontWeight={700} className="">
                            카카오톡 계정으로 로그인
                          </Text>
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className="border-black  dark:border-gray-50  border-[0.2px] rounded-md py-2.5 px-7 flex items-center gap-4 cursor-pointer"
                        onClick={() => {
                          const url = KAKAO_LOGIN;
                          window.location = url as any;
                          localStorage.setItem("LOGIN_TYPE", "kakao");
                        }}
                      >
                        <img
                          src="/img/standbuy/sns/sns_kakao.png"
                          width={30}
                          alt="sns_kakao"
                          className="cursor-pointer"
                        />
                        <Text fontSize={18} fontWeight={700} className="">
                          카카오톡 계정으로 로그인
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </FadeMotionDiv>
        </div>
      ) : (
        <div
          className={`bg-gray-200 dark:bg-black h-screen flex justify-center items-center bg-cover`}
        >
          <Switcher className="hidden" />
          <FadeMotionDiv>
            <Card>
              <div className="flex flex-col gap-10">
                <div className="flex justify-between w-full">
                  <img
                    src={
                      themeData === "light" || themeData === ""
                        ? "/img/standbuy/logo/logo.svg"
                        : "/img/standbuy/logo/logo-white.svg"
                    }
                    alt="standbuy"
                    onClick={() => navigate("/")}
                    className="cursor-pointer my-4"
                    width={200}
                  />

                  <Text
                    className="underline underline-offset-4 cursor-pointer my-4 "
                    onClick={() => navigate("/join")}
                    fontSize={14}
                    fontWeight={500}
                    color="#363636"
                  >
                    회원가입
                  </Text>
                </div>

                <div>
                  <Text fontSize={32} fontWeight={800} className="">
                    갤러리에서 잠든
                  </Text>

                  <Text fontSize={32} fontWeight={800} className="">
                    내 사진&영상 사고 팔기
                  </Text>
                </div>

                {emailClick && <LoginInputForm />}

                <div className="flex flex-col gap-4">
                  {!emailClick &&
                    (lastLogin === "normal" ? (
                      <Tooltip
                        title="최근 로그인 시도"
                        arrow // 화살표가 있는 툴팁
                        placement="right" // 툴팁 위치 (top, bottom, left, right 등)
                        open
                      >
                        <div>
                          {/* Tooltip 안에는 반드시 하나의 자식 요소만 들어갈 수 있으므로 div로 감싸줌 */}
                          <FillButton
                            text="이메일로 로그인"
                            onClick={() => setEmailClick(!emailClick)}
                            className="w-full"
                            size="lg"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <FillButton
                        text="이메일로 로그인"
                        onClick={() => {
                          setEmailClick(!emailClick);
                          localStorage.setItem("LOGIN_TYPE", "normal");
                        }}
                        className="w-full"
                        size="lg"
                      />
                    ))}

                  <div>
                    {lastLogin === "google" ? (
                      <Tooltip
                        title="최근 로그인 시도"
                        arrow // 화살표가 있는 툴팁
                        placement="right" // 툴팁 위치 (top, bottom, left, right 등)
                        open
                      >
                        <div
                          className="border-black dark:border-gray-50 border-[0.2px] rounded-md py-2.5 px-7 flex items-center cursor-pointer"
                          onClick={() => {
                            const url = GOOGLE_LOGIN;
                            window.location = url as any;
                            localStorage.setItem("LOGIN_TYPE", "google");
                          }}
                        >
                          <img
                            src="/img/standbuy/sns/sns_google.png"
                            width={30}
                            alt="sns_google"
                            className="cursor-pointer"
                          />

                          <div className="flex-1 text-center mr-8">
                            <Text fontSize={18} fontWeight={700}>
                              구글 계정으로 로그인
                            </Text>
                          </div>
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className="border-black dark:border-gray-50 border-[0.2px] rounded-md py-2.5 px-7 flex items-center cursor-pointer"
                        onClick={() => {
                          const url = GOOGLE_LOGIN;
                          window.location = url as any;
                          localStorage.setItem("LOGIN_TYPE", "google");
                        }}
                      >
                        <img
                          src="/img/standbuy/sns/sns_google.png"
                          width={30}
                          alt="sns_google"
                          className="cursor-pointer"
                        />

                        <div className="flex-1 text-center mr-8">
                          <Text fontSize={18} fontWeight={700}>
                            구글 계정으로 로그인
                          </Text>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    {lastLogin === "kakao" ? (
                      <Tooltip
                        title="최근 로그인 시도"
                        arrow // 화살표가 있는 툴팁
                        placement="right" // 툴팁 위치 (top, bottom, left, right 등)
                        open
                      >
                        <div
                          className="border-black dark:border-gray-50 border-[0.2px] rounded-md py-2.5 px-7 flex items-center cursor-pointer"
                          onClick={() => {
                            const url = KAKAO_LOGIN;
                            window.location = url as any;
                            localStorage.setItem("LOGIN_TYPE", "kakao");
                          }}
                        >
                          <img
                            src="/img/standbuy/sns/sns_kakao.png"
                            width={30}
                            alt="sns_kakao"
                            className="cursor-pointer"
                          />
                          <div className="flex-1 text-center mr-8">
                            <Text fontSize={18} fontWeight={700} className="">
                              카카오톡 계정으로 로그인
                            </Text>
                          </div>
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className="border-black dark:border-gray-50 border-[0.2px] rounded-md py-2.5 px-7 flex items-center cursor-pointer"
                        onClick={() => {
                          const url = KAKAO_LOGIN;
                          window.location = url as any;
                          localStorage.setItem("LOGIN_TYPE", "kakao");
                        }}
                      >
                        <img
                          src="/img/standbuy/sns/sns_kakao.png"
                          width={30}
                          alt="sns_kakao"
                          className="cursor-pointer"
                        />
                        <div className="flex-1 text-center mr-8">
                          <Text fontSize={18} fontWeight={700} className="">
                            카카오톡 계정으로 로그인
                          </Text>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </FadeMotionDiv>
        </div>
      )}
    </>
  );
}
