//@ts-nocheck
import Banner from "../../components/common/Banner";
import Text from "../../components/design-system/Text";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { setSearchStudioList } from "../../store/reducer/mainStock";
import StockListCard from "../feed/stock-list/StockListCard";
import { FeedNav } from "../../components/design-system/FeedNav";
import { Avatar, IconButton, Tooltip } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faHeart } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT, URL_HOST } from "../../api/api";
import { ChipDelete } from "../../components/feed/filter/ChipDelete";
import FilterBar from "../../components/feed/filter/FilterBar";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../api";
import MobileTopSection from "../../components/common/MobileTopSection";
import SlideBanner from "../../components/common/SlideBanner";
import FilterContainer from "../../components/feed/filter/box/FilterContainer";
import { SearchChip } from "../../components/feed/filter/SearchChip";
import { PriceChip } from "../../components/feed/filter/PriceChip";
import { ViewChip } from "../../components/feed/filter/viewChip";
import { motion } from "framer-motion";
import StockText from "../../components/design-system/StockText";
import ReactHlsPlayer from "react-hls-player";
import { styled } from "styled-components";
import FeedInfoModal from "../feed/modal/FeedInfoModal";
import { MetaDatas } from "../../meta/MetaDatas";

export default function FeedFilterPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const { state } = useContext();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);

  // chips 상태
  const [chips, setChips] = useState(() => {
    if (Array.isArray(state.keyword)) {
      return state.keyword;
    } else if (state.keyword) {
      return [state.keyword];
    } else {
      return [];
    }
  });
  const [searchChips, setSearchChips] = useState<any>();
  const [priceChips, setPriceChips] = useState<any>();
  const [viewChips, setViewChips] = useState<any>();
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [items, setItems] = useState<any[]>([]);
  const [toggle, setToggle] = useState(true);
  const [searchArray, setSearchArray] = useState<any>();

  // hover 중인 아이템 식별자
  const [hoverItemKey, setHoverItemKey] = useState<number | null>(null);

  const priceString: any = sessionStorage.getItem("price");
  const priceArray: any[] = JSON.parse(priceString || "[]");

  const viewString: any = sessionStorage.getItem("view");
  const viewArray: any[] = JSON.parse(viewString || "[]");

  // 데이터를 가져오는 함수
  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await instance.post(`${API_DEFAULT}/contents/search`, {
      text: state.search || null,
      grade: chips?.includes("DIRECTOR") ? "ROLE_DIRECTOR" : null,
      classification: chips?.includes("IMAGE")
        ? "IMAGE"
        : chips?.includes("VIDEO")
          ? "VIDEO"
          : null,
      priceFrom: state?.price?.[0] ?? priceArray?.[0] ?? null,
      priceTo: state?.price?.[1] ?? priceArray?.[1] ?? null,
      resolutionFrom: state?.view?.[0] ?? viewArray?.[0] ?? null,
      resolutionTo: state?.view?.[1] ?? viewArray?.[1] ?? null,
      keywords:
        chips && chips.length > 0
          ? chips.includes("IMAGE") ||
            chips.includes("VIDEO") ||
            chips.includes("구독") ||
            chips.includes("DIRECTOR")
            ? (() => {
                const filtered = chips.filter(
                  (item: any) =>
                    item !== "IMAGE" &&
                    item !== "VIDEO" &&
                    item !== "구독" &&
                    item !== "DIRECTOR"
                );
                return filtered.length > 0 ? filtered : null;
              })()
            : chips
          : null,
      sub: !!chips?.includes("구독"),
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res.data.data;
      if (nextItems.length === 0) {
        return [];
      }
      nextItems.forEach((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        // 아이템마다 고유 key를 만들거나, API의 id를 사용
        item.key = nextKey + index;
      });
    }
    return nextItems;
  }

  // chips나 state가 변경될 때 items와 groupKey를 초기화
  useEffect(() => {
    setItems([]);
    setGroupKey(null);
  }, [chips, searchChips]);

  // 맨 위로 올리는 함수
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // state가 없을 경우 /feed로 리디렉션
  useEffect(() => {
    if (
      (!state?.keyword || state.keyword.length < 1) &&
      (!state?.search || state.search.length < 1) &&
      (!state?.price || state.price.length < 1) &&
      (!state?.view || state.view.length < 1)
    ) {
      navigate("/feed");
    }
  }, [chips, state, navigate]);

  // 검색어 배열로 설정
  useEffect(() => {
    setSearchArray(Array(state?.search));
  }, [state?.search]);

  // StockCard 컴포넌트 레이아웃 깨짐으로 인한 직접 선언
  const playerRef = useRef() as any;
  const { me } = useContext();
  const location = useLocation();

  // 모달(예시)
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const [endModal, setEndModal] = useState<any>({ show: false, data: "" });
  const { versionData } = useAppSelector((state) => state.mobile);

  const popupWidth = 1400;
  const popupHeight = 1000;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  useEffect(() => {
    window.navigateToPage = (url: string, options?: any) => {
      navigate(url, options);
    };
  }, [navigate]);

  return (
    <>
      <MetaDatas siteName={""} title={"콘텐츠"} siteUrl={location.pathname} />

      <div
        className={`${isMobile ? "mx-3 mt-[80px]" : "mx-12 mt-[120px]"} mb-10`}
      >
        <div className="my-[50px] mb-[30px]">
          {isMobile ? <MobileTopSection /> : <SlideBanner />}
        </div>

        {/* 검색 상태 표시 */}
        <div className="flex items-center gap-4 mb-[30px]">
          <Text className="title " fontSize={28}>
            <Text
              span
              color="#134B70"
              className="mr-[10px] "
              removeDarkMode
              fontSize={28}
              fontWeight={600}
            >
              {state?.search ? `검색어 : ${state.search}, ` : null}
              {priceArray?.length > 1
                ? `가격 : ${priceArray[0]} ~ ${priceArray[1]}, `
                : null}
              {viewArray?.length > 1
                ? `해상도 : ${viewArray[0]} ~ ${viewArray[1]}, `
                : null}
              {chips?.length >= 1 &&
                chips.map((item: any, index: any) => (
                  <Text
                    key={index}
                    span
                    color="#134B70"
                    className="title mr-[10px] "
                    removeDarkMode
                    fontSize={28}
                    fontWeight={600}
                  >
                    {chips.length > 1 ? item + "," : item}
                  </Text>
                ))}
            </Text>{" "}
            검색 결과
          </Text>
        </div>

        {/* 필터 영역 */}
        <div className="mb-[30px] flex gap-5 overflow-auto">
          {/* 모바일이면 상단에 FilterBar */}
          {isMobile && <FilterBar items={items} />}
          {state.search && (
            <SearchChip
              state={searchArray}
              chips={searchChips}
              setChips={setSearchChips}
            />
          )}
          {state?.price && (
            <PriceChip
              state={state.price}
              chips={priceChips}
              setChips={setPriceChips}
            />
          )}
          {state?.view && (
            <ViewChip
              state={state.view}
              chips={viewChips}
              setChips={setViewChips}
            />
          )}
          <ChipDelete
            state={state.keyword}
            chips={chips}
            setChips={setChips}
            setGroupKey={setGroupKey}
          />
        </div>

        {/* 메인 컨텐츠: 이미지 그리드 */}
        <div className={`mt-[30px] ${!isMobile && "flex"}`}>
          {/* 데스크톱 사이즈라면 왼쪽 FilterContainer */}
          {!isMobile && (
            <FilterContainer toggle={toggle} setToggle={setToggle} />
          )}

          <JustifiedInfiniteGrid
            className="transition-all duration-300 ease-in-out"
            style={{
              width: toggle && !isMobile ? "calc(100% - 220px)" : "100%",
            }}
            loading={
              items.length >= 1 && (
                <img
                  src={
                    themeData === "light" || themeData === ""
                      ? "/img/standbuy/animation/standbuy-walking-dark.gif"
                      : "/img/standbuy/animation/standbuy-walking-white.gif"
                  }
                  alt="loading"
                  width={320}
                />
              )
            }
            stretch={true}
            passUnstretchRow={true}
            sizeRange={isMobile ? [228, 228] : [440, 540]}
            {...(isMobile && { columnRange: [0, 2] })}
            threshold={3000}
            gap={8}
            onRequestAppend={async (e) => {
              const nextGroupKey = (+e.groupKey! || 0) + 1;

              // 중복 호출 방지
              if (groupKey !== null && nextGroupKey <= groupKey) {
                return;
              }

              setGroupKey(nextGroupKey);
              e.wait();

              setTimeout(async () => {
                const add = await getItems(nextGroupKey, 30);
                if (add.length === 0) {
                  e.ready();
                  return true;
                }
                dispatch(setSearchStudioList({ data: add, reset: false }));
                setItems((prevItems) => [...prevItems, ...add]);
                e.ready();
              }, 1);
            }}
          >
            {/* 아이템 렌더링 */}
            {items.length < 1 ? (
              <div className="flex flex-col items-center justify-center">
                <Text className="subtitle-2 ">조회된 피드가 없습니다.</Text>
              </div>
            ) : (
              items.map((item: any, index: any) => {
                const isHover = hoverItemKey === item.key;

                return (
                  <Content data-grid-groupkey={item.groupKey} key={index}>
                    <div>
                      {item?.grade === "ROLE_DIRECTOR" && (
                        <div
                          className={"w-[30px] absolute top-2 right-2 z-[998]"}
                        >
                          <Tooltip content="Director 마크 : 전문가들의 콘텐츠에요😀">
                            <img
                              src="/img/standbuy/icon/dr-badge.svg"
                              alt="director badge"
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>

                    <img
                      className={`rounded-xl ${
                        item?.status === "SANCTION" ? "blur-lg" : ""
                      }`}
                      src={item.thumbnailPath}
                      alt="thumbnail"
                      data-grid-maintained-target="true"
                    />

                    {isHover && (
                      <aside className={`status ${isHover ? "show" : ""}`}>
                        <div className="h-full flex flex-col justify-end items-start gap-4">
                          <div className="flex items-center gap-3">
                            <div className="min-w-[30px] min-h-[30px] w-[30px] h-[30px]">
                              <Avatar
                                size="sm"
                                variant="circular"
                                alt="profile img"
                                src={
                                  item?.memberProfileImage
                                    ? item?.memberProfileImage
                                    : "/img/standbuy/icon/no-profile.svg"
                                }
                                placeholder={undefined}
                                className="border border-[#134B70] ring-1 ring-[#134B70] p-0.5 w-[30px] h-[30px] cursor-pointer z-[100]"
                              />
                            </div>
                            <StockText
                              fontSize={15}
                              fontWeight={600}
                              color="#fff"
                              className="truncate"
                            >
                              {item?.memberNickname}
                            </StockText>
                          </div>

                          <div className="w-full flex items-center justify-between">
                            <StockText
                              fontSize={18}
                              fontWeight={700}
                              color="#fff"
                              className="truncate"
                            >
                              {item?.contentName}
                            </StockText>
                            <div className="flex items-center gap-1">
                              <FontAwesomeIcon
                                fontSize={20}
                                icon={faHeart}
                                className={`text-red-500`}
                              />
                              <Text
                                fontSize={15}
                                fontWeight={700}
                                color="#ef4444"
                              >
                                {item?.likesCount}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </aside>
                    )}

                    <button
                      onMouseEnter={() => setHoverItemKey(item.key)}
                      onMouseLeave={() => setHoverItemKey(null)}
                      className="item w-full h-full absolute top-0 left-0"
                      onClick={() => {
                        if (item?.status === "SANCTION") {
                          setInfoModal({ show: true, data: item });
                          if (me?.memberId === item?.memberId) {
                            setEndModal({ show: true, data: item });
                          }
                        } else {
                          window.name = JSON.stringify({
                            contentId: item?.contentId,
                          });
                          window.open(
                            `${URL_HOST}feed-info`,
                            "feed",
                            `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
                          );
                        }
                      }}
                    >
                      {isHover && item?.classification === "VIDEO" && (
                        <div className="video-wrap">
                          {item?.status === "ENABLE" && (
                            <ReactHlsPlayer
                              className="video"
                              autoPlay={true}
                              controls={false}
                              muted={true}
                              loop={true}
                              playerRef={playerRef}
                              src={item?.previewPath}
                              hlsConfig={{
                                maxLoadingDelay: 4,
                                minAutoBitrate: 0,
                                lowLatencyMode: true,
                              }}
                            />
                          )}
                        </div>
                      )}
                    </button>
                  </Content>
                );
              })
            )}
          </JustifiedInfiniteGrid>
        </div>

        <div
          className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
        >
          <div className="flex flex-col justify-center items-center gap-4">
            <FeedNav />
            {!isMobile && (
              <IconButton
                className="rounded-full px-[28px] py-[28px]"
                size="lg"
                onClick={moveToTop}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </IconButton>
            )}
          </div>
        </div>

        {infoModal.show && (
          <FeedInfoModal
            open={infoModal.show}
            onClose={() => setInfoModal({ show: false })}
            data={infoModal.data}
          />
        )}
      </div>
    </>
  );
}

const Content = styled.div`
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  /* aside.status 기본 상태 */
  .status {
    pointer-events: none;
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
    padding: 18px 25px;
    background: linear-gradient(
      to bottom,
      rgba(249, 249, 249, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* 필요에 따라 pointer-events 조정 */
  }

  /* .show 클래스가 붙으면 보임 */
  .status.show {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  /* 비디오 미리보기 영역 */
  .video-wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: 9;
  }
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* 버튼(hover 영역) */
  button.item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 11;
  }
`;
