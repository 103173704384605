import React from "react";
import Text from "../design-system/Text";

const FilterCheckBox = ({ label, checked, onChange, name }: any) => {
  return (
    <div
      className={`custom-checkbox ${checked ? "checked bg-[#004A6F]" : "border border-[#004A6F] rounded-2xl"}`}
      onClick={() => onChange({ target: { checked: !checked } })}
    >
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <Text color={checked ? "white" : "black"} className="" fontSize={14}>
        {label}
      </Text>
    </div>
  );
};

export default FilterCheckBox;
