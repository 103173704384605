import React, { useEffect, useState } from "react";
import Text from "../../components/design-system/Text";
//@ts-ignore
import Lottie from "react-lottie";
import LottieData from "../../assets/lottie/joinScuess.json";
import FadeMotionDiv from "../../components/design-system/motion/FadeMotionDiv";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT } from "../../api/api";
import { useAxios } from "../../lib/api/useAxios";
import { useContext } from "../../util/useContext";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";

export default function UploadSccuess() {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(4);
  const { get } = useAxios();
  const { state } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  // const { data, status } = useQuery({
  //   queryKey: ["convert-stat"],
  //   queryFn: async () =>
  //     await get(`${API_DEFAULT}/contents/convert-stat?convertId=${state}`),
  //   refetchInterval: 3000,
  //   refetchIntervalInBackground: true,
  // });

  useEffect(() => {
    const timeout = setTimeout(() => {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            return prevTimer;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (timer === 0) {
      navigate("/feed");
    }
  }, [timer]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="h-full">
      <FadeMotionDiv flag={true}>
        <div className="flex flex-col  justify-center">
          <Lottie
            options={defaultOptions}
            height={200}
            width={200}
            isClickToPauseDisabled={true}
          />
          <div className="flex justify-center mt-2">
            <div className="flex flex-col items-center gap-2  mb-4">
              <Text className="subtitle-2 ">업로드에 성공했습니다.</Text>
              <Text className="label-1 ">
                피드에 반영되기 전까지 시간이 소요될 수 있습니다.
              </Text>
              <Text className="label-1 ">
                {timer}초 후 피드 화면으로 이동합니다.
              </Text>
            </div>
          </div>
        </div>
      </FadeMotionDiv>

      {isMobile ? <MobileFooter /> : <CustomFooter />}
    </div>
  );
}
