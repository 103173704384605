import { uploadAxios, useAxios } from "../../lib/api/useAxios";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import {
  setStatus,
  setThumbnail,
  setUploadPayload,
} from "../../store/reducer/uploadSlice";
import { useMutation } from "@tanstack/react-query";
import Text from "../../components/design-system/Text";
import UploadLoading from "./UploadLoading";
import UploadDetail from "./UploadDetail";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorText, UploadTextColor } from "../../styles/color";
import FillButton from "../../components/design-system/button/FillButton";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import UploadCheckBlock from "./UploadCheckBlock";
import { useMediaQuery } from "react-responsive";
import { FeedNav } from "../../components/design-system/FeedNav";
import { shallowEqual } from "react-redux";
import {
  thumbnailFlagFetched,
  uploadFlagFetched,
} from "../../store/reducer/uploadEndSlice";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { niceModalFetched } from "../../store/reducer/globalModalSlice";
import { useContext } from "../../util/useContext";
import { setGeoPosition } from "../../store/reducer/geoLocationSlice";
import { toastFetched } from "../../store/reducer/toastSlice";
import { instance } from "../../api";
import GoogleAdsense from "react-adsense-google";
import DisplayAds from "../../components/ads/DisplayAds";
import GoogleAd from "../../components/ads/GoogleAd";
import Standbuy from "../../assets/lottie/standbuywalking-walk-dark.gif";
import { MetaDatas } from "../../meta/MetaDatas";

export default function UploadForm() {
  const validTypes = [
    "video/mp4",
    "image/jpeg",
    "image/jpg",
    "image/png",
    "video/quicktime",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/x-flv",
    "video/x-matroska",
    "video/avi",
  ];
  const { me } = useContext();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const stat = useAppSelector((state) => state.uploadEnd.stat, shallowEqual);
  const { form, formProgress, putAws, post, postProgress } = useAxios();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [progressData, setProgressData] = useState(0);
  const [step, setStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadId, setUploadId] = useState<any>("");
  const [originFileName, setOriginFileName] = useState<any>("");
  const [saveFileName, setSaveFileName] = useState<any>("");
  const [btnFlag, setBtnFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partNumberData, setPartNumberData] = useState<any>();
  const [partFlagData, setPartFlagData] = useState<any>();
  const [startUploadFlag, setStartUploadFlag] = useState();

  // detail로 전달할 데이터
  const [detailData, setDetailData] = useState("");

  const fileInputRef: any = useRef(null);

  const fileChangedHandler = (event: any) => {
    let selectedFile = event.target.files[0];
    let fileName = selectedFile?.name;

    const maxFileSizeInBytes = 500 * 1024 * 1024;
    if (!validTypes.includes(selectedFile.type)) {
      alert(
        "해당 파일은 지원되지 않는 형식입니다.\n지원되는 형식: MP4, JPEG, JPG, PNG, QuickTime, AVI, WMV, FLV, MKV"
      );
    } else if (fileName?.length > 99) {
      alert("파일 이름은 최대 99글자까지만 허용해요.");
    } else {
      if (selectedFile?.size > maxFileSizeInBytes) {
        setBtnFlag(true);
        setStep(1);
      } else if (selectedFile?.size < maxFileSizeInBytes) {
        setBtnFlag(false);
        setStep(2);
      }
      setSelectedFile(selectedFile);
      setOriginFileName(fileName);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (100 * 1024 * 1024); // 파일 크기를 MB로 변환하고 반올림
      const firstDigit = Math.floor(fileSizeInMB).toString().charAt(0); // 첫 번째 자리만 가져옴
      setPartFlagData(Number(firstDigit));
    }
  }, [selectedFile]);

  const uploadMultipartFile = useCallback(async () => {
    try {
      const FILE_CHUNK_SIZE = 100 * 1024 * 1024; // 100MB
      const fileSize = selectedFile.size;
      const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE);
      let promisesArray = [];

      let getUploadUrlResp = await instance.post(
        `${API_DEFAULT}/contents/get-upload-url`,
        {
          originFileName: saveFileName,
          totalParts: partFlagData ? partFlagData : partFlagData + 1,
          uploadId: uploadId,
        }
      );

      // 100mb로 나눠서 0이하면 totalParts -> 1 ,  index <= NUM_CHUNKS
      // 0이상이면 partFlagData 사용,  index < NUM_CHUNKS

      if (partFlagData > 0) {
        for (let index = 1; index <= NUM_CHUNKS; index++) {
          let start = (index - 1) * FILE_CHUNK_SIZE;
          let end = index * FILE_CHUNK_SIZE;
          let blob =
            index < NUM_CHUNKS
              ? selectedFile.slice(start, end)
              : selectedFile.slice(start);

          let presignedUrl =
            getUploadUrlResp?.data.data[index - 1]?.presignedUrl;

          let uploadResp = await uploadAxios.put(presignedUrl, blob, {
            headers: { "Content-Type": selectedFile.type },

            onUploadProgress: (progressEvent: any) => {
              const { loaded, total } = progressEvent;

              let percentage = Math.floor((loaded * 100) / total);
              setProgressData(percentage);
            },
          });
          promisesArray.push(uploadResp);
        }
      } else {
        for (let index = 0; index <= NUM_CHUNKS; index++) {
          let start = index * FILE_CHUNK_SIZE;
          let end = index * FILE_CHUNK_SIZE;
          let blob =
            index < NUM_CHUNKS
              ? selectedFile.slice(start, end)
              : selectedFile.slice(start);

          let presignedUrl = getUploadUrlResp?.data.data[index]?.presignedUrl;

          let uploadResp = await uploadAxios.put(presignedUrl, blob, {
            headers: { "Content-Type": selectedFile.type },

            onUploadProgress: (progressEvent: any) => {
              const { loaded, total } = progressEvent;

              let percentage = Math.floor((loaded * 100) / total);
              setProgressData(percentage);
            },
          });
          promisesArray.push(uploadResp);
        }
      }

      let resolvedArray = await Promise.all(promisesArray);

      // 최종 전달 데이터 모음
      let uploadPartsArray = resolvedArray.map((resolvedPromise, index) => ({
        etag: resolvedPromise.headers.etag.replace(/["\\]/g, ""),
        partNumber: index + 1,
      }));

      setPartNumberData(partFlagData >= 1 ? uploadPartsArray?.length : 0);

      const res = await instance
        .post(`${API_DEFAULT}/contents/upload-success`, {
          fileName: saveFileName,
          parts: uploadPartsArray,
          uploadId: uploadId,
          s: startUploadFlag,
          originFileName: saveFileName,
        })
        .then((res) => {
          if (res?.data.success) {
            mutate();
          }
        })
        .catch(() => {
          alert("업로드에 실패했습니다. 다시 시도해주세요.");
          window.location.reload();
        });
    } catch (err) {
      console.log(err);
    }
  }, [selectedFile, originFileName, uploadId]);

  const startUpload = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    try {
      let resp = await instance
        .post(`${API_DEFAULT}/contents/start-upload`, {
          originFileName: originFileName,
          mimeType: selectedFile.type,
        })
        .then((res) => {
          if (res?.data.success) {
            let uploadId = res.data?.data.uploadId;
            setUploadId(uploadId);
            setSaveFileName(res.data?.data.saveFileName);
            setStartUploadFlag(res.data?.data.s);
          }
        })
        .catch(() => {
          alert("업로드에 실패했습니다. 다시 시도해주세요.");
          window.location.reload();
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (uploadId) {
      uploadMultipartFile();
    }
  }, [uploadId]);

  async function makeThumbnailProgress() {
    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;

      let percentage = Math.floor((loaded * 100) / total);
      setProgressData(percentage);
    };
    const res = await instance
      .post(
        `${API_DEFAULT}/contents/thumbnail-new`,
        {
          fileName: originFileName,
          filePath: saveFileName,
          fileType: selectedFile.type.includes("image/") ? "IMAGE" : "VIDEO",
          s: startUploadFlag,
        },
        { onUploadProgress }
      )
      .then((response) => {
        if (response?.data.success) {
          setDetailData(response?.data.data);
          dispatch(thumbnailFlagFetched(response?.data.data.jobId));
        }
      })
      .catch(() => {
        alert("썸네일 생성에 실패했습니다. 다시 시도해주세요.");
        window.location.reload();
      });
  }

  const { mutate, status, isPending } = useMutation({
    mutationFn: makeThumbnailProgress,
  });

  useEffect(() => {
    dispatch(thumbnailFlagFetched(""));
  }, []);

  if (
    progressData > 0 &&
    progressData <= 100 &&
    partFlagData !== partNumberData &&
    selectedFile.type.includes("video/")
  ) {
    return (
      <>
        <UploadLoading
          progressData={progressData}
          setProgressData={setProgressData}
        />
      </>
    );
  } else if (progressData === 100) {
    return (
      <>
        <UploadDetail
          detailData={detailData}
          status={status}
          saveFileName={saveFileName}
          fileType={selectedFile.type.includes("image/") ? "IMAGE" : "VIDEO"}
          originFileName={originFileName}
          startUploadFlag={startUploadFlag}
        />
      </>
    );
  }

  return (
    <>
      <MetaDatas title={"업로드"} siteName={""} siteUrl={location.pathname} />
      <div
        className={`flex flex-col justify-between items-center ${isMobile ? "" : "h-full"}  w-full`}
      >
        <motion.div
          className={`w-full flex justify-center ${isMobile ? "" : "h-full"} items-center`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className="flex flex-col justify-center w-full mx-[200px] max-w-[1280px] mb-20 mt-[120px]">
            {step === 1 ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className={`w-full flex flex-col items-center justify-center`}
              >
                <Text className="title mb-[30px] ">파일 업로드</Text>

                {/* <img src={Standbuy} alt="" width={650} /> */}

                <div>
                  <div className="flex flex-col gap-8 justify-center items-center p-8 rounded-lg">
                    <img
                      src={"/img/standbuy/icon/thumbnail-upload.svg"}
                      alt="thumbnail-upload"
                    />
                    <div className="flex flex-col items-center gap-4">
                      <Text
                        color={UploadTextColor}
                        fontSize={16}
                        fontWeight={600}
                      >
                        {originFileName}
                      </Text>
                      <input
                        type="file"
                        id="file"
                        ref={fileInputRef}
                        onChange={fileChangedHandler}
                        style={{ display: "none" }}
                        accept="video/mp4, image/jpeg, image/jpg, image/png,  video/avi, video/quicktime, video/x-msvideo, video/x-ms-wmv, video/x-flv, video/x-matroska"
                      />

                      {originFileName && !btnFlag ? (
                        <FillButton
                          color={"black"}
                          text={"파일 업로드 시작하기"}
                          onClick={startUpload}
                          loading={loading}
                          size="lg"
                          className={`${isMobile ? "w-[280px]" : "w-[360px]"} focus:!border-none flex justify-center`}
                          disabled={
                            selectedFile &&
                            btnFlag === false &&
                            loading === false
                              ? false
                              : true
                          }
                        />
                      ) : (
                        <FillButton
                          color={"black"}
                          text={
                            stat === ""
                              ? "파일 선택"
                              : "업로드가 진행 중입니다."
                          }
                          onClick={() => {
                            if (!me?.mobileVerification) {
                              dispatch(niceModalFetched({ show: true }));
                            } else {
                              fileInputRef.current.click();
                            }
                          }}
                          size="lg"
                          className={`${isMobile ? "w-[280px]" : "w-[360px]"} focus:!border-none flex justify-center`}
                          disabled={stat !== ""}
                          loading={stat !== ""}
                        />
                      )}
                    </div>

                    <Text color={ErrorText} className="label-1-strong ">
                      {btnFlag
                        ? "파일의 크기가 500MB 이상 입니다."
                        : "현재 500MB 이하만 업로드 가능합니다."}
                    </Text>

                    <Text color={UploadTextColor} className="label-1-strong ">
                      업로드 과정 진행 중 새로고침 또는 페이지 이동을 지양해
                      주시길 부탁드립니다.
                    </Text>
                  </div>
                </div>
              </motion.div>
            ) : (
              <UploadCheckBlock setStep={setStep} />
            )}
          </div>
        </motion.div>

        <Text color={UploadTextColor} className="px-4" fontSize={15}>
          *하단 광고는 STANDBUY와 무관한 광고 입니다.
        </Text>
        <hr className="w-full my-1 border-gray-300 border-[1px]" />

        <div className="w-full sm:max-w-[728px] mx-auto my-5">
          <GoogleAd />
        </div>

        {isMobile ? (
          <MobileFooter />
        ) : (
          <CustomFooter styles={"fixed bottom-0"} />
        )}
      </div>
    </>
  );
}
