import { useEffect, useState } from "react";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";

export default function NoCreditData({ value }: any) {
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <div className="hidden">로딩 중...</div>;
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col gap-10 justify-center items-center">
        <Text className="no-data-title  mt-[90px]">
          {value === "BUY"
            ? "구매한 콘텐츠가 없어요."
            : "판매한 콘텐츠가 없어요."}
        </Text>

        <img
          src={"/img/standbuy/icon/pay-coin-big.svg"}
          className="thumbnail"
        />

        <div className="flex flex-col items-center">
          <Text className="upload-text" color={UploadTextColor}>
            {value === "BUY"
              ? "콘텐츠를 구매해 보세요."
              : "콘텐츠를 판매해 보세요."}
          </Text>
        </div>
      </div>
    </div>
  );
}
