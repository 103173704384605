import React, { useEffect, useState } from "react";
import Card from "../../components/design-system/Card";
import Text from "../../components/design-system/Text";
import { LabelInputReset } from "../../components/design-system/input/LabelInputReset";
import { LabelInputEyes } from "../../components/design-system/input/LabelInputEyes";
import { ErrorText } from "../../styles/color";
import FillButton from "../../components/design-system/button/FillButton";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { toastFetched } from "../../store/reducer/toastSlice";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import CustomCheckBox from "../../components/design-system/CustomCheckBox";
import {
  checkEmailFetched,
  loginEmailFetched,
} from "../../store/reducer/loginEmailSlice";
import { shallowEqual } from "react-redux";
import { signUp } from "../../api/login/login";
import { signIn } from "../../lib/modules/auth";
import { InputReset } from "../../components/design-system/input/InputReset";
import { InputEyes } from "../../components/design-system/input/InputEyes";

export default function LoginInputForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassWord] = useState("");
  const [loading, setLoading] = useState(false);
  const { data, recordChecked } = useAppSelector((state) => state.email);
  const [record, setRecord] = useState(false);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual
  );

  // 이메일 검사: '@', '.' 이 둘다 포함될것.
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let isValidEmail = regex.test(email);

  const regexPwd =
    /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\[\]{};:'"<>,.?/\+\-=~₩`|_]).+$/;
  let isValidPassword = regexPwd.test(password);

  const loginData = {
    email: email,
    password: password,
    browser: browserData,
  };

  const { mutate, status } = useMutation({
    mutationFn: signUp,
    onSuccess: (data) => {
      if (data?.success) {
        signIn({ dispatch: dispatch, data: data.data });

        if (record) {
          dispatch(loginEmailFetched(email));
          dispatch(checkEmailFetched(record));
        }

        navigate("/feed", { replace: true });
      } else {
        setLoading(false);

        if (data?.error?.status) {
          navigate("/login-deny-email", { state: data?.error });
        } else {
          dispatch(
            toastFetched({
              show: true,
              text: data?.error?.message,
              type: "error",
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    if (status === "pending") {
      setLoading(true);
    } else if (status === "success") {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (data && recordChecked) {
      setEmail(data);
    }
  }, [data]);

  useEffect(() => {
    if (recordChecked) {
      setRecord(true);
    }
  }, []);

  useEffect(() => {
    dispatch(checkEmailFetched(record));
  }, [record]);

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full">
        <InputReset
          width="100%"
          value={email}
          setValue={setEmail}
          placeholder={"이메일을 입력해주세요."}
          label="이메일"
          size="lg"
          error={!isValidEmail && email !== ""}
          success={isValidEmail && email !== ""}
        />
        {!isValidEmail && email !== "" && (
          <Text color={ErrorText} className="label-2 mt-1">
            이메일 형식이 올바르지 않습니다.
          </Text>
        )}
      </div>

      <div className="w-full">
        <InputEyes
          type="password"
          width="100%"
          value={password}
          setValue={setPassWord}
          placeholder={"비밀번호를 입력해주세요."}
          label={"비밀번호"}
          minLength={8}
          error={
            (!isValidPassword && password !== "") ||
            (isValidPassword && password !== "" && password.length < 8)
          }
          success={isValidPassword && password !== "" && password.length >= 8}
          onKeyUp={(event: any) => {
            if (event.key === "Enter") {
              mutate(loginData);
            }
          }}
        />
        {(!isValidPassword && password !== "") ||
        (isValidPassword && password !== "" && password.length < 8) ? (
          <Text color={ErrorText} className="label-2 mt-1">
            8자 이상 숫자/영어 소문자/특수문자 조합으로 입력해주세요.
          </Text>
        ) : null}
      </div>

      <div className="mb-3 flex items-center justify-between">
        <CustomCheckBox
          checked={record}
          onChange={() => setRecord(!record)}
          label={"이메일 저장"}
          labelStyle="label-1"
        />

        <div className="flex gap-4 items-center justify-center">
          <Text
            className="underline underline-offset-4 cursor-pointer my-4 "
            onClick={() => navigate("/password-find")}
            fontSize={14}
            fontWeight={500}
            color="#363636"
          >
            비밀번호 찾기
          </Text>
        </div>
      </div>

      <FillButton
        text={"로그인"}
        disabled={!isValidEmail || !isValidPassword}
        onClick={() => {
          mutate(loginData);
        }}
        loading={loading}
        className="flex justify-center"
        size="lg"
      />
    </div>
  );
}
