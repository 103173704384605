import { Modal as MuiModal } from "@mui/material";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";
import Text from "../Text";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { CustomNavBar } from "../../layout/CustomNavBar";
import React from "react";

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const ModalBody = styled.div<any>`
  padding: 16px 65px 65px 65px;

  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 60px;
      padding-left: 16px;
      padding-right: 16px;
    `}
`;

export default function FeedModal({
  title,
  children,
  open,
  onClose,
  noOutSideClosed = false,
  width = 500,
  headerNone = false,
  feed = false,
}: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  const style = {
    "&:focus": {
      outline: "none",
    },
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : width,
    height: isMobile ? "100%" : 850,
    bgcolor: themeData === "light" || themeData === "" ? "white" : "#121212",
    borderRadius: isMobile ? "" : "20px",
    borderColor: "white",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
    overflow: "auto",
  };

  return (
    <MuiModal
      keepMounted
      open={open}
      onClose={noOutSideClosed ? false : onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!headerNone && (
          <ModalHeader>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={onClose}
              fontSize={24}
              className="cursor-pointer "
            />
          </ModalHeader>
        )}
        {isMobile ? <CustomNavBar /> : null}
        <ModalBody feed={feed} isMobile={isMobile}>
          {children}
        </ModalBody>
      </Box>
    </MuiModal>
  );
}
