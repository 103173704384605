import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import React, { useEffect, useRef, useState } from "react";
import AlarmList from "./card/AlarmList";
import { API_DEFAULT } from "../../api/api";
import { LOCAL_STORAGE } from "../../lib/const";
// import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";
import { useAxios } from "../../lib/api/useAxios";
import { useContext } from "../../util/useContext";
import { signOut } from "../../lib/modules/auth";
import { MetaDatas } from "../../meta/MetaDatas";
import { useLocation } from "react-router-dom";

export default function AlarmPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { me } = useContext();
  const { del } = useAxios();
  const location = useLocation();

  async function handleRemoveAll() {
    const res = await del(
      `${API_DEFAULT}/user/alarm/delete/all?memberId=${me?.memberId}`
    );

    window.location.reload();
  }

  return (
    <>
      <MetaDatas siteName={""} title={"알림"} siteUrl={location.pathname} />

      <div
        className={`flex flex-col justify-between h-full ${isMobile ? "" : "pt-[40px]"}`}
      >
        <motion.div
          className={`w-full pb-10 ${isMobile ? "px-10" : "flex justify-center"}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div
            className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
          >
            <div className="flex flex-col justify-center mb-[30px]">
              <div className={"flex items-center justify-between"}>
                <Text className="title ">알림센터</Text>

                <Text
                  color={"#9A9A9A"}
                  className={"cursor-pointer underline underline-offset-4"}
                  onClick={handleRemoveAll}
                >
                  전체삭제
                </Text>
              </div>

              <AlarmList />
            </div>
          </div>
        </motion.div>

        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>
    </>
  );
}
