import React from "react";
import Modal from "../design-system/modal/Modal";
import Text from "../design-system/Text";
import FillButton from "../design-system/button/FillButton";
import { useAppDispatch } from "../../store/store";
import { ticketFetched } from "../../store/reducer/priceInfoSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PreloadedImage from "../design-system/PreloadedImage";

export default function PriceTicketInfoModal({
  open,
  onClose,
  noRemoveBtn,
}: any) {
  const dispatch = useAppDispatch();
  const { ticket } = useSelector((state: any) => state.priceInfo);
  const location = useLocation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerNone
      width={"960px"}
      noOutSideClosed={location.pathname === "/cart"}
    >
      <div className={"flex gap-4"}>
        <div className={"relative"}>
          <PreloadedImage
            src={"/img/standbuy/background/ticket-info-bg-1.svg"}
            alt="owner-info"
            content={
              <div className={"absolute bottom-10 left-4"}>
                <Text color={"white"} fontSize={48} fontWeight={700}>
                  인물 콘텐츠는
                </Text>

                <Text
                  color={"white"}
                  fontSize={48}
                  fontWeight={700}
                  className={"leading-11"}
                >
                  재편집 불가
                </Text>

                <div className={"mt-2"}>
                  <Text color={"white"} fontSize={16} fontWeight={600}>
                    인물 콘텐츠는 누끼,재편집,AI편집 등을 할 수 없어요.
                  </Text>
                  <Text color={"white"} fontSize={16} fontWeight={600}>
                    자세한 내용은 이용약관에서 확인해보세요.
                  </Text>
                </div>
              </div>
            }
          />
        </div>

        <div className="flex flex-col items-center gap-2">
          <div className={"relative"}>
            <PreloadedImage
              src={"/img/standbuy/background/ticket-info-bg-2.svg"}
              alt="owner-info"
              content={
                <div className={"absolute top-10 left-8"}>
                  <Text color={"white"} fontSize={32} fontWeight={900}>
                    1년간
                  </Text>

                  <Text
                    color={"white"}
                    fontSize={32}
                    fontWeight={700}
                    className={"leading-11"}
                  >
                    자유롭게!
                  </Text>
                </div>
              }
            />
          </div>
          <div className={"relative"}>
            <PreloadedImage
              src={"/img/standbuy/background/ticket-info-bg-3.svg"}
              alt="owner-info"
              content={
                <div className={"absolute top-14 left-8"}>
                  <Text fontSize={32} fontWeight={700}>
                    1년 이용권 구매 주의사항
                  </Text>
                  <div className={"mt-3"}>
                    <Text
                      fontSize={16}
                      fontWeight={400}
                      className={"leading-11"}
                    >
                      콘텐츠의 저작권을 구매하는 것이 아니예요!
                    </Text>
                    <Text
                      fontSize={16}
                      fontWeight={400}
                      className={"leading-11"}
                    >
                      콘텐츠는 1년 동안만 사용할 수 있어요.(초과시 법적 책임)
                    </Text>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-4 justify-center mt-8">
        <FillButton
          onClick={onClose}
          text={"확인 했어요"}
          className="w-[350px]"
          size="lg"
          color={"black"}
        />
        {location.pathname === "/cart"
          ? !ticket && (
              <Text
                className="cursor-pointer underline underline-offset-4 cart-nickname "
                onClick={() => {
                  dispatch(ticketFetched(true));
                  onClose();
                }}
              >
                다시 열지 않기
              </Text>
            )
          : null}
      </div>
    </Modal>
  );
}
