import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import BeatLoader from "react-spinners/BeatLoader";

export default function PreloadedImage({ src, alt, className, content }: any) {
  const [loaded, setLoaded] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 820 });

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = src;
  }, [src]);

  return loaded ? (
    <>
      <img src={src} alt={alt} className={className} />
      {content ? content : null}
    </>
  ) : (
    <div className={className}>
      <BeatLoader />
    </div>
  );
}
