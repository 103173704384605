//@ts-nocheck
import Lottie from "react-lottie";
import ErrorData from "../assets/lottie/404.json";
import FillButton from "../components/design-system/button/FillButton";
import { useLocation, useNavigate } from "react-router-dom";
import { MetaDatas } from "../meta/MetaDatas";

export default function ErrorPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ErrorData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <MetaDatas siteName={""} title={"스탠바이"} siteUrl={location.pathname} />
      <div className="flex flex-col justify-center items-center h-screen w-full">
        <Lottie
          options={defaultOptions}
          height={500}
          width={1000}
          isClickToPauseDisabled={true}
        />

        <FillButton
          onClick={() => navigate("/")}
          text="홈으로 이동"
          size="lg"
          className="w-[240px]"
        />
      </div>
    </>
  );
}
