//@ts-nocheck
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { motion } from "framer-motion";
import FillButton from "../../../components/design-system/button/FillButton";
import MobileFooter from "../../../components/layout/footer/MobileFooter";
import CustomFooter from "../../../components/layout/footer/CustomFooter";
import Text from "../../../components/design-system/Text";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "../../../util/useContext";
import { Select, Option } from "@material-tailwind/react";
import { postDirectorEnd } from "../../../api/director/director";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toastFetched } from "../../../store/reducer/toastSlice";

export default function DirectorNinePage({ data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { me, state } = useContext();
  const themeData = useAppSelector((state) => state.theme.data);
  const { number } = useParams();
  const navigate = useNavigate();
  const [buyer, setBuyer] = useState();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const buyerCode = [
    { code: "true", name: "있어요" },
    { code: "false", name: "없어요" },
  ];

  const { mutate } = useMutation({
    mutationFn: postDirectorEnd,
    onSuccess: (data) => {
      if (data?.success) {
        navigate(`/director/10`);
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          })
        );
      }
    },
  });

  return (
    <>
      <div className="flex flex-col justify-between items-center h-full bg-[#121212]">
        <div
          className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
        >
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title !text-[#ececec] ">디렉터 신청</Text>

            <div className="flex flex-col items-end">
              <Text fontWeight={700} className={"!text-[#ececec] "}>
                0{number}/09
              </Text>
              <Text className={"!text-[#ececec] "}>아직 조금 남았어요..!</Text>
            </div>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <motion.div
            className={`w-full ${isMobile ? "px-10" : "flex flex-col"} mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="flex flex-col items-center gap-10 mt-[100px]">
              <div className="flex flex-col items-center gap-2">
                <Text
                  fontWeight={700}
                  fontSize={20}
                  className={"!text-[#ececec] "}
                >
                  스톡 영상을 촬영하거나 판매하신 경험이 있으신가요?
                </Text>

                <Text
                  fontWeight={500}
                  fontSize={16}
                  className={"!text-[#ececec] "}
                >
                  경험이 없으셔도 괜찮습니다:)
                </Text>
              </div>

              <div className="w-[220px]">
                <Select
                  label={"경험"}
                  value={buyer}
                  onClick={() => {
                    setIsFocused(true);
                  }}
                  onChange={(val: any) => {
                    setBuyer(val);
                  }}
                  children={undefined}
                  placeholder={undefined}
                  size="lg"
                  className="!bg-[#ffffff] border-[#121212]"
                  labelProps={{
                    className: isFocused ? "hidden" : "",
                  }}
                >
                  {buyerCode.map((bank) => (
                    //@
                    <Option key={bank.code} value={bank.code}>
                      {bank.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="flex justify-center mt-10">
              <FillButton
                onClick={() => {
                  const payload = {
                    wayToKnow: state?.channel,
                    portfolio: state?.portfolio,
                    experience: buyer,
                  };
                  mutate(payload);
                }}
                text={"STANDBUY"}
                disabled={!buyer}
                className="w-[360px] py-4"
                color={"white"}
              />
            </div>

            <div className="flex gap-4 items-center justify-center">
              <Text
                className="underline underline-offset-4 cursor-pointer my-6  !text-[#ececec] "
                onClick={() => navigate(-1)}
                fontSize={14}
                fontWeight={500}
                color="#363636"
              >
                이전으로
              </Text>
            </div>
          </motion.div>
        </div>
      </div>
      {/* {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />} */}
    </>
  );
}
