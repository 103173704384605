//@ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import LocationFeedMap from "./card/LocationFeedMap";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import {
  setLocationList,
  setLocationListInit,
  setPositions,
  setSearchResult,
} from "../../store/reducer/locationSlice";
import Text from "../../components/design-system/Text";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import StockListCard from "../feed/stock-list/StockListCard";
import { shallowEqual } from "react-redux";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SearchByMap from "../../lib/modules/google-map/SearchByMap";
import debounce from "lodash/debounce";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../api";
import FeedInfoModal from "../feed/modal/FeedInfoModal";
import LocationInfoModal from "./modal/LocationInfoModal";
import LocationSideView from "./card/LocationSideView";
import { useContext } from "../../util/useContext";
import LocationMobileList from "./card/LocationMobileList";
import { MetaDatas } from "../../meta/MetaDatas";

export default function LocationFeedPage() {
  const navigate = useNavigate();
  const { me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [loading, setLoading] = useState(false);
  const bounds = useAppSelector((state) => state.bounds);
  const zoom = useAppSelector((state) => state.bounds.zoom);
  const location = useLocation();
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { positions, locationList } = useAppSelector((state) => state.location);
  const [itmes, setItems] = useState<any>([]);
  const { lat, lng } = useAppSelector(
    (state) => state.geoLocation,
    shallowEqual
  );
  const [contentId, setContentId] = useState<number | null>(null);
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [pos, setPos] = useState({
    lat: !lat ? 37.56462664995 : lat,
    lng: !lng ? 127.02878456871 : lng,
  });
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    setPos({
      lat: !lat ? 37.56462664995 : lat,
      lng: !lng ? 127.02878456871 : lng,
    });
  }, [lat]);

  const debouncedOnAllLocation = useRef(
    debounce(async ({ latMin, latMax, lonMin, lonMax, zoom }) => {
      setLoading(true);
      const payload = {
        latMin: Math.min(latMax, latMin),
        latMax: Math.max(latMax, latMin),
        lonMin: Math.min(lonMax, lonMin),
        lonMax: Math.max(lonMax, lonMin),
        zoom: zoom,
      };
      const res = await instance.post(
        `${API_DEFAULT}/contents/search/markers`,
        payload
      );
      if (res?.data.success) {
        dispatch(setSearchResult([]));
        dispatch(setPositions(res?.data.data));
        setLoading(false);
      } else {
        // 에러 처리
      }
    }, 500)
  ).current;

  useEffect(() => {
    // 데이터가 실제로 로드되었는지 판단하는 조건
    // bounds가 실제 좌표를 가지고 있는지(0이 아닌지) 체크
    const isDataLoaded =
      location.pathname === "/location-feed" &&
      bounds.ne &&
      bounds.sw &&
      bounds.ne.lat !== 0 &&
      bounds.ne.lng !== 0 &&
      bounds.sw.lat !== 0 &&
      bounds.sw.lng !== 0;

    if (!hasFetchedOnce.current && isDataLoaded) {
      hasFetchedOnce.current = true;

      setTimeout(() => {
        debouncedOnAllLocation({
          latMin: bounds.ne.lat,
          latMax: bounds.sw.lat,
          lonMin: bounds.ne.lng,
          lonMax: bounds.sw.lng,
          zoom: zoom,
        });
      }, 500);
    }
  }, [bounds, location.pathname, zoom, debouncedOnAllLocation]);

  const fetchLocations = useCallback(() => {
    if (
      bounds.ne &&
      bounds.sw &&
      bounds.ne.lat !== 0 &&
      bounds.ne.lng !== 0 &&
      bounds.sw.lat !== 0 &&
      bounds.sw.lng !== 0 &&
      location.pathname === "/location-feed"
    ) {
      debouncedOnAllLocation({
        latMin: bounds.ne.lat,
        latMax: bounds.sw.lat,
        lonMin: bounds.ne.lng,
        lonMax: bounds.sw.lng,
        zoom: zoom,
      });
    }
  }, [bounds]);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <MetaDatas
        title={"지도로 찾기"}
        siteName={""}
        siteUrl={location.pathname}
      />

      <div className={`w-full flex max-w-screen`}>
        {!isMobile && (
          <div className="w-1/6 relative">
            <LocationSideView
              loading={loading}
              setLoading={setLoading}
              fetchLocations={fetchLocations}
            />
          </div>
        )}

        <div
          className={`flex flex-col justify-between pt-[40px] ${
            isMobile ? "" : "fixed"
          }`}
          style={{
            left: isMobile ? "" : "calc(16.6667% - 40px)",
            width: "100%",
          }}
        >
          <motion.div
            className={`w-full ${isMobile ? "" : "flex justify-center"} mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className={`${isMobile ? "" : "w-full mx-10 mt-[39px]"}`}>
              <div style={{ position: "relative" }} className={"w-full"}>
                {loading && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 1,
                    }}
                  >
                    <img
                      src={
                        themeData === "light" || themeData === ""
                          ? "/img/standbuy/animation/standbuy-walking-dark.gif"
                          : "/img/standbuy/animation/standbuy-walking-white.gif"
                      }
                      alt="loading"
                      width={320}
                    />
                  </div>
                )}
                <LocationFeedMap lat={lat} lng={lng} />
              </div>

              {isMobile && (
                <LocationMobileList
                  loading={loading}
                  setLoading={setLoading}
                  fetchLocations={fetchLocations}
                />
              )}
            </div>

            <div
              className={`fixed bottom-10 ${
                isMobile ? "right-10" : "right-10"
              } z-[9999]`}
            >
              <div className="flex flex-col justify-center items-center gap-4">
                {isMobile && (
                  <IconButton
                    //@ts-ignore
                    className={`rounded-full ${isMobile ? "px-[18px] py-[18px]" : "px-[28px] py-[28px]"}`}
                    placeholder={undefined}
                    children={undefined}
                    onClick={moveToTop}
                  >
                    <FontAwesomeIcon icon={faArrowUp} size="lg" />
                  </IconButton>
                )}
              </div>
            </div>
          </motion.div>

          {infoModal.show && (
            <LocationInfoModal
              open={infoModal.show}
              onClose={() => setInfoModal({ show: false })}
              data={infoModal.data}
            />
          )}
        </div>
      </div>
    </>
  );
}

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: 100%;
`;
