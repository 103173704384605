//@ts-nocheck
import React from "react";
import FillButton from "../../components/design-system/button/FillButton";
import { useAxios } from "../../lib/api/useAxios";
import { useContext } from "../../util/useContext";
import { API_DEFAULT } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Card from "../../components/design-system/Card";
import Text from "../../components/design-system/Text";
import { UploadTextColor } from "../../styles/color";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { certDataGet } from "../../api/locker/locker";

export default function CertificationPage() {
  const navigate = useNavigate();
  const { state } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const { data } = useQuery({
    queryKey: ["certData-get"],
    queryFn: () => certDataGet(state?.boxId),
  });

  let element = document.getElementById("element-to-print");

  const handleSavePDF = () => {
    const options = {
      margin: [0, 0, 20, 0], // 상단, 오른쪽, 하단, 왼쪽 마진 (mm 단위)
      filename: `STANDBUY_CERT.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true }, // scale은 이미지 품질에 영향을 줌
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={"relative overflow-hidden max-h-full"}>
      <div className={"blur-xl"} id={"element-to-print"}>
        <div>
          <img
            src="/img/standbuy/background/standbuy.png"
            className={"w-full"}
            alt="standbuy"
          />
        </div>

        <div className="div_container px-10">
          <div className="div_paper">
            <div>
              <div className="flex flex-col">
                <div className="flex justify-center mt-10">
                  <span className="text-3xl font-semibold">
                    콘텐츠{" "}
                    <span className="font-bold px-3">
                      {state?.type === "TICKET" ? "1년 사용권" : "소유권 거래"}{" "}
                    </span>
                    인증서
                  </span>
                </div>

                <div className="mt-10 flex justify-start flex-col gap-4">
                  <span className="text-2xl font-semibold">콘텐츠 정보</span>

                  <div className="flex flex-col">
                    <div className="grid grid-cols-3 items-center border border-b-0 border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          콘텐츠 명
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.contentName}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center border border-b-0 border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          콘텐츠 유형
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.contentType}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center border border-b-0 border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          업로드일
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.uploadDate}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center border  border-b-0 border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          판매자
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.sellerName}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center border border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          콘텐츠 번호
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.serialNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="mt-10 flex justify-start flex-col gap-4">
                  <span className="text-2xl font-semibold">거래 정보</span>

                  <div className="flex flex-col">
                    <div className="grid grid-cols-3 items-center border border-b-0 border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          판매자명
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.sellerName}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center border border-b-0 border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          구매자명
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.buyerName}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center border border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          거래번호
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.orderNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div className="flex flex-col">
                <div className="mt-10 flex justify-start flex-col gap-4">
                  <span className="text-2xl font-semibold">사용권 정보</span>

                  <div className="flex flex-col">
                    <div className="grid grid-cols-3 items-center border border-b-0 border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          사용권 유형
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.buyType === "OWNERSHIP" ? "소유권" : "이용권"}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center border border-b-0 border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-200 min-w-[240px] text-lg">
                          거래일
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.buyDate}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center border border-black">
                      <div className="flex">
                        <div className="px-8 py-3 font-medium text-center bg-stone-400 min-w-[240px] text-lg">
                          사용권 기한
                        </div>
                        <div className="w-[1px] h-inherit p-0 bg-black"></div>
                      </div>
                      <div className="col-span-2 px-8 py-3 text-lg">
                        {data?.periodDate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <br />

              <div className="mt-10">
                <span className="text-2xl font-semibold">사용권 유의사항</span>
              </div>

              <div className="flex flex-col gap-4 mt-4 text-lg ">
                <span>
                  "콘텐츠 사용권"이라 함은 콘텐츠를 1년의 기간 동안 사용할 수
                  있는 권한을 의미합니다. 사용권에는 콘텐츠를 이용할 수 있는
                  권리가 포함되나, 지적재산권 및 소유권의 이전은 포함되지
                  않습니다
                </span>

                <span>
                  "콘텐츠"라 함은 이 약관에서 정한 바에 따라 콘텐츠 사용권 또는
                  콘텐츠 소유권의 대상이 되는 저작물, 자료, 정보 등을
                  의미합니다. 콘텐츠는 문자, 영상, 음원, 이미지 등 그 형태를
                  불문하며, 회사에서 제공하는 모든 자료를 포괄합니다.
                </span>

                <span>
                  콘텐츠 사용권에 대한 계약은 아티스트(판매회원)이 제시한 콘텐츠
                  등의 판매 조건에 응하여 구매회원이 청약의 의사표시를 하고 이에
                  대하여 아티스트(판매회원)이 승낙의 의사를 표시함으로써
                  체결됩니다.
                </span>
              </div>

              <div className="flex flex-col gap-4 mt-10 text-lg">
                <span>
                  1. 사용권 구매 회원은 라이선스 규정에 따라 허용된 용도 및
                  기간의 범위 내에서 콘텐츠를 사용할 수 있는 권한을 갖는
                  것뿐이며, 저작권을 양도받는 것은 아닙니다.
                </span>
                <span>
                  2. “스탠바이”에서 제공되는 콘텐츠의 무단 복제, 무단 사용, 배포
                  또는 회사와 사전협의 없이 사이트에 게시된 “콘텐츠 라이선스
                  규정”을 벗어나는 행위에 대해서는 저작권법 및 관련법률에 의한
                  권리 침해로 간주됩니다. 따라서 회원은 이 약관과 라이선스
                  규정에서 명시적으로 허용하는 사용 이외의 이미지 사용으로
                  인하여 발생하는 모든 종류의 손해 또는 책임에 대해 회사와
                  제3자에게 손해를 배상하여야 합니다. 또한 제3자의 청구에
                  대해서는 자신의 비용으로 회사를 면책시켜야 합니다.
                </span>

                <span>
                  3. 사이트에서 제공되는 일부 콘텐츠(인물, 건물, 장소)에 대한
                  초상권, 저작권, 상표권, 특허권(이하 ‘저작권 등’)은 회사가
                  보유하지 않은 것이 있을 수 있습니다(예: 인물의 초상권,
                  로고/심벌/캐릭터/트레이드마크/서비스마크 등의 상표권, 건물의
                  재산권 등). 따라서 “스탠바이”에서 제공되는 일부 콘텐츠는 사용
                  형태에 따라서 회원이 사용 전에 직접 권리자로부터 해당 권리를
                  취득해야 합니다. 사용 시 저작권 등에 대한 권리의 존재가 의심이
                  되는 일부 콘텐츠에 대해서는 사전에 회사에 문의하여야 합니다.
                  만일 회사와 사전협의 없이 사용하여 제3자와 이들 권리에 대한
                  분쟁이 발생할 경우 회사는 일체의 책임을 지지 않으며, 모든
                  책임은 회원에게 있습니다.
                </span>
                <span>
                  4. 회사의 사용중지 고지에도 불구하고 기존 제작물에 사용된
                  콘텐츠를 수정 또는 삭제하지 않고 계속 사용해서 분쟁이 발생할
                  경우 회사는 일체의 책임을 지지 않으며, 모든 책임은 회원에게
                  있습니다.
                </span>
                <span>
                  5. 회사는 다음과 같은 내용이 포함된 콘텐츠의 업로드를
                  금지합니다.
                </span>
                <div className="flex flex-col gap-4">
                  <span>
                    ➀ 제삼자의 저작권, 기타 지적재산권, 초상권 등을 침해하는
                    내용
                  </span>
                  <span>
                    ➁ 음란물, 명예훼손 또는 그 왜 불법적이거나 비도덕적인
                    콘텐츠가 포함된 내용
                  </span>
                  <span>➂ 불법적이거나 폭력적인 행동을 묘사하는 내용</span>

                  <span>➃ 동물 학대 또는 동물에게 폭력을 행사하는 내용</span>
                  <span>➄ 사기성 광고 또는 불공정 경쟁을 일으키는 내용</span>
                  <span>
                    ➅ 상표, 디자인 마크, 사업자명, 로고 일부가 포함된 내용
                  </span>
                </div>

                <span className={""}>
                  6. 회사는 초상권, 재산권, 지적재산권 등의 권리가 침해된
                  콘텐츠의 업로드를 금지합니다.
                </span>

                <span className={""}>
                  7. 위와 같은 문제로 콘텐츠에 문제가 발생할 경우, 관련된 모든
                  책임은 콘텐츠를 업로드한 회원에게 있으며, 회사는 어떠한 책임도
                  부담하지 않습니다.
                </span>
                <span className={""}>
                  8. 허위의 정보를 입력하거나 약관을 충분히 숙지하지 않고
                  콘텐츠를 업로드하여 문제가 발생한 경우, 관련된 모든 책임은
                  콘텐츠를 업로드한 회원에게 있으며, 회사는 어떠한 책임도
                  부담하지 않습니다.
                </span>

                <div className="mt-10">
                  <span className="text-2xl font-semibold">라이선스 안내</span>
                </div>

                <div className="flex flex-col gap-4 mt-4">
                  <span>
                    1. 회원은 “스탠바이”에서 정한 “이용약관”을 숙지하여야 하며,
                    회원가입기간과 각 회원 별 라이선스 (이용허락) 범위 안에서
                    이용하여야 합니다.
                  </span>

                  <span>
                    2. 회원이 회사와 사전협의 없이 “이용약관 규정”(이용허락
                    범위)을 벗어나는 용도로 사용할 경우, 다운로드 받은 콘텐츠에
                    대하여 저작권 보호를 받을 수 없으며 적발 시 형사처벌이나
                    손해배상의 의무를 지게 됩니다
                  </span>
                </div>

                <div className="mt-10">
                  <span className="text-2xl font-semibold">
                    사용권 사용범위
                  </span>
                </div>

                <div className="flex flex-col gap-4 mt-4">
                  <span>
                    1. 사이트에서 제공되는 콘텐츠는 광고와 홍보를 위한 '디자인
                    요소'로만 사용해야 하며, 제공하는 콘텐츠의 전부 또는 일부를
                    활용하여 특정 회사의 상품으로 오인시키거나 콘텐츠 상의 인물
                    (모델)이 특정 상품의 효용이나 품질을 보증하는 것처럼
                    사용하는 것을 금합니다.
                  </span>

                  <span className={""}>
                    2. 모든 콘텐츠에 대하여 변경, 모방, 가공 등의 방법으로
                    재사용 및 재배포, 재판매할 수 없으며 어떠한 경우에도 권리를
                    주장할 수 없습니다.
                  </span>

                  <span className={""}>
                    3. 회원은 콘텐츠를 외부에서 다운로드할 수 있도록 유무선의
                    온라인 네트워크나 휴대전화로 배포할 수 있는 형태로
                    업로드해서는 안 됩니다. P2P, 블로그, 카페, 콘텐츠 공유
                    네트워크, SNS, 온라인 클라우드 시스템 등에 업로드하거나
                    웹사이트 내 자료실 형태로 보관할 수 없고 커뮤니티, SNS 등에
                    스크랩할 수 있도록 게재하는 것을 금합니다. 또한, 어떠한
                    경우에도 제3자에게 ID와 비밀번호를 제공하여 콘텐츠 사용을
                    공유할 수 없습니다.
                  </span>

                  <br />

                  <span className={""}>
                    4. 회원은 어떠한 경우에도 회원에게 부여되는 콘텐츠 사용에
                    대한 권리를 타인에게 판매, 재판매, 배급, 양도, 대여, 임대,
                    임차, 복제, 송신 (메신저, 웹하드, FTP, 네트워크 등) 하는
                    행위를 금합니다.
                  </span>

                  <span className={""}>
                    5. 다운로드한 콘텐츠를 전시회, 공모전, 행사 등의 엠블럼,
                    로고, 심벌, 트레이드마크, 서비스마크, 상표, 캐릭터 등의
                    상징물로 사용할 수 없습니다.
                  </span>

                  <span className={""}>
                    6. 동양 인물 콘텐츠는 사회 미풍양속을 저해하는 용도
                    (성인오락실, 전화방, 음란물, 성인 채팅사이트, 성인 커뮤니티
                    등의 성인 사이트 및 인쇄물, 성인 제품, 유흥업소 및 숙박업소,
                    고리대금업, 운세상담, 사주풀이 등의 풍속업 등)와 채팅
                    당사자로 오인할 수 있는 인물사진, 비뇨기과, 성형외과,
                    산부인과에서 사용하는 것을 금합니다. 또한, 특정 제품이나
                    서비스를 모델이 보증하는 형식의 과대광고 등에 사용하거나,
                    모델의 신체, 얼굴 등과 제3자의 사진을 합성하여 재가공하는
                    행위 등을 금합니다.
                  </span>

                  <span className={""}>
                    7. 회원은 외설적인, 중상 모략적인, 음성적인, 타인을 비방하는
                    용도 또는 기타 어떠한 비합법적인 용도로 사이트에서 제공한
                    콘텐츠를 사용하거나 다른 이가 사용하게 할 수 없습니다.
                  </span>
                  <span className={""}>
                    8. 회원 기간이 만료된 이후에는 회원 기간 동안 다운로드한
                    콘텐츠를 삭제해야 합니다. 그리고 회원 기간 동안 다운로드한
                    콘텐츠를 이용하여 새로운 저작물을 만들어서는 안 됩니다.
                  </span>
                  <span className={""}>
                    9. 다운로드한 콘텐츠 및 그의 2차 저작물에 대하여
                    지적재산권의 등록을 금합니다. 2차 저작물을 만들기 위해서는
                    별도의 협의가 필요합니다.
                  </span>
                  <span className={""}>
                    10. 저작물을 납품 시 납품된 저작물의 원본 소스는 납품할 수
                    없습니다. 클라이언트가 원본 소스를 이용하여 2차 저작물을
                    제작할 경우 그로 인한 모든 책임은 납품한 회원에게 있습니다.
                  </span>
                </div>

                <div className="flex flex-col items-center justify-center mt-10">
                  <span className="text-2xl font-bold mb-8">
                    {data?.buyDate.replace(/-/g, ".")}
                  </span>
                  <span className="text-lg font-semibold">
                    본 콘텐츠{" "}
                    {state?.type === "TICKET"
                      ? "1년 사용권을"
                      : "소유권 거래를"}{" "}
                    이용약관에 따라 인증합니다.
                  </span>

                  <div className="mt-4">
                    <span className="font-bold relative">
                      주식회사 FOF
                      <img
                        src="/img/standbuy/icon/fof-stamp.png"
                        className={"w-[40px] absolute -top-4 -right-4"}
                        alt="fof-stamp"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-10 right-10">
        <div className="flex flex-col justify-center items-center gap-4">
          <FeedNav />

          <IconButton
            //@ts-ignore
            className="rounded-full px-[28px] py-[28px]"
            placeholder={undefined}
            children={undefined}
            size="lg"
            onClick={moveToTop}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </IconButton>
        </div>
      </div>

      <div
        className={`fixed top-1/3 ${isMobile ? "right-[7%]" : "right-[40%]"} px-auto`}
      >
        <Card width={"min-w-full"}>
          <div className="flex flex-col items-center justify-center">
            <Text className="subtitle-2 ">
              인증서를 확인하려면 다운로드 해야 합니다.
            </Text>
            <Text className="upload-text  mt-4" color={UploadTextColor}>
              인증서를 다운로드 하시겠어요?
            </Text>
          </div>

          <div className="flex flex-col justify-center items-center">
            <FillButton
              onClick={handleSavePDF}
              text={"PDF 다운로드"}
              size={"lg"}
              className={"w-full mt-[30px]"}
            />

            <div className="flex flex-col items-center justify-center">
              <Text
                className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
                onClick={() => navigate(-1)}
              >
                뒤로가기
              </Text>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
