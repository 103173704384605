// import IconButton from "@mui/material/IconButton";
import { IconButton } from "@material-tailwind/react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useState } from "react";
import Text from "../design-system/Text";
import FeedReportModal from "../feed/modal/FeedReportModal";
import StudioReportModal from "../../pages/studio/modal/StudioReportModal";
import { useAppDispatch } from "../../store/store";
import { useContext } from "../../util/useContext";
import {
  modalFetched,
  niceModalFetched,
} from "../../store/reducer/globalModalSlice";

export default function StudioReportBtn({ detail }: any) {
  const { me, isLogin } = useContext();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [studioReport, setStudioReport] = useState({ show: false });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isLogin && me?.mobileVerification) {
      setAnchorEl(event.currentTarget);
    } else if (isLogin && !me?.mobileVerification) {
      dispatch(niceModalFetched({ show: true }));
    } else if (isLogin === false) {
      dispatch(modalFetched({ show: true }));
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const declarationList = [
    {
      label: "신고하기",
      onClick: () => {
        setStudioReport({ show: true });
      },
    },
  ];

  return (
    <>
      <div>
        <IconButton
          //@ts-ignore
          onClick={handleClick}
          color="white"
          children={undefined}
          placeholder={undefined}
          size="lg"
          className="border !border-[#E1E1E1]"
        >
          <img src="/img/standbuy/icon/info-dot.svg" alt="info-dot" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {declarationList.map((option: any, index: any) => (
            <MenuItem
              key={option.label}
              onClick={(e: any) => {
                // 옵션의 onClick 핸들러를 호출합니다.
                option.onClick();
                handleClose();
              }}
            >
              <Text
                fontSize={14}
                fontWeight={500}
                color="#2f2f2f"
                removeDarkMode={true}
              >
                {option.label}
              </Text>
            </MenuItem>
          ))}
        </Menu>
      </div>

      {studioReport.show && (
        <StudioReportModal
          open={studioReport.show}
          onClose={() => setStudioReport({ show: false })}
          detail={detail}
        />
      )}
    </>
  );
}
