import React from "react";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-tailwind/react";
import { useAxios } from "../../../lib/api/useAxios";
import { useContext } from "../../../util/useContext";
import { API_DEFAULT } from "../../../api/api";
import StudioFeedCard from "../card/StudioFeedCard";
import { FeedNav } from "../../../components/design-system/FeedNav";
import AvatarMobileCard from "./card/AvatarMobileCard";
import ProfileMobileCard from "./card/ProfileMobileCard";
import { useMediaQuery } from "react-responsive";

export default function StudioMobilePage() {
  const { get } = useAxios();
  const { state, me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const { data } = useQuery({
    queryKey: ["profile", me],
    queryFn: async () =>
      await get(`${API_DEFAULT}/user/studio/${me?.memberId}`),
  });

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[80px] w-full max-w-[1280px]">
          <div className="flex flex-col">
            <AvatarMobileCard data={data?.data} />
            <ProfileMobileCard data={data?.data} />
          </div>

          <StudioFeedCard />
        </div>

        <div
          className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
        >
          <div className="flex flex-col justify-center items-center gap-4">
            <FeedNav />

            {!isMobile && (
              <IconButton
                //@ts-ignore
                className="rounded-full px-[28px] py-[28px]"
                placeholder={undefined}
                children={undefined}
                size="lg"
                onClick={moveToTop}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </IconButton>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
