import React from "react";
import Text from "../../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../../util/useContext";
import { useMediaQuery } from "react-responsive";

export default function SectionEnd() {
  const navigate = useNavigate();
  const { isLogin, me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <div className="h-fit w-full flex flex-col bg-[#000] pb-40 relative">
      {!isMobile && (
        <div className="flex flex-col justify-center items-center gap-3 mt-[280px]">
          <Text
            fontSize={50}
            fontWeight={600}
            color="#fff"
            className="leading-[1]"
          >
            콘텐츠만 한국?
          </Text>
          <Text
            fontSize={50}
            fontWeight={600}
            color="#fff"
            className="leading-[1]"
          >
            아니, 인터페이스도 한국!
          </Text>

          <div className="flex flex-col items-center mt-2">
            <Text
              fontSize={20}
              fontWeight={600}
              color="#fff"
              className="leading-[1]"
            >
              한국인 안성맞춤, 편의를 생각한 인터페이스로
            </Text>
            <Text fontSize={20} fontWeight={600} color="#fff">
              유저 친화적으로 지향하고 있어요:)
            </Text>
          </div>
        </div>
      )}

      <div
        className={`flex flex-col justify-center items-center gap-4 mt-[90px] ${isMobile && "mx-3"}`}
      >
        <div
          className={`w-full flex ${isMobile && "flex-col"}  justify-center  gap-4 `}
        >
          <div
            className={`bg-white py-8 px-12 rounded-2xl ${!isMobile && "min-w-[550px]"}  ${isMobile ? "w-full" : "min-w-[550px]  min-h-[400px]"}   flex flex-col items-center`}
          >
            <Text
              fontSize={isMobile ? 20 : 30}
              fontWeight={600}
              color="#666666"
            >
              마냥 비싼 스톡은 그만
            </Text>

            <div className="w-full flex flex-col items-center justify-center mt-4">
              <Text fontSize={isMobile ? 20 : 30} fontWeight={600} color="#000">
                판매자가 자유롭게
              </Text>
              <Text fontSize={isMobile ? 20 : 30} fontWeight={600} color="#000">
                판매금액을 설정할 수 있는
              </Text>
              <Text fontSize={isMobile ? 20 : 30} fontWeight={600} color="#000">
                콘텐츠 자유거래 시장.
              </Text>

              <div className="mt-14">
                <img src="/img/standbuy/landing/bar.svg" alt="bar" />
              </div>
            </div>
          </div>

          <div
            className={`bg-white py-8 px-12 rounded-2xl flex flex-col items-center ${!isMobile && "min-w-[550px]"}  ${isMobile ? "w-full" : "min-w-[550px]"}`}
          >
            <Text
              fontSize={isMobile ? 20 : 30}
              fontWeight={600}
              color="#666666"
            >
              SNS 방식 거래
            </Text>

            <div className="w-full flex flex-col items-center justify-center mt-4 relative">
              <Text fontSize={isMobile ? 20 : 30} fontWeight={600} color="#000">
                SNS 인터페이스로
              </Text>
              <Text fontSize={isMobile ? 20 : 30} fontWeight={600} color="#000">
                게시글+판매기능을 한번에.
              </Text>

              <div
                className={`${isMobile ? "" : "mt-8  absolute bottom-[-226px]"}`}
              >
                <img src="/img/standbuy/landing/sns.svg" alt="sns" />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`bg-white py-8 px-12 rounded-2xl ${isMobile ? "w-full" : "w-[1116px]"} flex flex-col items-center`}
        >
          <div className="w-full flex flex-col items-center justify-center mt-4 relative">
            <div
              className={`flex  ${isMobile && "flex-col"} items-center ${isMobile ? "gap-1" : "gap-40"}`}
            >
              <img
                src="/img/standbuy/landing/map.svg"
                alt="map"
                className="mb-4 rounded-2xl"
              />
              <div className="w-full flex flex-col items-center justify-center mb-8">
                <Text
                  fontSize={20}
                  fontWeight={600}
                  color="#666666"
                  className={`${isMobile && "mb-4"}`}
                >
                  지도기반 콘텐츠 검색
                </Text>
                <Text
                  fontSize={isMobile ? 20 : 30}
                  fontWeight={600}
                  color="#000"
                >
                  지도 기반으로 찾는다면
                </Text>
                <Text
                  fontSize={isMobile ? 20 : 30}
                  fontWeight={600}
                  color="#000"
                >
                  내가 원하는 콘텐츠를 좀 더
                </Text>
                <Text
                  fontSize={isMobile ? 20 : 30}
                  fontWeight={600}
                  color="#000"
                >
                  효율적으로 찾을 수 있어요.
                </Text>
              </div>
            </div>
          </div>
        </div>

        {isLogin && me?.agreementSelectiveTerms && (
          <div
            className={`${isMobile ? "mt-10" : "mt-[70px]"} rounded-full bg-[#134B70] py-3 px-7 cursor-pointer ${isMobile ? "w-full" : "w-[440px]"} flex justify-center items-center`}
            onClick={() => navigate(isLogin ? "/feed" : "/login")}
          >
            <img
              src="/img/standbuy/landing/green-standbuy.svg"
              alt="green-standbuy"
              width={140}
            />
            <Text
              fontSize={24}
              fontWeight={600}
              color="#fff"
              className="mb-1 ml-2"
            >
              에서 팔아보기
            </Text>
          </div>
        )}

        {isLogin && me?.agreementSelectiveTerms === false && (
          <div
            className="mt-[70px] rounded-full bg-[#134B70] py-3 px-7 cursor-pointer w-[440px] flex justify-center items-center"
            onClick={() => navigate("/social-check")}
          >
            <img
              src="/img/standbuy/landing/green-standbuy.svg"
              alt="green-standbuy"
              width={140}
            />
            <Text
              fontSize={24}
              fontWeight={600}
              color="#fff"
              className="mb-1 ml-2"
            >
              에서 팔아보기
            </Text>
          </div>
        )}

        {isLogin === false && (
          <div
            className={`mt-[70px] rounded-full bg-[#134B70] py-3 px-7 cursor-pointer ${isMobile ? "w-full" : "w-[440px]"}  flex justify-center items-center`}
            onClick={() => navigate("/login")}
          >
            <img
              src="/img/standbuy/landing/green-standbuy.svg"
              alt="green-standbuy"
              width={140}
            />
            <Text
              fontSize={24}
              fontWeight={600}
              color="#fff"
              className="mb-1 ml-2"
            >
              에서 팔아보기
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
