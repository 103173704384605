import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";

export default function NoSubsData() {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col gap-10 justify-center items-center">
        <Text className="no-data-title  mt-[90px]">
          구독하고 있는 아티스트가 없습니다.
        </Text>

        <img
          src={"/img/standbuy/icon/thumbnail-upload.svg"}
          alt="thumbnail-upload"
        />

        <div className="flex flex-col items-center">
          <Text className="upload-text" color={UploadTextColor}>
            다양한 아티스트를 구독하고
          </Text>
          <Text className="upload-text" color={UploadTextColor}>
            콘텐츠를 구경해보세요.
          </Text>
        </div>
      </div>
    </div>
  );
}
