import { instance } from "../index";
import { API_DEFAULT } from "../api";

export const getInfoPartOne = async () => {
  const url = `${API_DEFAULT}/user/info/part-1`;
  const response = await instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

export const postDirectorEnd = async (payload: any) => {
  const url = `${API_DEFAULT}/user/director/register/request`;
  const response = await instance
    .post(url, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};
