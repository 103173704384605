import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../../store/store";
import { motion } from "framer-motion";
import FillButton from "../../../components/design-system/button/FillButton";
import MobileFooter from "../../../components/layout/footer/MobileFooter";
import CustomFooter from "../../../components/layout/footer/CustomFooter";
import Text from "../../../components/design-system/Text";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "../../../util/useContext";

export default function DirectorSixPage({ data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { me } = useContext();
  const themeData = useAppSelector((state) => state.theme.data);
  const { number } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col justify-between items-center h-full bg-[#121212]">
        <div
          className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
        >
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title !text-[#ececec] ">디렉터 신청</Text>

            <div className="flex flex-col items-end">
              <Text fontWeight={700} className={"!text-[#ececec] "}>
                0{number}/09
              </Text>
              <Text className={"!text-[#ececec] "}>아직 조금 남았어요..!</Text>
            </div>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <motion.div
            className={`w-full ${isMobile ? "px-10" : "flex flex-col"} mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="flex flex-col items-center gap-10 mt-[100px]">
              <div className="flex flex-col items-center">
                <Text
                  fontWeight={700}
                  fontSize={20}
                  className={"!text-[#ececec] "}
                >
                  예비 디렉터님의 휴대폰 번호 입니다.
                </Text>
              </div>

              <div className="flex justify-center items-center w-[230px] border px-2 py-2 rounded-lg !bg-[#ffffff]">
                {data?.phone
                  .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                  .replace(/\-{1,2}$/g, "")}
              </div>
            </div>

            <div className="flex justify-center mt-10">
              <FillButton
                onClick={() => navigate(`/director/7`)}
                text={"STANDBUY"}
                className="w-[360px] py-4"
                color={"white"}
              />
            </div>

            <div className="flex gap-4 items-center justify-center">
              <Text
                className="underline underline-offset-4 cursor-pointer my-6  !text-[#ececec] "
                onClick={() => navigate(-1)}
                fontSize={14}
                fontWeight={500}
                color="#363636"
              >
                이전으로
              </Text>
            </div>
          </motion.div>
        </div>
      </div>
      {/* {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />} */}
    </>
  );
}
