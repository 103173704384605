import React, { useState } from "react";
import Modal from "../../design-system/modal/Modal";
import Text from "../../design-system/Text";
import CustomCheckBox from "../../design-system/CustomCheckBox";
import FillButton from "../../design-system/button/FillButton";
import { Textarea } from "@material-tailwind/react";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { useMutation } from "@tanstack/react-query";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { getCookie } from "../../../util/cookies";
import CustomTooltip from "../../design-system/CustomTooltip";
import { instance } from "../../../api";
import { useContext } from "../../../util/useContext";

const info = [
  { title: "성적인 콘텐츠", value: false, type: "SEXUAL" },
  {
    title: "폭력적 또는 가학적인 콘텐츠",
    value: false,
    type: "VIOLENT_OR_ABUSIVE",
  },
  {
    title: "혐오 또는 악의적인 콘텐츠",
    value: false,
    type: "HATEFUL_OR_MALICIOUS",
  },
  { title: "아동학대", value: false, type: "CHILD_ABUSE" },
  { title: "괴롭힘 콘텐츠", value: false, type: "HARASSMENT_OR_BULLYING" },
  {
    title: "위험하거나 유해한 콘텐츠",
    value: false,
    type: "DANGEROUS_ACTS_OR_HARMFUL",
  },
  { title: "불법 광고 콘텐츠", value: false, type: "SPAM_OR_MISLEADING" },
  {
    title: "법적 문제 (초상권, 저작권 등)",
    value: false,
    type: "LEGAL_ISSUE",
  },
  { title: "기타", value: false, type: "OTHER" },
];

export default function FeedReportModal({ open, onClose, detail }: any) {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState({ step: 1 });
  const [reportKeywords, setReportKeywords] = useState<string[]>([]);
  const [desc, setDesc] = useState("");
  const { me } = useContext();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.name;
    if (event.target.checked) {
      setReportKeywords([value]);
    } else {
      setReportKeywords([]);
    }
  };

  const onSubmit = async () => {
    const _reporterId = me?.memberId;
    const _contentId = detail?.contentId;
    const _category = reportKeywords.toString();
    const _desc = desc;
    const res = await instance.post(`${API_DEFAULT}/user/report/content`, {
      reporterId: _reporterId,
      contentId: _contentId,
      category: _category,
      description: _desc,
    });
  };

  const { mutate, status } = useMutation({
    mutationFn: onSubmit,
    onSuccess: () => {
      onClose();
      dispatch(
        toastFetched({
          show: true,
          text: "콘텐츠가 신고되었습니다.",
          type: "success",
        })
      );
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone width="440px">
      <div className="flex items-center gap-2">
        <Text fontSize={18} fontWeight={700} color="#242424">
          신고하기
        </Text>
        <CustomTooltip title="콘텐츠가 신고되면 담당자가 업무시간 내로 검토하여 위반 여부를 판단합니다. 허위신고의 경우 내부 정책에 의거해, 불이익이 발생할 수 있습니다." />
      </div>

      {step.step === 1 && (
        <div className="flex flex-col justify-center gap-6 mt-[32px]">
          {info?.map((item, index) => (
            <CustomCheckBox
              key={index}
              name={item?.type}
              checked={reportKeywords.includes(item?.type)}
              onChange={handleCheckboxChange}
              label={item?.title}
              labelStyle="report-checkbox"
            />
          ))}
        </div>
      )}

      {step.step === 2 && (
        <div className="flex flex-col justify-center gap-6 mt-[32px]">
          <Textarea
            value={desc}
            onChange={(e: any) => setDesc(e.target.value)}
            placeholder="상세 내용을 입력해주세요."
            labelProps={{ className: "before:content-none after:content-none" }}
            variant="outlined"
            className="min-h-[260px] !border-[#F1F1F1] focus:border-[1px]  focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10  shadow-gray-900/5 ring-4 ring-transparent"
          />
        </div>
      )}

      <div className="flex flex-col items-center">
        {step.step === 1 ? (
          <FillButton
            text="다음"
            className="w-full mt-[30px]"
            size="lg"
            onClick={() => setStep({ step: 2 })}
            disabled={reportKeywords.length < 1 ? true : false}
          />
        ) : (
          <FillButton
            text="신고하기"
            className="w-full mt-[30px]"
            size="lg"
            onClick={mutate}
            disabled={desc.length < 1 ? true : false}
          />
        )}

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
