import Box from "@mui/material/Box";
import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Text from "../../design-system/Text";

export default function FilterOrderBtn({ order, handleChange }: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const isMobile = useMediaQuery({ maxWidth: 820 });

  return (
    <Box sx={{ maxWidth: 140, minWidth: isMobile ? 50 : 80 }}>
      <FormControl fullWidth>
        <Select
          value={order}
          onChange={handleChange}
          displayEmpty
          sx={{
            backgroundColor: themeData === "dark" ? "#424242" : "#fff",
            color: themeData === "dark" ? "#fff" : "#000",
            height: isMobile ? "32px" : "48px",
            fontSize: isMobile ? "10px" : "16px",
            fontFamily: "Pretendard",
            ".MuiOutlinedInput-input": {
              padding: isMobile ? "8px 14px" : "7.5px 14px",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSvgIcon-root": {
              color: themeData === "dark" ? "#fff" : "#000",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                // 메뉴 전체의 스타일
                backgroundColor: themeData === "dark" ? "#424242" : "#fff",
              },
            },
            MenuListProps: {
              sx: {
                fontFamily: "Pretendard",
                "& .MuiMenuItem-root": {
                  fontSize: isMobile ? "14px" : "",
                  padding: isMobile ? "8px" : "10px",
                },
              },
            },
          }}
        >
          <MenuItem value={"DEFAULT"}>
            <Text fontSize={14}>기본순</Text>
          </MenuItem>
          <MenuItem value={"DESC"}>
            <Text fontSize={14}>최신순</Text>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
