import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export default function CartEndModal({ open, onClose, data }: any) {
  const queryClient = useQueryClient();
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  async function itemCheckout() {
    const payload = {
      cartIds: data,
    };
    const res = await post(`${API_DEFAULT}/user/cart/checkout`, payload);

    if (res?.success) {
      dispatch(
        toastFetched({
          show: true,
          text: "구매 완료",
          type: "success",
        })
      );
      navigate("/locker");
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error.message,
          type: "error",
        })
      );
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: itemCheckout,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 ">상품을 구매하시겠어요?</Text>
        <Text className="upload-text  mt-4" color={UploadTextColor}>
          상품을 구매하면 보관함으로 이동합니다.
        </Text>

        <FillButton
          text="구매하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={mutate}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
