import { Input } from "@material-tailwind/react";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useRef, useState } from "react";

const Wrap = styled.div<any>`
  width: ${(props) => props.width};
`;

export function InputEyes({
  type = "text",
  value,
  setValue,
  width,
  placeholder,
  label,
  size = "md",
  error = false,
  success = false,
  disable = false,
  onKeyUp,
  minLength,
  maxLength,
}: Props) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const focusRef = useRef<any>();
  const [inputType, setInputType] = useState<string>(type);

  const handleInput = (e: any) => {
    setValue(e);
    return focusRef.current.focus();
  };

  return (
    <Wrap width={width}>
      <Input
        className="dark:text-darkText"
        type={inputType}
        // variant="static"
        label={label}
        placeholder={placeholder}
        crossOrigin={undefined}
        color={themeData === "dark" ? "white" : undefined}
        size={size}
        error={error}
        success={success}
        disabled={disable}
        ref={focusRef}
        value={value}
        onChange={(e: any) => handleInput(e.target.value)}
        onKeyUp={onKeyUp}
        minLength={minLength}
        maxLength={maxLength}
        icon={
          value ? (
            focusRef.current !== undefined &&
            value &&
            inputType === "password" ? (
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => {
                  setInputType("text");
                }}
                className=""
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                onClick={() => {
                  setInputType("password");
                }}
                className=""
              />
            )
          ) : undefined
        }
      />
    </Wrap>
  );
}

interface Props {
  value: any;
  setValue: any;
  type?: string;
  width?: string;
  placeholder: string;
  size?: "md" | "lg";
  error?: boolean;
  success?: boolean;
  disable?: boolean;
  onKeyUp?: any;
  label?: string;
  minLength?: number;
  maxLength?: number;
}
