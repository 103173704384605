import React, { useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { Radio } from "@material-tailwind/react";
import { handlePayCancel, payDetailList } from "../../../api/pay/pay";

export default function PayCancelModal({
  open,
  onClose,
  data,
  payOrderId,
  flag,
}: any) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<any>({
    changed: false,
    mistake: false,
  });

  const { data: payDetail } = useQuery({
    queryKey: ["payInfo", data?.orderId],
    queryFn: () => payDetailList(data?.orderId),
    enabled: flag === "all",
  });

  const handleChange = (type: any) => {
    setValue({
      changed: type === "changed",
      mistake: type === "mistake",
    });
  };

  const { mutate } = useMutation({
    mutationFn: handlePayCancel,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });
      if (data?.success) {
        dispatch(
          toastFetched({
            show: true,
            text: "결제 취소 완료",
            type: "success",
          })
        );
        window.location.reload();
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          })
        );
      }
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 ">정말 결제를 취소하시겠어요?</Text>

        <div className="mt-4 flex gap-10">
          <Radio
            value={value.changed}
            onChange={() => handleChange("changed")}
            name="type"
            label={
              <Text fontSize={16} fontWeight={500} color="#555" className="">
                생각이 바꼈어요!
              </Text>
            }
            crossOrigin={undefined}
            ripple={true}
            className="hover:before:opacity-0 checked:border-[#134B70] checked:before:bg-[#134B70] text-[#134B70]"
          />
          <Radio
            value={value.mistake}
            onChange={() => handleChange("mistake")}
            name="type"
            label={
              <Text fontSize={16} fontWeight={500} color="#555" className="">
                결제를 잘못했어요!
              </Text>
            }
            ripple={true}
            crossOrigin={undefined}
            className="hover:before:opacity-0 checked:border-[#134B70] checked:before:bg-[#134B70] text-[#134B70]"
          />
        </div>

        <FillButton
          text="취소하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => {
            const cancelReason = value.chaged ? "단순변심" : "결제실수";
            const tradeIdsALL =
              flag === "all"
                ? payDetail?.data.map((detail: any) => detail.tradeId)
                : "";

            mutate({
              orderId: flag === "all" ? data?.orderId : payOrderId,
              tradeIds: flag === "all" ? tradeIdsALL : [data?.tradeId],
              cancelReason: cancelReason,
            });
          }}
          disabled={
            value.changed === false && value.mistake === false ? true : false
          }
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
