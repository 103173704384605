import React, { useEffect, useState } from "react";
import Text from "../../../components/design-system/Text";
import { Progress } from "@material-tailwind/react";

export default function WeekSales({ data }: any) {
  const [lastWeekRevenue, setLastWeekRevenue] = useState<any>();
  // const [thisWeekRevenue, setThisWeekRevenue] = useState<any>();

  useEffect(() => {
    if (data?.weeklySalesComparison?.lastWeek.revenue === null) {
      setLastWeekRevenue(0);
    } else {
      setLastWeekRevenue(data?.weeklySalesComparison?.lastWeek.revenue);
    }
  }, [data]);

  const thisWeekRevenue = data?.weeklySalesComparison?.thisWeek.revenue;
  const maxRevenue = Math.max(thisWeekRevenue, lastWeekRevenue);
  const thisWeekRevenuePercentage = (thisWeekRevenue / maxRevenue) * 100;
  const lastWeekRevenuePercentage = (lastWeekRevenue / maxRevenue) * 100;
  const revenueDifference =
    thisWeekRevenuePercentage - lastWeekRevenuePercentage;

  return (
    <>
      <div className="mt-[100px]">
        <Text fontSize={16} fontWeight={600} color="#303030" className="">
          지난주 대비 이번주 수익
        </Text>
        <Text fontSize={14} fontWeight={400} className="mt-[6px] ">
          이번주가 지난 주 수입 대비 총{" "}
          <Text span color="#42bc7f" fontSize={14} fontWeight={700}>
            {isNaN(revenueDifference) ? 0 : Math.trunc(revenueDifference)}%{" "}
          </Text>
          증가했어요!
        </Text>

        <div className="w-full mt-5 px-[30px] py-5 rounded-lg border flex items-center justify-between dark:border-darkBanner">
          <div className="w-full flex items-center gap-[40px]">
            <div>
              <Text fontSize={16} fontWeight={700} color="#303030" className="">
                콘텐츠 판매량
              </Text>

              <div className="mt-[28px] flex flex-col gap-1">
                <div className="flex items-center gap-2">
                  <div className="w-[5px] h-[5px] rounded-full bg-[#eaeaea]"></div>
                  <Text
                    fontSize={13}
                    fontWeight={600}
                    color="#474747"
                    className=""
                  >
                    저번주
                  </Text>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-[5px] h-[5px] rounded-full bg-[#42BC7F]"></div>
                  <Text
                    fontSize={13}
                    fontWeight={600}
                    color="#474747"
                    className=""
                  >
                    이번주
                  </Text>
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <div className="h-[100px] w-[1px] bg-[#F1F1F1]" />
              <div className="flex flex-col gap-4">
                <Progress
                  value={
                    (data?.weeklySalesComparison?.lastWeek.salesVolume /
                      Math.max(
                        data?.weeklySalesComparison?.thisWeek.salesVolume,
                        data?.weeklySalesComparison?.lastWeek.salesVolume
                      )) *
                    100
                  }
                  label={data?.weeklySalesComparison?.lastWeek.salesVolume}
                  placeholder={undefined}
                  className="flex w-[450px] rounded-none h-[24px] bg-inherit"
                  barProps={{
                    className: "rounded-none bg-[#eaeaea]",
                  }}
                />
                <Progress
                  value={Math.trunc(
                    (data?.weeklySalesComparison?.thisWeek.salesVolume /
                      Math.max(
                        data?.weeklySalesComparison?.thisWeek.salesVolume,
                        data?.weeklySalesComparison?.lastWeek.salesVolume
                      )) *
                      100
                  )}
                  label={data?.weeklySalesComparison?.thisWeek.salesVolume}
                  placeholder={undefined}
                  className="flex w-[450px] rounded-none h-[24px] bg-inherit"
                  barProps={{
                    className: "rounded-none bg-[#42BC7F]",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="w-full mt-5 px-[30px] py-5 rounded-lg border flex items-center justify-between dark:border-darkBanner">
          <div className="w-full flex items-center gap-[65px]">
            <div>
              <Text fontSize={16} fontWeight={700} color="#303030" className="">
                수익금
              </Text>

              <div className="mt-[28px] flex flex-col gap-1">
                <div className="flex items-center gap-2">
                  <div className="w-[5px] h-[5px] rounded-full bg-[#eaeaea]"></div>
                  <Text
                    fontSize={13}
                    fontWeight={600}
                    color="#474747"
                    className=""
                  >
                    저번주
                  </Text>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-[5px] h-[5px] rounded-full bg-[#42BC7F]"></div>
                  <Text
                    fontSize={13}
                    fontWeight={600}
                    color="#474747"
                    className=""
                  >
                    이번주
                  </Text>
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <div className="h-[100px] w-[1px] bg-[#F1F1F1]" />
              <div className="flex flex-col gap-4">
                <Progress
                  value={Math.trunc(
                    (data?.weeklySalesComparison?.lastWeek.revenue /
                      Math.max(
                        data?.weeklySalesComparison?.thisWeek.revenue,
                        data?.weeklySalesComparison?.lastWeek.revenue
                      )) *
                      100
                  )}
                  label={lastWeekRevenue}
                  placeholder={undefined}
                  className="flex w-[450px] rounded-none h-[24px] bg-inherit"
                  barProps={{
                    className: "rounded-none bg-[#eaeaea]",
                  }}
                />
                <Progress
                  value={
                    (data?.weeklySalesComparison?.thisWeek.revenue /
                      Math.max(
                        data?.weeklySalesComparison?.thisWeek.revenue,
                        lastWeekRevenue
                      )) *
                    100
                  }
                  label={data?.weeklySalesComparison?.thisWeek.revenue}
                  placeholder={undefined}
                  className="flex w-[450px] rounded-none h-[24px] bg-inherit"
                  barProps={{
                    className: "rounded-none bg-[#42BC7F]",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
