import { useNavigate, useParams } from "react-router-dom";
import DirectorTwoPage from "./DirectorTwoPage";
import DirectorOnePage from "./DirectorOnePage";
import DirectorThreePage from "./DirectorThreePage";
import DirectorPage from "../DirectorPage";
import DirectorFourPage from "./DirectorFourPage";
import DirectorFivePage from "./DirectorFivePage";
import DirectorSixPage from "./DirectorSixPage";
import { useQuery } from "@tanstack/react-query";
import { getInfoPartOne } from "../../../api/director/director";
import DirectorSevenPage from "./DirectorSevenPage";
import DirectorEightPage from "./DirectorEightPage";
import DirectorNinePage from "./DirectorNinePage";
import DirectorEndPage from "../DirectorEndPage";
import { useEffect } from "react";
import { useContext } from "../../../util/useContext";

export default function DirectorNumberPage() {
  const { number } = useParams();
  const { me } = useContext();
  const navigate = useNavigate();

  const { data, status } = useQuery({
    queryKey: ["director-info"],
    queryFn: () => getInfoPartOne(),
  });

  useEffect(() => {
    if (
      me?.memberId === 1 ||
      me?.memberId === 2 ||
      me?.memberId === 3 ||
      me?.memberId === 6
    ) {
      return;
    } else {
      navigate("/feed");
    }
  }, [me]);

  switch (number) {
    case "1":
      return <DirectorOnePage />;
    case "2":
      return <DirectorTwoPage />;
    case "3":
      return <DirectorThreePage />;
    case "4":
      return <DirectorFourPage />;
    case "5":
      return <DirectorFivePage data={data?.data} />;
    case "6":
      return <DirectorSixPage data={data?.data} />;
    case "7":
      return <DirectorSevenPage />;
    case "8":
      return <DirectorEightPage />;
    case "9":
      return <DirectorNinePage />;
    case "10":
      return <DirectorEndPage />;
    default:
      return <DirectorPage />;
  }
}
