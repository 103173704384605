import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Switcher from "../../components/common/Switcher";
import Card from "../../components/design-system/Card";
import Text from "../../components/design-system/Text";
import FillButton from "../../components/design-system/button/FillButton";
import { useContext } from "../../util/useContext";
import { MetaDatas } from "../../meta/MetaDatas";

export default function EmailLoginDenyPage() {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useContext();

  return (
    <>
      <MetaDatas
        siteName={""}
        title={"로그인 제한"}
        siteUrl={location.pathname}
      />
      <div className="bg-gray-200 dark:bg-black h-screen flex justify-center items-center">
        <Switcher className="hidden" />
        <div className="flex flex-col gap-12 w-full items-center">
          <img
            src={
              themeData === "light" || themeData === ""
                ? "/img/standbuy/logo/logo.svg"
                : "/img/standbuy/logo/logo-white.svg"
            }
            alt="standbuy"
            onClick={() => navigate("/")}
            className="cursor-pointer"
            width={200}
          />
          <Card>
            <div className="flex flex-col overflow-auto">
              <Text className="subtitle-1 ">로그인 제한</Text>
              <Text className="subtitle-2  mt-4 ">
                해당 계정은 로그인이 제한되었습니다.
              </Text>

              <div className="mt-4">
                <Text
                  fontSize={16}
                  fontWeight={500}
                  color="#535353"
                  className=""
                >
                  - 이메일 : {state?.email}
                </Text>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  color="#535353"
                  className=""
                >
                  - 상태 :{" "}
                  {state?.status === "SUSPENSION"
                    ? "제재"
                    : state?.status === "DORMANT"
                      ? "휴면"
                      : "회원 탈퇴"}
                </Text>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  color="#535353"
                  className=""
                >
                  - 이유 : {state?.reason}
                </Text>
              </div>

              {state?.status === "WITHDRAWAL" && (
                <Text className="mt-4 " fontSize={14}>
                  탈퇴한 계정은 1주일간 정지 처리되며, 이후 완전히 삭제됩니다.
                </Text>
              )}

              <FillButton
                onClick={() => navigate("/")}
                text="홈으로"
                className="mt-10"
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
