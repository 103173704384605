import { useEffect, useState, useCallback } from "react";

export function useScrollPosition() {
  const [scrollPos, setScrollPos] = useState(0);

  // 스크롤 이벤트를 통해 현재 스크롤 위치 추적
  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /**
   * 스크롤 위치가 0이 아닐 때 호출하면,
   * 페이지 최상단으로 부드럽게 이동시키는 함수
   */
  const scrollToTopSmoothly = useCallback(() => {
    if (scrollPos !== 0) {
      window.scrollTo(0, 0);
    }
  }, [scrollPos]);

  return { scrollPos, scrollToTopSmoothly };
}
