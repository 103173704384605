import { Checkbox } from "@material-tailwind/react";

import Text from "./Text";
import { useLocation } from "react-router-dom";

export default function CustomCheckBox({
  checked,
  onChange,
  label,
  labelStyle,
  name,
  index,
  ripple = true,
  disabled = false,
  onClick,
  flag = false,
}: Props) {
  const location = useLocation();
  return (
    <Checkbox
      disabled={disabled}
      ripple={ripple}
      key={index}
      name={name}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      color="green"
      crossOrigin={undefined}
      containerProps={{
        className: "p-0",
      }}
      className={`checked:bg-[#134B70] ${
        ripple ? "" : "hover:before:opacity-0 "
      } h-6 w-6 rounded-full checked:border-[#134B70] checked:before:bg-[#134B70]`}
      label={
        <Text
          className={`${labelStyle}  ml-2 ${
            location.pathname === "/feed" && flag === false ? "" : ""
          }`}
          // style={{ display: "ruby" }}
        >
          {label}
        </Text>
      }
    />
  );
}

interface Props {
  checked: any;
  onChange: any;
  label: string;
  labelStyle?: string;
  name?: string;
  index?: any;
  ripple?: boolean;
  disabled?: boolean;
  onClick?: any;
  flag?: boolean;
}
