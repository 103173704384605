import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import SideDrawer from "../../components/design-system/SideDrawer";
import Text from "../../components/design-system/Text";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import FofCreditList from "./card/FofCreditList";
import CustomTabs from "../../components/design-system/CustomTabs";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { useLocation } from "react-router-dom";
import { MetaDatas } from "../../meta/MetaDatas";

const setData = [
  {
    label: "판매내역",
    value: "SELL",
  },
  {
    label: "구매내역",
    value: "BUY",
  },
];

export default function FofCreditPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [value, setValue] = useState(setData[0].value);
  const location = useLocation();

  return (
    <>
      <MetaDatas
        title={"콘텐츠 거래내역"}
        siteName={""}
        siteUrl={location.pathname}
      />

      <div
        className={`flex flex-col justify-between h-full ${isMobile ? "pt-[40px]" : "pt-[80px]"}`}
      >
        <motion.div
          className={`w-full ${isMobile ? "px-10" : "flex justify-center"} mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div
            className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
          >
            {isMobile ? null : (
              <div className="animate-bounce gap-1">
                <Text fontSize={14} fontWeight={600} color="black" className="">
                  메뉴
                </Text>
                <img
                  src="/img/standbuy/icon/scroll-down.svg"
                  alt="scroll-down"
                />
              </div>
            )}
            <div className="flex items-center mb-[30px]">
              {isMobile ? null : <SideDrawer />}
              <Text className="title ">콘텐츠 거래내역</Text>
            </div>

            {/*<div className="h-[1px] bg-[#afafaf] mt-[10px]" />*/}

            <CustomTabs
              tabsData={setData}
              value={value}
              setValue={setValue}
              size={isMobile ? "20px" : "28px"}
            />

            {value === "BUY" && <FofCreditList value={value} />}
            {value === "SELL" && <FofCreditList value={value} />}
          </div>
        </motion.div>

        {isMobile ? <MobileFooter /> : <CustomFooter />}
      </div>
    </>
  );
}
