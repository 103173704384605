import React, { useEffect, useRef, useState } from "react";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { LabelInputView } from "../../../components/design-system/input/LabelInputView";
import BankAddModal from "../modal/BankAddModal";
import { useContext } from "../../../util/useContext";
import accountCode from "../../../util/accountCode";
import { niceModalFetched } from "../../../store/reducer/globalModalSlice";
import { useAppDispatch } from "../../../store/store";
import { useMediaQuery } from "react-responsive";
import { useQuery } from "@tanstack/react-query";
import { myDetail } from "../../../api/info/info";

export default function MyBankEdit() {
  const [addModal, setAddModal] = useState({ show: false });
  const { me, isLogin } = useContext();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 820 });

  const { data, status: getStatus } = useQuery({
    queryKey: ["profile-detail"],
    queryFn: myDetail,
  });

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2 mt-3">
          <Text fontSize={23} color="#303030" fontWeight={600} className="">
            정산 계좌 관리
          </Text>
        </div>

        <div className="flex flex-col gap-12 mt-3">
          <div
            className={`flex flex-col items-center justify-center ${isMobile ? "" : "w-[360px]"} `}
          >
            <FillButton
              onClick={() => {
                if (isLogin && me?.mobileVerification) {
                  setAddModal({ show: true });
                } else if (isLogin && !me?.mobileVerification) {
                  dispatch(niceModalFetched({ show: true }));
                }
              }}
              text="정산 계좌 등록하기"
              size="lg"
              className={`${isMobile ? "w-full" : "w-[360px]"} flex justify-center`}
            />
          </div>

          <div
            className={`flex flex-col gap-10 ${isMobile ? "w-full" : "w-[360px]"} `}
          >
            <LabelInputView
              readOnly={true}
              width="100%"
              value={accountCode(data?.data?.bankAccount?.bankCode)}
              setValue={undefined}
              placeholder={"은행명"}
              label="은행명"
            />

            <LabelInputView
              readOnly={true}
              width="100%"
              value={data?.data?.bankAccount?.accountNumber}
              setValue={undefined}
              placeholder={"계좌번호"}
              label="계좌번호"
            />
          </div>
        </div>
      </div>

      {addModal.show && (
        <BankAddModal
          open={addModal.show}
          onClose={() => setAddModal({ show: false })}
        />
      )}
    </>
  );
}
