import Banner from "../../components/common/Banner";
import Text from "../../components/design-system/Text";
import FilterBar from "../../components/feed/filter/FilterBar";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual, useSelector } from "react-redux";
import { API_DEFAULT, URL_HOST } from "../../api/api";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import { setStockList } from "../../store/reducer/mainStock";
import StockListCard from "./stock-list/StockListCard";
import { Avatar, IconButton, Tooltip } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FeedNav } from "../../components/design-system/FeedNav";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { instance } from "../../api";
import NoticeModal from "../../components/common/NoticeModal";
import MobileTopSection from "../../components/common/MobileTopSection";
import SlideBanner from "../../components/common/SlideBanner";
import FilterOrderBtn from "../../components/feed/filter/FilterOrderBtn";
import FilterContainer from "../../components/feed/filter/box/FilterContainer";
import FilterReload from "../../components/feed/filter/FilterReload";
import { setOrderFetched } from "../../store/reducer/OrderSlice";
import { useContext } from "../../util/useContext";
import StockText from "../../components/design-system/StockText";
import ReactHlsPlayer from "react-hls-player";
import { styled } from "styled-components";
import FeedInfoModal from "./modal/FeedInfoModal";
import { MetaDatas } from "../../meta/MetaDatas";
import TermsNoticeModal from "../../components/design-system/modal/TermsNoticeModal";
import DirectorBanner from "../../components/common/DirectorBanner";
import BoxAvatarCard from "../../components/common/BoxAvatarCard";

export default function NewFeedPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const toggle = useAppSelector((state) => state.theme.toggle);
  const order = useAppSelector((state) => state.order.order);

  const { stockList } = useAppSelector(
    (state) => state.mainStock,
    shallowEqual
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [itmes, setItems] = useState<any>([]);
  const { notice } = useSelector((state: any) => state.priceInfo);

  // const [order, setOrder] = useState<any>("DEFAULT");

  const handleChange = (event: any) => {
    dispatch(setOrderFetched(event.target.value));
    // setOrder(event.target.value as string);
  };

  // const [isPopupShow, setPopupShow] = useState(true);
  // const [infoModal, setInfoModal] = useState<any>({ show: true, data: "" });
  // const expireDay: any = localStorage.getItem("popupNoShow");
  //
  // const closePopup = (expireDays: any) => {
  //   let expire: any = new Date();
  //   expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
  //   localStorage.setItem("popupNoShow", expire.getTime());
  // };
  //
  // const checkPopupClose = () => {
  //   const expireDay: any = localStorage.getItem("popupNoShow");
  //   let today: any = new Date();
  //
  //   if (today.getTime() > expireDay) {
  //     // 이렇게 하면 localStorage에 아무것도 저장되어 있지 않을 경우 undefined 거나 null 이므로 true를 반환한다.
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  //
  // const closePopupToday = () => {
  //   closePopup(1); // 하루 동안 표시 안 할 것임을 명시
  //   setPopupShow(false); // 오늘 하루 안 보기 누름과 동시에 팝업 사라짐
  // };
  //
  // useEffect(() => {
  //   checkPopupClose() ? setPopupShow(true) : setPopupShow(false);
  //   // 최초 컴포넌트가 마운트되었을 때, 팝업을 표시할 지 말지 조회
  // }, []);
  //
  // useEffect(() => {
  //   setInfoModal({ show: true });
  //   if (expireDay) {
  //     setPopupShow(false);
  //   } else {
  //     setPopupShow(true);
  //   }
  // }, [expireDay]);
  //
  useEffect(() => {
    if (notice) {
      setInfoModal({ show: false });
    } else {
      setInfoModal({ show: true });
    }
  }, [notice]);

  useEffect(() => {
    dispatch(setStockList({ data: itmes, reset: false }));
  }, [itmes]);

  // 진입 페이지
  async function getItems(
    nextGroupKey: number,
    count: number,
    order: string,
    last: any
  ) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await instance.post(
      last
        ? `${API_DEFAULT}/contents/search/main?page=${nextGroupKey}&size=${count}&order=${order}&last=${last}`
        : `${API_DEFAULT}/contents/search/main?page=${nextGroupKey}&size=${count}&order=${order}`
    );

    if (res?.data?.success) {
      nextItems = res.data.data; // 응답 구조에 따라 수정 필요
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setGroupKey(1);
    setItems([]);

    (async () => {
      const initialItems = await getItems(1, 15, order, null);
      if (initialItems?.length > 0) {
        dispatch(setStockList({ data: initialItems, reset: true }));
        setItems(initialItems);
      }
    })();
  }, [order]);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const playerRef = useRef() as any;
  const { me } = useContext();

  // 모달(예시)
  const [hoverItemKey, setHoverItemKey] = useState<number | null>(null);

  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const [endModal, setEndModal] = useState<any>({ show: false, data: "" });
  const { versionData } = useAppSelector((state) => state.mobile);

  const popupWidth = 1400;
  const popupHeight = 1000;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  useEffect(() => {
    window.navigateToPage = (url: string, options?: any) => {
      navigate(url, options);
    };
  }, [navigate]);

  return (
    <>
      <MetaDatas title={"콘텐츠"} siteName={""} siteUrl={location.pathname} />
      <div
        className={`${isMobile ? "mx-3  mt-[80px]" : "mx-12  mt-[120px]"} mb-10 min-h-screen`}
      >
        <div className="my-[50px] mb-[15px]">
          {/* {isMobile ? (
            <MobileTopSection />
          ) : (
            <div className="flex justify-between gap-6">
              <DirectorBanner /> <BoxAvatarCard />
            </div>
          )} */}
          {isMobile ? <MobileTopSection /> : <SlideBanner />}
        </div>

        <div className="mb-[14px] flex gap-5 overflow-auto items-center justify-between">
          {isMobile ? (
            <FilterBar itmes={itmes} toggle={toggle} />
          ) : (
            <FilterReload toggle={toggle} />
          )}

          <FilterOrderBtn order={order} handleChange={handleChange} />
        </div>

        <div className={"flex"}>
          {!isMobile && toggle && <FilterContainer toggle={toggle} />}

          <JustifiedInfiniteGrid
            // key={toggle ? "expanded" : "collapsed"}
            // useResizeObserver={true}
            className={`transition-all duration-300 ease-in-out`}
            style={{
              width: toggle && !isMobile ? "calc(100% - 220px)" : "100%",
            }}
            loading={
              <img
                src={
                  themeData === "light" || themeData === ""
                    ? "/img/standbuy/animation/standbuy-walking-dark.gif"
                    : "/img/standbuy/animation/standbuy-walking-white.gif"
                }
                alt="loading"
                width={320}
              />
            }
            stretch="true"
            // passUnstretchRow={true}
            sizeRange={isMobile ? [228, 228] : [440, 540]}
            // stretchRange={[144, 320]}
            {...(isMobile && { columnRange: [0, 2] })}
            threshold={3000}
            gap={8}
            onRequestAppend={(e) => {
              const nextGroupKey = (+e.groupKey! || 0) + 1;
              if (nextGroupKey === groupKey) {
                return;
              }
              setGroupKey(nextGroupKey);
              e.wait();

              setTimeout(async () => {
                const add = await getItems(
                  nextGroupKey,
                  15,
                  order,
                  itmes[itmes?.length - 1]?.contentId || null
                );
                if (add?.length === 0) {
                  e.ready();
                  return true;
                }

                dispatch(setStockList({ data: add, reset: false }));
                setItems([...itmes, ...add]);
                e.ready();
              }, 1);
            }}
          >
            {itmes?.length < 1 ? (
              <div className="flex flex-col items-center justify-center animate-pulse">
                <div className="h-4 w-32 bg-gray-200 rounded mb-2"></div>
                <div className="h-4 w-48 bg-gray-200 rounded"></div>
              </div>
            ) : (
              itmes.map((item: any, index: any) => {
                const isHover = hoverItemKey === item.key;

                return (
                  <Content data-grid-groupkey={item.groupKey} key={index}>
                    <div>
                      {item?.grade === "ROLE_DIRECTOR" && (
                        <div
                          className={"w-[30px] absolute top-2 right-2 z-[998]"}
                        >
                          <Tooltip content="Director 마크 : 전문가들의 콘텐츠에요😀">
                            <img
                              src="/img/standbuy/icon/dr-badge.svg"
                              alt="director badge"
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <img
                      className={`rounded-xl ${
                        item?.status === "SANCTION" ? "blur-lg" : ""
                      }`}
                      src={item.thumbnailPath}
                      alt="thumbnail"
                      data-grid-maintained-target="true"
                    />

                    {isHover && (
                      <aside className="status show">
                        <div className="h-full flex flex-col justify-end items-start gap-4">
                          <div className="flex items-center gap-3">
                            <div className="min-w-[30px] min-h-[30px] w-[30px] h-[30px]">
                              <Avatar
                                size="sm"
                                variant="circular"
                                alt="profile img"
                                src={
                                  item?.memberProfileImage
                                    ? item?.memberProfileImage
                                    : "/img/standbuy/icon/no-profile.svg"
                                }
                                placeholder={undefined}
                                className="border border-[#134B70] ring-1 ring-[#134B70] p-0.5 w-[30px] h-[30px] cursor-pointer z-[100]"
                              />
                            </div>
                            <StockText
                              fontSize={15}
                              fontWeight={600}
                              color="#fff"
                              className="truncate"
                            >
                              {item?.memberNickname}
                            </StockText>
                          </div>

                          <div className="w-full flex items-center justify-between">
                            <StockText
                              fontSize={18}
                              fontWeight={700}
                              color="#fff"
                              className="truncate"
                            >
                              {item?.contentName}
                            </StockText>
                            <div className="flex items-center gap-1">
                              <FontAwesomeIcon
                                fontSize={20}
                                icon={faHeart}
                                className={`text-red-500`}
                              />
                              <Text
                                fontSize={15}
                                fontWeight={700}
                                color="#ef4444"
                              >
                                {item?.likesCount}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </aside>
                    )}

                    <button
                      onMouseEnter={() => setHoverItemKey(item.key)}
                      onMouseLeave={() => setHoverItemKey(null)}
                      className="item w-full h-full absolute top-0 left-0"
                      onClick={() => {
                        if (item?.status === "SANCTION") {
                          setInfoModal({ show: true, data: item });
                          if (me?.memberId === item?.memberId) {
                            setEndModal({ show: true, data: item });
                          }
                        } else {
                          window.name = JSON.stringify({
                            contentId: item?.contentId,
                          });
                          window.open(
                            `${URL_HOST}feed-info`,
                            "feed",
                            `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
                          );
                        }
                      }}
                    >
                      {isHover && item?.classification === "VIDEO" && (
                        <div className="video-wrap">
                          {item?.status === "ENABLE" && (
                            <ReactHlsPlayer
                              className="video"
                              autoPlay={true}
                              controls={false}
                              muted={true}
                              loop={true}
                              playerRef={playerRef}
                              src={item?.previewPath}
                              hlsConfig={{
                                maxLoadingDelay: 4,
                                minAutoBitrate: 0,
                                lowLatencyMode: true,
                              }}
                            />
                          )}
                        </div>
                      )}
                    </button>
                  </Content>
                );
              })
            )}
          </JustifiedInfiniteGrid>
        </div>
      </div>

      <div
        className={`fixed bottom-10 ${isMobile ? "left-5" : "right-10"} z-[9999]`}
      >
        <div className="flex flex-col justify-center items-center gap-4 z-[9999]">
          <FeedNav />

          {!isMobile && (
            <IconButton
              //@ts-ignore
              className="rounded-full px-[28px] py-[28px]"
              placeholder={undefined}
              children={undefined}
              size="lg"
              onClick={moveToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </IconButton>
          )}
        </div>
      </div>
      {infoModal.show && (
        <TermsNoticeModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
    </>
  );
}

const Content = styled.div`
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  /* aside.status 기본 상태 */
  .status {
    pointer-events: none;
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
    padding: 18px 25px;
    background: linear-gradient(
      to bottom,
      rgba(249, 249, 249, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* 필요에 따라 pointer-events 조정 */
  }

  /* .show 클래스가 붙으면 보임 */
  .status.show {
    opacity: 1;
  }

  /* 비디오 미리보기 영역 */
  .video-wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: 9;
  }
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* 버튼(hover 영역) */
  button.item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 11;
  }
`;
