import Text from "../design-system/Text";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../util/useContext";
import { CustomNavBar } from "./CustomNavBar";

export default function MobileLandingPage() {
  const navigate = useNavigate();
  const { isLogin, me } = useContext();

  // useEffect(() => {
  //   if (isLogin && me?.agreementSelectiveTerms === false) {
  //     navigate("/social-check");
  //   } else if (isLogin && me?.agreementSelectiveTerms) {
  //     navigate("/feed");
  //   } else if (!isLogin) {
  //     navigate("/");
  //   }
  // }, [isLogin, me]);

  return (
    <div className="">
      <CustomNavBar />
      <img
        src={"/img/standbuy/background/mobile-landing.jpg"}
        alt="mobile-landing"
      />

      <div className="flex justify-center pb-10 mb-4">
        <button
          className="rounded-full bg-black pt-4 pb-5 px-7 opacity-1 cursor-pointer w-[280px]"
          onClick={() =>
            navigate(
              isLogin && me?.agreementSelectiveTerms
                ? "/feed"
                : isLogin && me?.agreementSelectiveTerms === false
                  ? "/social-check"
                  : "/login"
            )
          }
        >
          <Text color="#fff" fontWeight={600}>
            {isLogin ? "팔러가기" : "회원가입"}
          </Text>
        </button>
      </div>
    </div>
  );
}
