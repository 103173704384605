import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import Text from "../../../components/design-system/Text";
import Dayjs from "dayjs";
import { addComma } from "../../../util/number";
import FeedInfoModal from "../../feed/modal/FeedInfoModal";
import PayCancelModal from "../modal/PayCancelModal";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { payDetailList } from "../../../api/pay/pay";
import { URL_HOST } from "../../../api/api";

export default function PayClickDetail({ payOrderId }: any) {
  const { get } = useAxios();
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const [payCancel, setPayCancel] = useState<any>({ show: false, data: "" });
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();

  const { status, data: payDetail } = useQuery({
    queryKey: ["payInfo", payOrderId],
    queryFn: () => payDetailList(payOrderId),
  });

  const today = Dayjs();
  // 일주일 전 날짜 계산
  const aWeekAgo: any = today.subtract(7, "day");

  const popupWidth = 1400;
  const popupHeight = 1000;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  // 팝업 창을 화면 가운데에 위치시키기 위한 좌표 계산
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  useEffect(() => {
    window.navigateToPage = (url: string, options?: any) => {
      navigate(url, options);
    };
  }, [navigate]);

  return (
    <div className={"w-full items-center flex gap-4"}>
      <div className={"w-full"}>
        {payDetail?.data.map((item: any, index: any) => (
          <div key={index} className="py-[30px] border-b border-[#EBEBEB]">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-6">
                <img
                  src={item?.thumbnail}
                  alt="thumbnail"
                  className="w-[80px] h-[80px] rounded-lg object-cover cursor-pointer"
                  onClick={() => {
                    window.name = JSON.stringify({
                      contentId: item?.contentId,
                    });
                    window.open(
                      `${URL_HOST}feed-info`,
                      "feed",
                      `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
                    );
                  }}
                />

                <div>
                  <Text
                    fontSize={isMobile ? 12 : 16}
                    fontWeight={700}
                    color={"#42BC7F"}
                  >
                    {item?.description}
                  </Text>
                  <Text
                    fontSize={isMobile ? 10 : 12}
                    fontWeight={600}
                    color={"#b2b2b2"}
                  >
                    {Dayjs(item.createTime).format("YYYY.MM.DD")} •
                    {item?.type === "OWNERSHIP" ? "소유권" : "1년 이용권"}
                  </Text>
                </div>
              </div>

              <div className="flex flex-col items-end justify-center">
                <Text
                  fontSize={isMobile ? 12 : 20}
                  fontWeight={600}
                  color="#484848"
                  className=""
                >
                  {addComma(item?.amount)} ₩
                </Text>

                {!item?.download &&
                  !item?.resreselling &&
                  Dayjs(item?.createTime).isSame(Dayjs(), "day") && (
                    <Text
                      fontSize={12}
                      fontWeight={600}
                      color={`${item?.cancel && "#f87171"}`}
                      className={"cursor-pointer "}
                      onClick={() => {
                        if (!item?.cancel) {
                          setPayCancel({ show: true, data: item });
                        }
                      }}
                    >
                      {item?.cancel ? "결제 취소 완료" : "결제 취소"}
                    </Text>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {payCancel.show && (
        <PayCancelModal
          open={payCancel.show}
          onClose={() => setPayCancel({ show: false })}
          data={payCancel.data}
          payOrderId={payOrderId}
        />
      )}

      {infoModal.show && (
        <FeedInfoModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
    </div>
  );
}
