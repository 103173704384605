//@ts-nocheck
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { instance } from "../../../api";
import { API_DEFAULT } from "../../../api/api";
import { useMediaQuery } from "react-responsive";
import Text from "../../../components/design-system/Text";
import StockListSide from "../../feed/stock-list/StockListSide";
import { shallowEqual } from "react-redux";
import { setLocationList } from "../../../store/reducer/locationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { FadeLoader } from "react-spinners";

export default function LocationSideView({
  loading,
  setLoading,
  fetchLocations,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data);
  const bounds = useAppSelector((state) => state.bounds);
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const dispatch = useAppDispatch();

  // ==========================================
  // 변경 전/후 bounds 비교를 위한 설정
  // ==========================================
  // 초기 bounds값을 저장해놓을 ref
  const initialBounds = useRef(bounds);
  // bounds가 바뀔 경우 버튼 노출 여부를 결정할 state
  const [isBoundsChanged, setIsBoundsChanged] = useState(false);

  // ==========================================
  // items 관련 state 및 ref
  // ==========================================
  const groupKeyRef = useRef(0);
  const [items, setItems] = useState<any[]>([]);
  const isRefreshed = useRef(false);
  const [gridKey, setGridKey] = useState(0);
  const [spinnersFlag, setSpinnersFlag] = useState<any>(true);

  // ==========================================
  // 지도 위치가 변경되었는지 확인하는 useEffect
  // ==========================================
  useEffect(() => {
    // 단순 비교의 예: 위경도 4가지를 모두 비교
    const changed =
      bounds?.sw?.lat !== initialBounds.current?.sw?.lat ||
      bounds?.sw?.lng !== initialBounds.current?.sw?.lng ||
      bounds?.ne?.lat !== initialBounds.current?.ne?.lat ||
      bounds?.ne?.lng !== initialBounds.current?.ne?.lng;

    setIsBoundsChanged(changed);
  }, [bounds]);

  // ==========================================
  // "현 지도에서 새로고침" 버튼 클릭
  // ==========================================
  const fetchItemsNow = async () => {
    groupKeyRef.current = 0;
    setItems([]);
    isRefreshed.current = true;

    setGridKey((prevKey) => prevKey + 1); // Grid를 리렌더링하여 데이터 요청 트리거
    fetchLocations();

    // 검색을 실행했으므로, 이제 해당 bounds를 '초기값'으로 취급
    initialBounds.current = bounds;
    setIsBoundsChanged(false);
  };

  // ==========================================
  // 새로고침 이후 호출되는 아이템 가져오기
  // ==========================================
  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    setSpinnersFlag(true);

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: bounds.sw.lat,
      latMax: bounds.ne.lat,
      lonMin: bounds.sw.lng,
      lonMax: bounds.ne.lng,
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.forEach((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
      setSpinnersFlag(false);
    }

    return nextItems;
  }

  // ==========================================
  // 초기 렌더링 시 호출되는 아이템 가져오기
  // (lat/lon 범위 고정값 사용)
  // ==========================================
  async function getItemMount(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    setSpinnersFlag(true);

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: 36.507468472827576,
      latMax: 38.60617845599798,
      lonMin: 125.24388600000003,
      lonMax: 128.75951100000003,
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.forEach((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
      setSpinnersFlag(false);
    }

    return nextItems;
  }

  return (
    <div className={`${isMobile ? "mt-[20px]" : "mt-[80px]"}`}>
      <JustifiedInfiniteGrid
        className={"container"}
        key={gridKey}
        placeholder={
          <div className="placeholder">추가 데이터를 불러오는 중...</div>
        }
        columnRange={[0, 1]}
        threshold={3000}
        gap={8}
        loading={
          spinnersFlag ? (
            <img
              src={
                themeData === "light" || themeData === ""
                  ? "/img/standbuy/animation/standbuy-walking-dark.gif"
                  : "/img/standbuy/animation/standbuy-walking-white.gif"
              }
              alt="loading"
              width={320}
            />
          ) : undefined
        }
        onRequestAppend={(e) => {
          const nextGroupKey = groupKeyRef.current + 1;
          groupKeyRef.current = nextGroupKey;
          e.wait();

          setTimeout(async () => {
            let add;
            if (isRefreshed.current) {
              // 새로고침 이후에는 getItems 사용
              add = await getItems(nextGroupKey, 20);
            } else {
              // 초기 렌더링 시에는 getItemMount 사용
              add = await getItemMount(nextGroupKey, 20);
            }

            if (add?.length === 0) {
              setSpinnersFlag(false);
              return;
            }

            dispatch(setLocationList({ data: add, reset: false }));
            setItems((prevItems) => [...prevItems, ...add]);
            e.ready();
          }, 1);
        }}
      >
        {items?.map((item: any, index: any) => (
          <StockListSide
            data-grid-groupkey={item.groupKey}
            key={index}
            index={index}
            item={item}
          />
        ))}
      </JustifiedInfiniteGrid>

      {/* 
        loading이 false 이면서, bounds가 변경된 상태(isBoundsChanged)가 true일 때만 
        '현 지도에서 검색' 버튼 노출 
      */}
      {!loading && isBoundsChanged && (
        <button
          onClick={fetchItemsNow}
          style={{ background: "rgba(0, 74, 111, 0.8)" }}
          className="fixed bottom-[20px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(0,74,111,0.8)]  p-4 text-white text-center z-50 rounded-3xl font-bold"
        >
          <FontAwesomeIcon icon={faRotateRight} className="mr-2" />현 지도에서
          검색
        </button>
      )}
    </div>
  );
}
