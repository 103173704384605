import React from "react";
import Modal from "./Modal";
import Text from "../Text";
import FillButton from "../button/FillButton";
import { useNavigate } from "react-router-dom";

export default function LoginAuthModal({ open, onClose }: any) {
  const navigate = useNavigate();

  const handleMove = () => {
    onClose();
    navigate("/login");
  };
  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 ">로그인 후 사용이 가능 합니다.</Text>

        <FillButton
          text="로그인하러 가기"
          className="w-[300px] mt-[30px]"
          size="lg"
          onClick={handleMove}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
