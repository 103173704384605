import React, { useState } from "react";
import PageCard from "../../components/design-system/PageCard";
import EditAvatarForm from "./EditAvatarForm";
import RemoveUserModal from "./modal/RemoveUserModal";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";
import GoogleAdsense from "react-adsense-google";
import GoogleAd from "../../components/ads/GoogleAd";
import { MetaDatas } from "../../meta/MetaDatas";
import { useLocation } from "react-router-dom";
import Text from "../../components/design-system/Text";
import { UploadTextColor } from "../../styles/color";

export default function ProfileEditPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const location = useLocation();
  const [userRemove, setUserRemove] = useState({ show: false });

  return (
    <>
      <MetaDatas
        title={"프로필 편집"}
        siteName={""}
        siteUrl={location.pathname}
      />

      <div className="flex flex-col justify-between h-full">
        <PageCard
          title="프로필 편집"
          textButton="탈퇴하기"
          onClick={() => setUserRemove({ show: true })}
        >
          <EditAvatarForm />

          <Text
            color={UploadTextColor}
            className="px-6 flex justify-center w-full"
            fontSize={15}
          >
            *하단 광고는 STANDBUY와 무관한 광고 입니다.
          </Text>
          <hr className="w-full my-1 border-gray-300 border-[1px]" />

          <div className="w-full sm:max-w-[728px] mx-auto my-5">
            <GoogleAd />
          </div>
        </PageCard>
        {userRemove.show && (
          <RemoveUserModal
            open={userRemove.show}
            onClose={() => setUserRemove({ show: false })}
          />
        )}
      </div>
      {isMobile ? <MobileFooter /> : <CustomFooter styles={"fixed bottom-0"} />}
    </>
  );
}
