import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  isLogin: false,
  me: {},
  accessToken: null,
  refreshToken: null,
  expiresAt: null,
};
//*--------------------------------------------------*
/** @createSlice */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 초기화

    // 로그인상태
    setLogin(state: any, action) {
      state.isLogin = action.payload;
    },
    // 사용자본인정보
    setMe(state: any, action) {
      state.me = action.payload;
    },
    setMeReset(state: any, action) {
      state.me = action.payload;
    },
    //accessToken 업데이트
    setAccessToken(state: any, action) {
      state.accessToken = action.payload;
    },
    // refreshToken 업데이트
    setRefreshToken(state: any, action) {
      state.refreshToken = action.payload;
    },
    setExpiresAt(state: any, action) {
      state.expiresAt = action.payload;
    },
  },
});

export const {
  setLogin,
  setMe,
  setAccessToken,
  setRefreshToken,
  setExpiresAt,
  setMeReset,
} = authSlice.actions;

export default authSlice.reducer;
