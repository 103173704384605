import React from "react";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { useNavigate } from "react-router-dom";
import { UploadTextColor } from "../../../styles/color";

export default function NoCartData() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col gap-10 justify-center items-center">
        <Text className="no-data-title  mt-[90px]">
          장바구니에 담긴 파일이 없어요.
        </Text>

        <img
          src={"/img/standbuy/icon/thumbnail-upload.svg"}
          alt="thumbnail-upload"
        />

        <div className="flex flex-col items-center">
          <Text className="upload-text" color={UploadTextColor}>
            원하는 이미지나 영상을
          </Text>
          <Text className="upload-text" color={UploadTextColor}>
            장바구니에 담아 구매해보세요.
          </Text>
        </div>

        <FillButton
          text={"원하는 파일 찾으러가기"}
          onClick={() => navigate("/feed")}
          size="lg"
          className="w-[360px] focus:!border-none"
        />
      </div>
    </div>
  );
}
