import React, { useEffect, useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { Input } from "@material-tailwind/react";
import FillButton from "../../../components/design-system/button/FillButton";
import { API_DEFAULT } from "../../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../../api";

export default function ProfileCareerModal({ open, onClose, data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<any>();
  const [inputSets, setInputSets] = useState<any>([
    { startDate: "", endDate: "", content: "" },
  ]);

  const handleChange = (index: any, type: any, value: any) => {
    // const formattedValue =
    //   type === "startDate" || type === "endDate" ? autoHypenDate(value) : value;
    // const newInputSets: any = [...inputSets];
    // newInputSets[index][type] = formattedValue;
    // setInputSets(newInputSets);

    // 먼저, 현재의 inputSets 상태를 복사합니다.
    const newInputSets = [...inputSets];

    // 해당 인덱스의 객체를 찾아 필드의 값을 업데이트합니다.
    newInputSets[index][type] = value;

    // 변경된 새 배열로 inputSets 상태를 업데이트합니다.
    setInputSets(newInputSets);
  };

  const addInputSet = () => {
    setInputSets([...inputSets, { startDate: "", endDate: "", content: "" }]);
  };

  const removeInputSet = (indexToRemove: any) => {
    setInputSets(
      inputSets.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const autoHypenDate = (str: any) => {
    str = str.replace(/[^0-9]/g, "");
    let tmp = "";
    if (str.length <= 4) {
      tmp = str;
    } else if (str.length <= 6) {
      tmp = str.substr(0, 4) + "." + str.substr(4);
    } else if (str.length <= 8) {
      tmp = str.substr(0, 4) + "." + str.substr(4, 2) + "." + str.substr(6);
    }
    return tmp;
  };

  // 날짜 형식을 YYYY.MM.DD에서 YYYY-MM-DD로 변환하는 함수
  function formatDate(dateStr: any) {
    return dateStr.replace(/\./g, "-");
  }

  async function onProfileCareer() {
    const careers = inputSets.map((inputSet: any) => ({
      careerId: inputSet.careerId ? inputSet?.careerId : null,
      startDate: formatDate(inputSet.startDate),
      endDate: formatDate(inputSet.endDate),
      description: inputSet.content,
    }));

    const hasNullDates = careers.every(
      (career: any) => career.startDate === "" && career.endDate === ""
    );

    const res = await instance
      .post(`${API_DEFAULT}/user/profile/update/careers`, {
        careers: hasNullDates ? [] : careers,
        affiliation: message,
      })
      .then((response) => {
        onClose();
        dispatch(
          toastFetched({
            show: true,
            text: "아티스트 정보가 저장되었습니다.",
            type: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          toastFetched({
            show: true,
            text: error.response.data.error.message,
            type: "error",
          })
        );
      });
  }

  const { mutate: onCareer } = useMutation({
    mutationFn: onProfileCareer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });

  async function onDeleteCareer(careerId: any) {
    const res = await instance.delete(
      `${API_DEFAULT}/user/profile/delete/career?careerId=${careerId}`
    );
    if (res?.data.success) {
      dispatch(
        toastFetched({
          show: true,
          text: "삭제되었습니다.",
          type: "success",
        })
      );
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res.data.error.message,
          type: "error",
        })
      );
    }
  }

  const { mutate: onDelete } = useMutation({
    mutationFn: onDeleteCareer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });

  const handleEndEvent = async () => {
    try {
      await onCareer();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (data) {
      setMessage(data?.affiliation);
    }
  }, [data]);

  useEffect(() => {
    if (data?.careerList && data.careerList.length > 0) {
      const newInputSets = data.careerList.map((item: any) => ({
        careerId: item.careerId,
        startDate: item.startDate,
        endDate: item.endDate,
        content: item.description,
      }));
      setInputSets(newInputSets);
    }

    // else {
    //   // 데이터가 없는 경우, 기본 입력 세트를 유지
    //   setInputSets([{ startDate: "", endDate: "", content: "" }]);
    // }
  }, [data?.careerList]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerNone
      noOutSideClosed={true}
      width={isMobile ? "100%" : 830}
    >
      <Text fontSize={18} color="#242424" fontWeight={700} className="">
        아티스트 정보 입력
      </Text>

      <Text fontSize={16} color="#303030" fontWeight={600} className="mt-5 ">
        현재 소속
      </Text>

      <div className="w-full mt-[10px] mb-5">
        <Input
          variant="static"
          type="text"
          className="!py-5 !px-3 rounded-lg !border !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10 dark:!bg-darkBg dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] "
          placeholder="경력을 입력하는 칸입니다. 자유롭게 사용할 수 있어요."
          label="경력을 입력하는 칸입니다. 자유롭게 사용할 수 있어요."
          labelProps={{
            className: "hidden",
          }}
          value={message}
          onChange={(e: any) => {
            setMessage(e.target.value);
          }}
          maxLength={50}
          crossOrigin={undefined}
        />

        <div className="flex justify-end">
          <Text fontSize={14} fontWeight={600} color="#535353" className="">
            {message?.length || 0} / 50
          </Text>
        </div>
      </div>

      <div className="flex items-center justify-between mt-5">
        <Text fontSize={16} color="#303030" fontWeight={600} className="">
          이력사항
        </Text>
        <Text
          fontSize={14}
          fontWeight={600}
          color="#686868"
          className="underline underline-offset-4 cursor-pointer "
          onClick={addInputSet}
        >
          추가
        </Text>
      </div>

      <div className={`max-h-[200px] overflow-auto`}>
        {inputSets?.map((inputSet: any, index: any) => (
          <div key={index} className="mt-[10px]">
            <div
              className={`flex ${isMobile && "flex-col"} gap-[10px] items-center`}
            >
              <div
                className={`border border-[#f1f1f1] dark:!border-[#1a1a1a] pl-[18px] py-[14px] rounded-lg flex items-center gap-1 ${isMobile ? "w-full" : "w-1/2"}`}
              >
                <Text
                  fontSize={12}
                  fontWeight={600}
                  color="#424242"
                  className=""
                >
                  날짜
                </Text>

                <input
                  type="date"
                  className="ml-4 outline-none text-[14px] font-semibold dark:!bg-darkBg dark:!border-[#1a1a1a]  dark:border-solid dark:placeholder:text-[#707070] "
                  placeholder="시작일"
                  maxLength={10}
                  value={inputSet.startDate}
                  onChange={(e) =>
                    handleChange(index, "startDate", e.target.value)
                  }
                />

                <Text>-</Text>

                <input
                  type="date"
                  className="ml-2 outline-none text-[14px] font-semibold dark:!bg-darkBg dark:!border-[#1a1a1a]  dark:border-solid dark:placeholder:text-[#707070] "
                  placeholder="종료일"
                  maxLength={10}
                  value={inputSet.endDate}
                  onChange={(e) =>
                    handleChange(index, "endDate", e.target.value)
                  }
                />
              </div>
              <div
                className={`border border-[#f1f1f1] dark:!border-[#1a1a1a] pl-[18px] py-[14px] rounded-lg flex items-center gap-1 ${isMobile ? "w-full" : "w-1/2"} h-[54px]`}
              >
                <Text
                  fontSize={12}
                  fontWeight={600}
                  color="#424242"
                  className=""
                >
                  내용
                </Text>

                <input
                  type="text"
                  className="ml-2 outline-none text-[14px] font-semibold dark:!bg-darkBg dark:!border-[#1a1a1a] dark:border-solid dark:placeholder:text-[#707070] "
                  placeholder="내용 입력"
                  value={inputSet.content}
                  onChange={(e) =>
                    handleChange(index, "content", e.target.value)
                  }
                />
              </div>

              <FontAwesomeIcon
                icon={faXmark}
                onClick={() => {
                  removeInputSet(index);
                  if (inputSet?.careerId) {
                    onDelete(parseInt(inputSet?.careerId));
                  }
                }}
                fontSize={16}
                className="cursor-pointer "
              />
            </div>
          </div>
        ))}

        {inputSets?.length < 1 && (
          <div className="my-2 flex justify-center">
            <Text fontSize={14} fontWeight={400} color="#535353">
              추가 버튼을 누른 후 이력사항을 입력해주세요.
            </Text>
          </div>
        )}
      </div>
      <ul
        style={{
          listStyle: "inside",
          color: "#A7A7A7",
          fontSize: "12px",
          fontWeight: "500",
          marginTop: "8px",
        }}
      >
        <li>이력사항은 날짜를 올바르게 입력하지 않으면 저장되지 않습니다.</li>
        <li>
          3개 이상의 이력사항을 등록할 경우 스크롤을 사용해서 입력해주세요.
        </li>
      </ul>

      <div className="flex flex-col items-center">
        <FillButton
          text="편집 완료"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={handleEndEvent}
          disabled={message || data?.affiliation ? false : true}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
