import React from "react";
import { useAxios } from "../../lib/api/useAxios";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import { UploadTextColor } from "../../styles/color";
import FillButton from "../design-system/button/FillButton";
import Modal from "../design-system/modal/Modal";
import Text from "../design-system/Text";
import { signOut, signOutLogin } from "../../lib/modules/auth";
import { LOCAL_STORAGE } from "../../lib/const";
import { shallowEqual } from "react-redux";

export default function OverlapModal({ open, onClose }: any) {
  const { del } = useAxios();
  const dispatch = useAppDispatch();
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual
  );
  const _token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const r_token = localStorage.getItem(LOCAL_STORAGE.R_TOKEN);

  return (
    <Modal open={open} onClose={onClose} headerNone noOutSideClosed>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 ">다른 브라우저에서 로그인 중 입니다.</Text>
        <Text className="upload-text  mt-4" color={UploadTextColor}>
          다시 로그인 할까요?
        </Text>

        <FillButton
          text="네"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => {
            onClose();
            del(`${API_DEFAULT}/user/auth/sign-out`, {
              accessToken: _token,
              refreshToken: r_token,
              browser: browserData,
            });
            signOutLogin({ dispatch: dispatch });
          }}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={() => {
            onClose();
            del(`${API_DEFAULT}/user/auth/sign-out`, {
              accessToken: _token,
              refreshToken: r_token,
              browser: browserData,
            });
            signOut({ dispatch: dispatch });
          }}
        >
          아니요
        </Text>
      </div>
    </Modal>
  );
}
