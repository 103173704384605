import React from "react";
import { Avatar, Tooltip } from "@material-tailwind/react";
import { checkRole } from "../../../../util";
import { addComma } from "../../../../util/number";
import Text from "../../../../components/design-system/Text";

export default function AvatarMobileCard({ data }: any) {
  return (
    <div className="py-[28px] mb-6 min-w-[284px] h-[340px]">
      <div className="flex flex-col items-center justify-center gap-5">
        <Avatar
          variant="circular"
          alt="profile img"
          src={
            data?.profileImage
              ? data?.profileImage
              : "/img/standbuy/icon/no-profile.svg"
          }
          placeholder={undefined}
          className="border border-[#134B70] ring-1 hover:ring-1 ring-[#134B70] hover:ring-[#396984] p-0.5 w-[147px] h-[147px]"
        />

        <Text
          fontSize={26}
          fontWeight={700}
          color="#1a1a1a"
          className="relative "
        >
          {data?.nickname}

          {data?.grade === "ROLE_DIRECTOR" && (
            <div className={"w-[20px] absolute -top-1 -right-6 z-[998]"}>
              <Tooltip
                content={<div>Director 마크 : 전문가들의 콘텐츠에요😀</div>}
              >
                <img src={"/img/standbuy/icon/dr-badge.svg"} alt="director" />
              </Tooltip>
            </div>
          )}
        </Text>
      </div>

      <div className="w-full h-[1px] bg-[#F1F1F1] my-5"></div>

      <div className="flex justify-around">
        <div className="flex flex-col items-center justify-center">
          <Text fontSize={20} fontWeight={700} color="#343434" className="">
            {data?.subscriberCount}
          </Text>
          <Text fontSize={16} fontWeight={600} color="#717171" className="">
            구독자
          </Text>
        </div>

        <div className="flex flex-col items-center">
          <Text fontSize={20} fontWeight={700} color="#343434" className="">
            {checkRole(data?.grade)}
          </Text>
          <Text fontSize={16} fontWeight={600} color="#717171" className="">
            등급
          </Text>
        </div>

        <div className="flex flex-col items-center">
          <Text fontSize={20} fontWeight={700} color="#343434" className="">
            {addComma(data?.salesCount)}
          </Text>
          <Text fontSize={16} fontWeight={600} color="#717171" className="">
            다운로드
          </Text>
        </div>
      </div>
    </div>
  );
}
