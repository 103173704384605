import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { addComma } from "../../../util/number";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { handleTransFof } from "../../../api/exchange/exchange";

export default function ExChangeModal({ open, onClose, fof }: any) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { mutate, status } = useMutation({
    mutationFn: handleTransFof,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });
      if (data?.success) {
        alert("정산 요청 성공");
        window.location.reload();
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          })
        );
      }
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 ">수익금을 정산하시겠어요?</Text>
        <Text className="upload-text  mt-4" color={UploadTextColor}>
          정산 예정 : {addComma(fof) || 0} ₩
        </Text>

        <FillButton
          text="정산하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={mutate}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] "
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
