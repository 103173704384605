import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Switcher from "../../components/common/Switcher";
import ResetPasswordForm from "./card/ResetPasswordForm";
import { MetaDatas } from "../../meta/MetaDatas";

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <MetaDatas
        siteName={""}
        title={"신규 비밀번호 설정"}
        siteUrl={location.pathname}
      />
      <div className="bg-gray-200 dark:bg-black h-screen flex justify-center items-center">
        <Switcher className="hidden" />
        <div className="w-full flex flex-col items-center gap-12">
          <img
            src={"/img/standbuy/logo/logo-green.svg"}
            alt="standbuy"
            onClick={() => navigate("/")}
            className="cursor-pointer"
            width={200}
          />

          <ResetPasswordForm />
        </div>
      </div>
    </>
  );
}
