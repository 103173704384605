import React, { useEffect, useState } from "react";
import PageCard from "../../components/design-system/PageCard";
import Text from "../../components/design-system/Text";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import FillButton from "../../components/design-system/button/FillButton";
import { useAppSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";

export default function DirectorPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const themeData = useAppSelector((state) => state.theme.data);
  const { me } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      me?.memberId === 1 ||
      me?.memberId === 2 ||
      me?.memberId === 3 ||
      me?.memberId === 6
    ) {
      return;
    } else {
      navigate("/feed");
    }
  }, [me]);

  return (
    <>
      <div className="flex flex-col justify-between h-full pb-[120px] !bg-[#121212]">
        <motion.div
          className={`w-full ${isMobile ? "px-10" : "flex justify-center"} mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div
            className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
          >
            <div className="flex items-center mb-[30px] ">
              <Text className="title " color="#ececec">
                디렉터 신청
              </Text>
            </div>

            <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

            <img
              src="/img/standbuy/background/director-banner.png"
              alt="director-banner"
              width="100%"
            />

            <div className="mt-10">
              <Text fontSize={18} className="!text-[#ececec] ">
                안녕하세요 <strong>{me?.nickname}</strong> 아티스트님.
              </Text>
              <Text fontSize={18} className="!text-[#ececec] ">
                오늘도 스탠바이에서 만나뵙게 되어 영광 입니다. 스탠바이는
                콘텐츠의 퀄리티 향상을 위해 디렉터를 모집합니다.
              </Text>
              <Text fontSize={18} className="!text-[#ececec] ">
                디렉터는 스탠바이의 든든한 파트너이자 함께 플랫폼을 이끌어나갈
                동반자가 될 것 입니다.
              </Text>
              <Text fontSize={18} className="!text-[#ececec] ">
                아래 등급표를 참조하시면, 아티스트와 디렉터의 차이점을 쉽게
                확인하실 수 있습니다.
              </Text>
            </div>

            <table className="table-fixed w-full mt-10 text-center dark:text-darkText !text-[#ececec] ">
              <thead className="border-b border-[#AFAFAF] ">
                <tr>
                  <th className="border p-2 w-24"></th>
                  <th className="border p-2 w-1/2">아티스트</th>
                  <th className="border p-2 w-1/2">디렉터</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border p-2">신청방법</td>
                  <td className="border p-2">모든유저</td>
                  <td className="border p-2">신청 후 평가</td>
                </tr>
                <tr>
                  <td className="border p-2">필수조건</td>
                  <td className="border p-2">-</td>
                  <td className="border p-2">
                    월 5개 이상 콘텐츠 업로드 (미 업로드 확인시 익월 자격 박탈)
                  </td>
                </tr>
                <tr>
                  <td className="border p-2">혜택</td>
                  <td className="border p-2">-</td>
                  <td className="border p-2">수수료 22% (한시적 10% 적용)</td>
                </tr>
                <tr>
                  <td className="border p-2">엠블럼</td>
                  <td className="border p-2">-</td>
                  <td className="border p-2">디렉터 엠블럼 적용</td>
                </tr>
              </tbody>
            </table>

            <div className="flex justify-center mt-10">
              <FillButton
                onClick={() => navigate(`/director/1`)}
                text={"디렉터 신청하기"}
                className="w-[360px] py-4"
                color={"white"}
              />
            </div>

            <div className="flex gap-4 items-center justify-center">
              <Text
                className="underline underline-offset-4 cursor-pointer my-6 !text-[#ececec] "
                onClick={() => navigate("/feed")}
                fontSize={14}
                fontWeight={500}
              >
                뒤로가기
              </Text>
            </div>
          </div>
        </motion.div>
      </div>
      {/* {isMobile ? <MobileFooter /> : <CustomFooter />} */}
    </>
  );
}
