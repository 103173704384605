import React from "react";
import Switcher from "../../components/common/Switcher";
import { useLocation, useNavigate } from "react-router-dom";
import JoinAddForm from "./card/JoinAddForm";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MetaDatas } from "../../meta/MetaDatas";

export default function JoinFormPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const location = useLocation();

  return (
    <>
      <MetaDatas siteName={""} title={"회원가입"} siteUrl={location.pathname} />
      <div className="bg-gray-200 dark:bg-black min-h-full flex justify-center items-center">
        <Switcher className="hidden" />
        <div
          className={`w-full flex flex-col items-center gap-12 ${isMobile ? "mx-5 mb-10" : "mx-auto"}`}
        >
          <img
            src={
              themeData === "light" || themeData === ""
                ? "/img/standbuy/logo/logo.svg"
                : "/img/standbuy/logo/logo-white.svg"
            }
            alt="standbuy"
            onClick={() => navigate("/")}
            className="cursor-pointer mt-10"
            width={200}
          />
          <JoinAddForm />
        </div>
      </div>
    </>
  );
}
