import React from "react";
import Switcher from "../../components/common/Switcher";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordFindForm from "./card/PasswordFindForm";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MetaDatas } from "../../meta/MetaDatas";

export default function PasswordFindPage() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  return (
    <>
      <MetaDatas
        siteName={""}
        title={"비밀번호 찾기"}
        siteUrl={location.pathname}
      />
      <div className="bg-gray-200 dark:bg-black h-screen flex justify-center items-center">
        <Switcher className="hidden" />
        <div
          className={`w-full flex flex-col items-center gap-12 ${isMobile && "mx-5"}`}
        >
          <img
            src={
              themeData === "light" || themeData === ""
                ? "/img/standbuy/logo/logo.svg"
                : "/img/standbuy/logo/logo-white.svg"
            }
            alt="standbuy"
            onClick={() => navigate("/")}
            className="cursor-pointer"
            width={200}
          />

          <PasswordFindForm />
        </div>
      </div>
    </>
  );
}
