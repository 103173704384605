import React from "react";
import Modal from "../design-system/modal/Modal";
import Text from "../design-system/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { getCookie } from "../../util/cookies";
import { useContext } from "../../util/useContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  modalFetched,
  niceModalFetched,
} from "../../store/reducer/globalModalSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { API_DEFAULT } from "../../api/api";
import { signOut } from "../../lib/modules/auth";
import { useAxios } from "../../lib/api/useAxios";
import { useQuery } from "@tanstack/react-query";
import { Avatar } from "@material-tailwind/react";
import { ProfileUnderLineGreen } from "../../styles/color";
import styled from "styled-components";
import { addComma } from "../../util/number";
import { checkRole } from "../../util";
import MobileModal from "./MobileModal";

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: 100%;
  padding: 15px 18px;
`;

export default function MobileAvatarModal({ open, onClose }: any) {
  const { me, isLogin } = useContext();
  const { del } = useAxios();
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const location = useLocation();
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual
  );

  return (
    <MobileModal open={open} onClose={onClose} width={"100%"} headerNone>
      <div className="h-screen relative">
        <div className="flex justify-between items-center">
          <Text fontSize={20} fontWeight={600}>
            전체메뉴
          </Text>

          <FontAwesomeIcon
            icon={faXmark}
            onClick={onClose}
            fontSize={24}
            className="cursor-pointer dark:text-darkText"
          />
        </div>

        <div className="mt-6 flex flex-col gap-4">
          <BorderWrap>
            <div className="flex items-center gap-4">
              <Avatar
                variant="circular"
                alt="profile img"
                src={me?.profileImage || "/img/standbuy/icon/no-profile.svg"}
                placeholder={undefined}
                className="border border-[#134B70] ring-2 ring-[#134B70] p-0.5"
              />
              <div className="flex flex-col gap-2">
                <Text className="subtitle-3 ">{me?.nickname || "-"}</Text>
                <Text
                  className="label-2-strong underline underline-offset-1 cursor-pointer"
                  color={`#134B70`}
                  onClick={() => {
                    onClose();
                    navigate("/profile-edit");
                  }}
                >
                  프로필 편집
                </Text>
              </div>
            </div>
          </BorderWrap>

          <div className="w-full flex justify-around gap-2 !cursor-default">
            <div
              className="flex flex-col gap-2 items-center cursor-pointer"
              // onClick={() => navigate("/fof-credit")}
            >
              <Text className="subtitle-3 ">{addComma(me?.profit) || 0}</Text>
              <Text className="label-2 " color={"#134B70"}>
                수익
              </Text>
            </div>

            <div className="flex flex-col gap-2 items-center justify-center">
              <Text className="subtitle-3 ">{me?.subscriberCount || 0}</Text>

              <Text className="label-2 " color={"#134B70"}>
                구독자
              </Text>
            </div>

            <div className="flex flex-col gap-2 items-center">
              <Text className="subtitle-3 ">
                {checkRole(me?.grade) === "아티스트" ? "아티스트" : "디렉터"}
              </Text>
              <Text className="label-2 " color={"#134B70"}>
                등급
              </Text>
            </div>
          </div>

          <div className={"flex justify-between gap-4 mb-[30px]"}>
            <BorderWrap>
              <Text
                className={"flex justify-center "}
                fontWeight={600}
                fontSize={14}
                onClick={() => {
                  onClose();
                  navigate(`/my-studio`);
                }}
              >
                내 스튜디오
              </Text>
            </BorderWrap>

            <BorderWrap>
              <Text
                className={"flex justify-center "}
                fontWeight={600}
                fontSize={14}
                onClick={() => {
                  onClose();
                  navigate(`/locker`);
                }}
              >
                보관함
              </Text>
            </BorderWrap>
          </div>

          <div className="hover:text-[#42BC7F] subtitle-3  transition duration-300 ease-in-out flex justify-between">
            <Text
              className={""}
              fontSize={14}
              fontWeight={600}
              onClick={() => {
                onClose();
                navigate(`/cart`);
              }}
            >
              장바구니
            </Text>

            <img
              src={"/img/standbuy/icon/arrow-right.svg"}
              alt={"arrow-right"}
            />
          </div>

          <div className="hover:text-[#42BC7F] subtitle-3  transition duration-300 ease-in-out flex justify-between">
            <Text
              className={""}
              fontSize={14}
              fontWeight={600}
              onClick={() => {
                onClose();
                navigate(`/exchange`);
              }}
            >
              정산하기
            </Text>

            <img
              src={"/img/standbuy/icon/arrow-right.svg"}
              alt={"arrow-right"}
            />
          </div>

          <div className="hover:text-[#42BC7F] subtitle-3  transition duration-300 ease-in-out flex justify-between">
            <Text
              className={""}
              fontSize={14}
              fontWeight={600}
              onClick={() => {
                onClose();
                navigate(`/exchange-detail`);
              }}
            >
              정산내역
            </Text>

            <img
              src={"/img/standbuy/icon/arrow-right.svg"}
              alt={"arrow-right"}
            />
          </div>

          <div className="hover:text-[#42BC7F] subtitle-3  transition duration-300 ease-in-out flex justify-between">
            <Text
              className={""}
              fontSize={14}
              fontWeight={600}
              onClick={() => {
                onClose();
                navigate(`/fof-list`);
              }}
            >
              결제내역
            </Text>

            <img
              src={"/img/standbuy/icon/arrow-right.svg"}
              alt={"arrow-right"}
            />
          </div>

          <div className="hover:text-[#42BC7F] subtitle-3  transition duration-300 ease-in-out flex justify-between">
            <Text
              className={""}
              fontSize={14}
              fontWeight={600}
              onClick={() => {
                onClose();
                navigate(`/fof-credit`);
              }}
            >
              콘텐츠 거래내역
            </Text>

            <img
              src={"/img/standbuy/icon/arrow-right.svg"}
              alt={"arrow-right"}
            />
          </div>
        </div>

        <div className="mb-8 w-full hover:text-[#42BC7F] subtitle-3  transition duration-300 ease-in-out absolute bottom-28 flex gap-2 items-center justify-center">
          <img src={"/img/standbuy/icon/logout-icon.svg"} alt={""} />

          <Text
            color={"#6A6A6A"}
            className={"underline underline-offset-4 "}
            fontWeight={600}
            onClick={async () => {
              if (isLogin) {
                await del(`${API_DEFAULT}/user/auth/sign-out`, {
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                  browser: browserData,
                });
                signOut({ dispatch: dispatch });
              }
            }}
          >
            로그아웃
          </Text>
        </div>
      </div>
    </MobileModal>
  );
}
