import React, { useState } from "react";
import { motion } from "framer-motion";
import Text from "../../../components/design-system/Text";
import { useContext } from "../../../util/useContext";
import FillButton from "../../../components/design-system/button/FillButton";
import { useNavigate } from "react-router-dom";

export default function BillFail() {
  const navigate = useNavigate();
  const { state } = useContext();

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[50px] mx-[200px] w-full max-w-[1280px]">
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title ">결제 실패</Text>
          </div>

          <div className="w-full flex flex-col">
            <div className="flex flex-col  mt-[120px]">
              <div className="flex justify-center">
                <img
                  src="/img/standbuy/icon/error.svg"
                  alt="error"
                  width={66}
                />
              </div>

              <div className="flex justify-center">
                <Text
                  fontSize={30}
                  fontWeight={700}
                  color="#1a1a1a"
                  className="mt-8 mb-[50px] "
                >
                  결제에 실패했습니다.
                </Text>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center gap-5">
            <div className="flex flex-col justify-center gap-5">
              <div className="mr-40">
                <Text
                  fontSize={15}
                  fontWeight={600}
                  color="#4c4c4c"
                  className=""
                >
                  에러 코드
                </Text>
                <Text fontSize={22} fontWeight={600} color="#424242">
                  {state?.errorCode}
                </Text>
              </div>

              <div className="mr-40">
                <Text
                  fontSize={15}
                  fontWeight={600}
                  color="#4c4c4c"
                  className=""
                >
                  에러 메세지
                </Text>
                <Text fontSize={22} fontWeight={600} color="#424242">
                  {state?.errorMessage}
                </Text>
              </div>
            </div>
          </div>

          <div className="mt-[60px] flex justify-center">
            <FillButton
              text="다시 결제하기"
              onClick={() => navigate("/pay")}
              size="lg"
              className="w-[360px]"
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
}
