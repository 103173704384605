import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import Switcher from "../../components/common/Switcher";
import NiceFormCard from "../niceAuth/card/NIceFormCard";
import React from "react";
import Card from "../../components/design-system/Card";
import Text from "../../components/design-system/Text";
import { UploadTextColor } from "../../styles/color";
import FillButton from "../../components/design-system/button/FillButton";
import { useContext } from "../../util/useContext";
import { MetaDatas } from "../../meta/MetaDatas";

export default function LoginEndPage() {
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const { isLogin, me } = useContext();
  const location = useLocation();

  return (
    <>
      <MetaDatas
        siteName={""}
        title={"회원가입 완료"}
        siteUrl={location.pathname}
      />

      <div className="bg-gray-200 dark:bg-black h-screen flex justify-center items-center">
        <Switcher className="hidden" />
        <div className="w-full flex flex-col items-center gap-12">
          <img
            src={
              themeData === "light" || themeData === ""
                ? "/img/standbuy/logo/logo.svg"
                : "/img/standbuy/logo/logo-white.svg"
            }
            alt="standbuy"
            onClick={() => navigate(-1)}
            className="cursor-pointer"
            width={200}
          />

          <Card>
            <div className="flex flex-col items-center justify-center">
              <img
                src={"/img/standbuy/icon/login-end-icon.svg"}
                alt={"end-icon"}
              />

              <Text className=" mt-10" fontSize={30} fontWeight={700}>
                회원가입 완료
              </Text>

              <Text
                className=" mt-4"
                color={UploadTextColor}
                fontSize={16}
                fontWeight={600}
              >
                지금 바로 스탠바이에서
              </Text>

              <Text
                className=""
                color={UploadTextColor}
                fontSize={16}
                fontWeight={600}
              >
                사진/영상을 팔아보세요!
              </Text>
            </div>

            <div className="flex flex-col items-center justify-center mt-10">
              <FillButton
                text={"지금바로 팔러가기"}
                onClick={() =>
                  navigate(
                    isLogin && me?.agreementSelectiveTerms
                      ? "/feed"
                      : isLogin && !me?.agreementSelectiveTerms
                        ? "/social-check"
                        : "/login"
                  )
                }
                className="w-full"
                size="lg"
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
