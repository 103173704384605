import React, { useEffect, useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
//@ts-ignore
import Lottie from "react-lottie";
import LottieData from "../../../assets/lottie/joinScuess.json";
import Text from "../../../components/design-system/Text";
import { useNavigate } from "react-router-dom";

export default function JoinEndModal({ open, onClose }: any) {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            return prevTimer;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (timer === 0) {
      navigate("/login");
    }
  }, [timer]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Modal title="" onClose={onClose} open={open} headerNone noOutSideClosed>
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        isClickToPauseDisabled={true}
      />

      <div className="flex justify-center mt-2">
        <div className="flex flex-col items-center gap-2 mb-4">
          <Text className="subtitle-2 ">회원가입이 완료되었습니다.</Text>
          <Text className="label-1 ">
            {timer}초 후 로그인 화면으로 이동합니다.
          </Text>
        </div>
      </div>
    </Modal>
  );
}
